import * as React from 'react'

import {RealScreen} from 'api/realsources'
import {ScreenTypeModal} from 'modules/admin/TypeModal'

const SIMPLE_ATTRS = [
  'id',
  'pi_mac_addr',
  'active_asset',
  'key',
  'name',
  'hostname',
  'location',
  'lat',
  'lon',
  'orientation',
  'last_req',
  'marquee',
  'mq_created',
  'mq_duration',
  'gen_info',
  'mb_version',
  'mb_service_ver',
  'hardware_info',
  'time_start',
  'date_start',
  'time_stop',
  'date_stop',
  'reboot',
  'new_config',
  'send_log',
  'distro',
  'config',
  'notes',
  'created_at',
  'updated_at',
  'ownerID',
  'playlistIDs',
  'sharedIDs'
]

interface ScreenModalProps {
  onHide: () => void
  screen?: RealScreen
  show?: boolean
}

const ScreenModal: React.StatelessComponent<ScreenModalProps> = props => (
  <ScreenTypeModal
    onHide={props.onHide}
    entity={props.screen}
    show={props.show}
    simpleAttributes={SIMPLE_ATTRS}
    id="ScreenTypeModal"
    headerTitle="Screen ID#"
  />
)

export default ScreenModal
