/* tslint:disable:variable-name */

import {computed, observable} from 'mobx'
import {AxiosResponse} from 'axios'

import {NotificationInterface} from 'globals'
import {NotificationResource} from 'api/resources'
import API from 'api'
import userStore from './userStore'
import contentStore from './contentStore'
import screenStore from './screenStore'
import playlistStore from './playlistStore'
import User from './User'
import Asset from './Asset'
import Screen from './Screen'
import Playlist from './Playlist'

export default class Notification implements NotificationInterface {
  static fromResource(resource: NotificationResource) {
    return new Notification({
      id: resource.id.toString(),
      userID: resource.user_id.toString(),
      itemID: resource.item_id.toString(),
      code: resource.code,
      messageText: resource.message_text,
      cleared: Boolean(resource.cleared),
      link: resource.link || null
    })
  }

  @observable id: string
  @observable userID: string
  @observable itemID: string
  @observable code: number
  @observable messageText: string
  @observable cleared: boolean
  @observable link: string | null = null

  @computed
  get user(): User {
    return userStore.findById(this.userID)
  }

  @computed
  get item(): Screen | Asset | Playlist | null {
    switch (this.itemClass) {
      case null:
        return null
      case Screen:
        return screenStore.findById(this.itemID)
      case Asset:
        return contentStore.findById(this.itemID)
      case Playlist:
        return playlistStore.findById(this.itemID)
    }
  }

  @computed
  get itemClass(): (typeof Screen | typeof Asset | typeof Playlist) | null {
    switch (Math.floor(this.code / 1000)) {
      case 1:
        return Screen
      case 2:
        return Asset
      case 3:
        return Playlist
      default:
        return null
    }
  }

  constructor(attrs: NotificationInterface) {
    Object.assign(this, attrs)
  }

  asResource = (): NotificationResource => {
    return {
      id: Number(this.id),
      user_id: Number(this.userID),
      item_id: Number(this.itemID),
      message_text: this.messageText,
      code: this.code,
      cleared: Number(this.cleared)
    }
  }

  clear = (): Promise<AxiosResponse> => {
    this.cleared = true
    return API.notifications.clear(this.id)
  }
}
