import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {Provider} from 'mobx-react'
import {IndexRoute, Redirect, Route, Router, Switch, Routes, BrowserRouter} from 'react-router-dom'
import {createBrowserHistory} from "history";
import {localStorageCheck, NOBACKEND} from 'globals'

import './sass/main.scss'

// import routes from 'modules/routes'
import appStateStore from 'stores/appStateStore'
import userStore from 'stores/userStore'
import screenStore from 'stores/screenStore'
import contentStore from 'stores/contentStore'
import playlistStore from 'stores/playlistStore'
import showtimeStore from 'stores/showtimeStore'
import assetShowtimeStore from 'stores/assetShowtimeStore'
import notificationStore from 'stores/notificationStore'
import institutionStore from 'stores/institutionStore'
import broadcastStore from 'stores/broadcastStore'

import alertStore from 'stores/alertStore'

import HomeNav from './modules/navs/HomeNav'
import auth from './modules/auth'
import Get from "./modules/home/Get"
import NotFound from "./modules/NotFound"
import Screens from "./modules/screens/Screens"
import Playlists from "./modules/playlists/Playlists"
import Content from "./modules/content/Content"
import HelpNav from "./modules/help/HelpNav"
import App from "./modules/navs/App"

const stores = {
  alertStore,
  appStateStore,
  assetShowtimeStore,
  broadcastStore,
  contentStore,
  institutionStore,
  notificationStore,
  playlistStore,
  screenStore,
  showtimeStore,
  userStore,
}

function returnUser(nextState, replace) {
  if (auth.loggedIn()) {
    replace('/screens')
    return
  }
}

const createHistory = createBrowserHistory()

function oauth2callback(nextState) {
  
  if (NOBACKEND) {
    window.location.replace('/')
    // cb()

    return
  }

  if (!localStorageCheck()) {
    window.location.replace('/401#error=Browser%20cannot%20set%20token')
    // cb()

    return
  }

  const hash = nextState
  if (hash) {
    const hashQueries = auth.parseHash(hash.substring(1))

    if (hashQueries.access_token) {
      // received access_token, try to log in with it
      auth.login(hashQueries.access_token, (isLoggedIn, err) => {
        window.location.replace(
          isLoggedIn ? '/screens' : '/#signinerror=' + encodeURIComponent(err)
        )
        // cb()
      })

      return
    } else if (hashQueries.error) {
      // received error from oauth2 provider, show it to user
      window.location.replace('/#signinerror=' + hashQueries.error)
      // cb()

      return
    }
  }
  // received unknown hash or no hash at all
  window.location.replace('/#signinerror=OAuth2%20Callback%20expected%20a%20token')
  // cb()
}

// HERE FOR TESTING RUNTIME ERRORS
// uncomment this, reload page, then comment it back out and reload again to log yourself out until
// we get the internal pages fixed so that we can use the logout button
// auth.logout()


ReactDOM.render(
  <Provider {...stores}>
    {/* Changed routes to children */}
    {/* <Router children={routes} history={createHistory} /> */}
    <BrowserRouter>
      <div>
        <Switch>
          <Route path='/oauth2callback' componentDidMount={console.log()}>
            {() => {
              if(window.location.href.includes('/oauth2callback'))
              {
                oauth2callback(window.location.hash)
              }
            }}
          </Route>
          <Route path='/testing' component={Get} />
          <Route path='/'>
            {/* Function that determines if the user is logged in and can allow the authenticated passage or not. If no, it will not render authenticated pages*/}
            {() => {
              if(auth.loggedIn())
              {
                return(
                  <div>
                    <Route component={App} history={createHistory}/>
                    <div className="row" id="wrapper">
                      <Switch>
                        <Route exact path="/">
                          <Redirect to="/screens"/>
                        </Route>
                        <Route path='/screens' component={Screens}/>
                        <Route path='/playlists' component={Playlists}/>
                        <Route path='/content' component={Content}/>
                        <Route path='/help' component={HelpNav}/>
                        <Route component={NotFound}/>
                      </Switch>
                    </div>
                  </div>
                )
              }
              else 
              {
                return(
                  <Route path='/' component={HomeNav}/>
                )
              }
            }}
          </Route>
          <Route path='401' component={Get} />
        </Switch>
      </div>
    </BrowserRouter>
  </Provider>,
  document.getElementById('content')
)
