import * as React from 'react'

import {RealUser} from 'api/realsources'
import {UserTypeModal} from 'modules/admin/TypeModal'

const SIMPLE_ATTRS = [
  'id',
  'username',
  'name',
  'fname',
  'lname',
  'email',
  'active',
  'broadcast_priv',
  'is_admin',
  'institution_id',
  'dept',
  'sys_admin',
  'created_at',
  'updated_at'
]

interface Props {
  onHide: () => void
  user?: RealUser
  show?: boolean
}

const UserModal: React.StatelessComponent<Props> = props => (
  <UserTypeModal
    onHide={props.onHide}
    entity={props.user}
    show={props.show}
    simpleAttributes={SIMPLE_ATTRS}
    id="UserTypeModal"
    headerTitle="User ID#"
  />
)

export default UserModal
