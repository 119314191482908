import {computed, observable, ObservableMap} from 'mobx'

import Asset from 'stores/Asset'
import Playlist from 'stores/Playlist'

export class AppStateStore {
  @observable propMap: ObservableMap<any> = observable.map()

  constructor() {
    this.closeSideNav()

    this.selectedPlaylist = null
  }

  @computed
  get props(): string[] {
    return Array.from(this.propMap.keys())
  }

  @computed
  get navbarExpanded(): boolean {
    return this.propMap.get('navbarExpanded')
  }
  set navbarExpanded(val: boolean) {
    this.propMap.set('navbarExpanded', val)
  }

  // Side Nav Bars

  @computed
  get openSideNav(): string {
    return this.propMap.get('openSideNav')
  }
  set openSideNav(val: string) {
    this.propMap.set('openSideNav', val)
  }
  closeSideNav = () => {
    this.propMap.set('openSideNav', null)
  }

  // Content Section Expansion

  @computed
  get openSection(): string {
    return this.propMap.get('openSection')
  }
  set openSection(val: string) {
    this.propMap.set('openSection', val)
  }
  closeSections = () => {
    this.propMap.set('openSection', null)
  }

  // Content Page Multi Selection

  @computed
  get multiSelecting(): boolean {
    return this.propMap.get('multiSelecting')
  }
  set multiSelecting(val: boolean) {
    this.propMap.set('multiSelecting', val)
  }
  toggleMultiSelect = () => {
    this.propMap.set('multiSelecting', !this.propMap.get('multiSelecting'))
  }

  @computed
  get multiSelectedAssets(): Asset[] {
    return this.propMap.get('multiSelectedAssets')
  }
  isMultiSelected = (asset: Asset): boolean => {
    return this.propMap.get('multiSelectedAssets').includes(asset)
  }
  multiSelectAsset = (asset: Asset) => {
    const selectedAssets = this.propMap.get('multiSelectedAssets')

    if (selectedAssets.includes(asset)) {
      selectedAssets.splice(selectedAssets.indexOf(asset), 1)
    } else {
      selectedAssets.push(asset)
    }

    this.propMap.set('multiSelectedAssets', selectedAssets)
  }
  deselectAll = () => {
    this.propMap.set('multiSelectedAssets', [])
  }

  @computed
  get selectedPlaylist(): Playlist {
    return this.propMap.get('selectedPlaylist')
  }
  set selectedPlaylist(val: Playlist) {
    this.propMap.set('selectedPlaylist', val)
  }

  @computed
  get expandedScreen(): string {
    return this.propMap.get('expandedScreen')
  }
  set expandedScreen(val: string) {
    this.propMap.set('expandedScreen', val)
  }
}

export default new AppStateStore()
