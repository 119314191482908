import * as React from 'react'

import {GOOGLE_OAUTH2_URL} from 'globals'
import Footer from './Footer'

const Logo = require('images/elements/mb-logo.svg')
const LogoWords = require('images/elements/home-logo-lettering.svg')
const HowTo = require('images/elements/how-to.svg')
const ContentContext = require('images/elements/content.svg')
const EmergencyEquipped = require('images/elements/emergency.svg')

const Home: React.StatelessComponent = () => (
  <div id="home-page">
    {/* <div className="header-logo">
      <div className="header-logo-content">
        <div id="minerbytes-logo-home" className="centered">
          {/* <Logo className="centered spin" />
          <LogoWords className="centered" />
        </div>
        <div className="text-centered">digital signage made easy</div>
        <div id="sign-in">
          <a href={GOOGLE_OAUTH2_URL}>
            <button type="submit" className="centered">
              SIGN IN WITH GOOGLE
            </button>
          </a>
        </div>
      </div>
    </div> */}
    <div className="about-section">
      <div className="about-content">
        <span>
          about <b>miner</b>bytes
        </span>
        <br />
        <p>
          MinerBytes is a complete content delivery system that is time and
          location aware. The system is built with organizations in mind,
          allowing users to share, edit and display content in an engaging and
          relevant manner. The system also has the ability to function as a
          location sensitive emergency alert system should the need arise.
        </p>
        <p>
          MinerBytes was developed at the Missouri University of Science and
          Technology by the dedicated members of the IT Research Support Team.
        </p>
        <div id="how-to-animation">
          <HowTo />
        </div>
      </div>
    </div>

    <div className="content-section">
      <div className="content-section-content row">
        <div className="col-sm-8 text">
          <div className="vertical-align">
            <span>
              content with <b>context</b>
            </span>
            <br />
            <p>
              MinerBytes was designed with content at its core. We believe that
              fresh relevant content helps engage and inform people about their
              organization. The time and location features of MinerBytes allow
              users to tailor information to only what is pertinent to a certain
              audience.
            </p>
            <p>
              Don't know what to say? Don't worry! We have just launched the
              MinerBytes Content Marketplace which allows MinerBytes display
              owners to connect with content creators within their organization.
              The marketplace is also a great way for student organizations and
              smaller groups to connect with their institution without having to
              invest in display hardware.
            </p>
          </div>
        </div>
        <div className="col-sm-4">
          <ContentContext />
        </div>
      </div>
    </div>
    <div className="social-section">
      <div className="social-section-content">
        <div>
          <span>
            simply <b>social</b>
          </span>
        </div>
        <div>
          <p>
            MinerBytes is made with organizations, institutions, and departments
            in mind. Content, playlists, and control over screens can all be
            easily shared and viewed at a department and organization level. And
            since MinerBytes is built on web technologies almost anything with a
            web address can be shown and shared on MinerBytes. No more back and
            forth file conversations, email chains or pieces of paper.
          </p>
        </div>
      </div>
    </div>
    <div className="emergency-section">
      <div className="emergency-section-content row">
        <div className="col-sm-8">
          <span>
            equipped for <b>emergency</b>
          </span>
          <p>
            MinerBytes comes equipped to handle emergency broadcasts to a single
            screen, multiple screens in an area you select, or to all the
            screens you control. The broadcast message is then displayed on the
            selected screens until canceled. This allows important messages to
            be spread across a wide area in a short time frame.
          </p>
        </div>
        <div className="col-sm-4">
          <EmergencyEquipped />
        </div>
      </div>
    </div>
    <Footer />
  </div>
)

export default Home
