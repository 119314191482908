/* tslint:disable */

import {computed, observable, ObservableMap} from 'mobx'
import {AxiosResponse} from 'axios'

import API from 'api'
import userStore from './userStore'
import Broadcast from './Broadcast'

export class BroadcastStore {
  @observable broadcastsMap: ObservableMap<string, Broadcast> = observable.map()

  fetchInProgress: Set<string> = new Set()

  @computed
  get broadcasts(): Broadcast[] {
    return Array.from(this.broadcastsMap.values())
  }

  @computed
  get myBroadcasts(): Broadcast[] {
    /* validation; accessing this.broadcastsMap is required for the computed
        function to be correct considering the early branching. */
    this.broadcastsMap // tslint:disable-line
    if (!userStore.currentUser) return []

    const myid = userStore.currentUser.id
    return Array.from(this.broadcastsMap.values()).filter(alrt => alrt.user_id === myid)
  }

  @computed
  get broadcastCount(): number {
    return this.broadcastsMap.size
  }

  /* Add/update broadcast to the store */
  add = (broadcast: Broadcast): void => {
    this.broadcastsMap.set(broadcast.id, broadcast)
  }

  get = (id: string): Broadcast => {
    if (!id) throw new Error(`[BroadcastStore.get] Invalid id: ${id}`)

    return this.broadcastsMap.get(id)
  }

  /* Remove broadcast from the store */
  remove = (broadcast: Broadcast): void => {
    this.broadcastsMap.delete(broadcast.id)
  }

  /* Return the Broadcast from the store, or request from the backend
      and return null */
  findById = (id: string): Broadcast | null => {
    if (!id) throw new Error(`[BroadcastStore.findById] Invalid id: ${id}`)

    const item = this.broadcastsMap.get(id)
    if (item) return item
    else {
      if (!this.fetchInProgress.has(id)) {
        this.fetchInProgress.add(id)
        this.fetchByID(id)
      }
      return null
    }
  }

  /* Request the playlist from the backend and add it to the store */
  fetchByID = (id: string | number): Promise<Broadcast> => {
    id = id.toString()
    if (!id) throw new Error(`[BroadcastStore.fetchByID] Invalid id: ${id}`)

    return API.broadcasts
      .get(id)
      .then(data => {
        const broadcast = Broadcast.fromResource(data)
        this.add(broadcast)
        this.fetchInProgress.delete(broadcast.id)
        return broadcast
      })
      .catch(data => {
        this.fetchInProgress.delete(id as string)
        console.error(`BroadcastStore.fetchByID( ${id} ) failed`, data)
        return null
      })
  }

  /* Fetch all broadcasts */
  fetchAll = (): void => {
    API.broadcasts.getAll().then(data => {
      const oldIDs = new Set(this.broadcasts.map(alrt => Number(alrt.id)))
      data.forEach(broadcastR => {
        oldIDs.delete(broadcastR.id)
        const broadcast = Broadcast.fromResource(broadcastR)
        this.add(broadcast)
      })
      // remove remaining broadcasts
      oldIDs.forEach(id => this.broadcastsMap.delete(id.toString()))
    })
  }

  /* Create a new Broadcast on the backend and store it here */
  create = (broadcast: Broadcast): Promise<Broadcast> => {
    return API.broadcasts
      .create(broadcast.asResource())
      .then(resource => {
        broadcast = Broadcast.fromResource(resource)
        this.add(broadcast)
        return broadcast
      })
      .catch(err => {
        console.error(err)
        throw new Error('Failed to create broadcast')
      })
  }

  /* Delete an Broadcast on the backend and unstore it here. */
  delete = (broadcast: Broadcast): Promise<AxiosResponse> => {
    return API.broadcasts.delete(broadcast.id).then(axioResp => {
      this.remove(broadcast)
      return axioResp
    })
  }
}

export default new BroadcastStore()
