import {IObservableArray, observable, ObservableMap} from 'mobx'
import {v1 as uuid} from 'uuid'

import Alert, {AlertStyle} from './Alert'

type Timer = number

export class AlertStore {
  @observable alerts: IObservableArray<Alert> = observable.array()
  @observable timeouts: ObservableMap<Timer> = observable.map()

  addAlert = (
    message: string | Error,
    bsStyle?: AlertStyle,
    title?: string,
    timeout?: number
  ) => {
    if (message instanceof Error)
      console.error('Posting error alert for error:', [message])
    // stringify if not string
    message = String(message)

    const alert = new Alert(uuid(), String(message), bsStyle, title, timeout)

    this.alerts.push(alert)
    return alert
  }

  clearAlert = (alert: Alert): boolean => {
    return !!this.alerts.remove(alert)
  }
}

export default new AlertStore()
