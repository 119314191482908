import * as React from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {v1 as uuid} from 'uuid'

interface SVGButtonProps {
  customClass?: string
  svgIcon: JSX.Element
  text?: string
  active?: boolean
  onClick: (event: any) => void
  tooltip?: string
  disabled?: boolean
  trigger?: ['hover'] | ['focus'] | ['hover', 'focus']
}

export default class SVGButton extends React.Component<SVGButtonProps> {
  readonly id: number

  constructor(props: SVGButtonProps) {
    super(props)

    this.id = uuid()
  }

  render() {
    const tooltip =
      this.props.tooltip && !this.props.disabled ? (
        <Tooltip id={`svgbutton-tooltip-${this.id}`}>
          {this.props.tooltip}
        </Tooltip>
      ) : null

    let buttonClass = 'svgbutton'

    if (this.props.svgIcon.props) {
      buttonClass += ` ${this.props.svgIcon.props.className}`
    }
    if (this.props.customClass) {
      buttonClass += ` ${this.props.customClass}`
    }
    if (this.props.active) {
      buttonClass += ' active'
    }

    const buttonBody = (
      <button
        id={`svgbutton-${this.id}`}
        disabled={!!this.props.disabled}
        onClick={this.props.onClick}
        className={buttonClass}
      >
        {this.props.svgIcon}
        {this.props.text ? <p>{this.props.text}</p> : null}
      </button>
    )

    return this.props.tooltip && !this.props.disabled ? (
      <OverlayTrigger
        trigger={this.props.trigger || ['hover', 'focus']}
        placement="left"
        overlay={tooltip}
      >
        {buttonBody}
      </OverlayTrigger>
    ) : (
      buttonBody
    )
  }
}
