import * as React from 'react'
import {inject, observer} from 'mobx-react'

import {DataResponse} from 'api'
import {RealScreen, RealUser} from 'api/realsources'
import {AlertStore} from 'stores/alertStore'
import {RealScreenStore} from 'modules/admin/adminstores'
import ScreenForm, {PermedRealScreen} from './form'

interface Props {
  alertStore?: AlertStore
}

const CreateTab: React.StatelessComponent<Props> = inject('alertStore')(
  observer(props => {
    const prepForCreation = (screen: PermedRealScreen | RealScreen | any) => {
      screen.key = screen.config = screen.log = ''

      delete screen.editors
      delete screen.viewers

      return screen
    }

    const onSubmit = (
      screenSubmitted: PermedRealScreen,
      cb: (success: boolean) => void
    ) => {
      const editorsDiff = screenSubmitted.editors
      const viewersDiff = screenSubmitted.viewers

      prepForCreation(screenSubmitted)
      const owner = screenSubmitted.owner

      RealScreenStore.create(screenSubmitted)
        .then(screen => {
          const newOwnerPerms = {
            screen_id: screen.id,
            user_id: owner.id,
            permission: 'owner'
          }

          // owner Creation
          RealScreenStore.createRelation(
            screen.id,
            'users',
            owner.id,
            newOwnerPerms
          )
            .then((userRes: DataResponse<RealUser>) => {
              // owner successfully related
              const diffPromises = []
              editorsDiff.added.forEach(usrID =>
                diffPromises.push(
                  RealScreenStore.createRelation(screen.id, 'users', usrID, {
                    permission: 'editor'
                  })
                )
              )
              viewersDiff.added.forEach(usrID =>
                diffPromises.push(
                  RealScreenStore.createRelation(screen.id, 'users', usrID, {
                    permission: 'viewer'
                  })
                )
              )

              Promise.all(diffPromises).then(resolved => {
                const results = resolved.map(resp => resp.status === 'success')

                if (results.every(Boolean)) {
                  props.alertStore.addAlert(
                    'Successfully created preemptive screen.\nYou must refresh the page to see it.',
                    'success',
                    'Screen Admin Creation'
                  )
                  cb(true)
                } else {
                  props.alertStore.addAlert(
                    '(Partial) failure to set shared permissions.',
                    'danger',
                    'Screen Admin Creation, e1'
                  )
                  cb(false)
                }
              })
            })
            .catch(err => {
              props.alertStore.addAlert(
                err,
                'danger',
                'Screen Admin Creation, e2'
              )
              cb(false)
            })
        })
        .catch(err => {
          props.alertStore.addAlert(err, 'danger', 'Screen Admin Creation, e3')
          cb(true)
        })
    }

    return (
      <div id="screen-create" className="admin-inner-tab">
        <span className="description">
          Use this form to preemptively create a screen.
        </span>
        <ScreenForm
          formType="create"
          onSubmit={(screenSubmitted, callback) =>
            onSubmit(screenSubmitted, callback)
          }
          id="screen-createform"
        />
      </div>
    )
  })
)

export default CreateTab
