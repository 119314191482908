import * as React from 'react'

import Playlist from 'stores/Playlist'
import Screen from 'stores/Screen'
import User from 'stores/User'
import {SelectValueInfo} from './UserSearchBox'

const CloseX = require('images/elements/close-x.svg')

const EVENTKEY_OWNER = 3

/*
interface Props {
  children: [SelectValueInfo, Node]
  id: string
  instancePrefix: string
  onClick: () => void
  value: User
}
*/
interface Props {
  innerProps: any
  target: Screen | Playlist
  removeUser: (user: User) => void
  user: User
  children?: any
}

export default class UserSearchValue extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  removeUser = () => {
    this.props.removeUser(this.props.user)
  }

  render() {
    return (
      <div className="user-search-value">
        <span
          className="user-search-value-label"
          role="option"
          aria-selected="true"
        >
          {this.props.user.name}
          {this.props.children}
          <CloseX
            className="user-search-value-remove"
            onClick={this.removeUser}
          />
        </span>
      </div>
    )
  }
}
