import {computed, IObservableArray, observable, ObservableMap} from 'mobx'

import API from 'api'
import showtimeStore from './showtimeStore'
import userStore from './userStore'
import playlistStore from './playlistStore'
import institutionStore from './institutionStore'
import User from './User'
import Screen from './Screen'
import Playlist from './Playlist'
import Showtime from './Showtime'
import Institution from './Institution'

const sIDusrIDregex = /.*(\d+)\/users\/(\d+)\/?$/

export class ScreenStore {
  @observable screensMap: ObservableMap<string, Screen> = observable.map()
  // might need to make these into string, string if they cause problems during runtime
  @observable myScreenIDs: IObservableArray<string> = observable.array()
  @observable sharedScreenIDs: IObservableArray<string> = observable.array()
  @observable editableScreenIDs: IObservableArray<string> = observable.array()

  fetchInProgress: Set<string> = new Set()

  readonly Screen: typeof Screen = Screen

  @computed
  get screens(): Screen[] {
    return Array.from(this.screensMap.values())
  }

  @computed
  get myScreens(): Screen[] {
    return this.myScreenIDs.map(id => this.screensMap.get(id)).filter(Boolean)
  }

  @computed
  get sharedScreens(): Screen[] {
    return this.sharedScreenIDs
      .map(id => this.screensMap.get(id))
      .filter(Boolean)
  }

  @computed
  get editableScreens(): Screen[] {
    return this.editableScreenIDs
      .map(id => this.screensMap.get(id))
      .filter(Boolean)
  }

  /* Add/update screen to the store */
  add = (screen: Screen) => this.screensMap.set(screen.id, screen)

  /* Remove screen from the store */
  remove = (screen: Screen): void => {
    const id = screen.id
    this.screensMap.delete(screen.id)
    this.myScreenIDs.remove(id)
    this.sharedScreenIDs.remove(id)
  }

  removePlaylist = (plID: string) => {
    this.screensMap.forEach((scrn: Screen) => {
      scrn.playlistIDs.remove(plID)
      if (scrn.activePlaylistID == plID)
        scrn.activePlaylistID = scrn.playlistIDs[0] || "none"
    })
  }

  /* Return the corresponding screen from the store, or request from
      the backend and return null */
  findById = (id: string): Screen | null => {
    if (!id) throw new Error(`[ScreenStore.findById] Invalid id: ${id}`)

    id = id.toString()

    const item = this.screensMap.get(id)

    if (item) return item
    else {
      if (!this.fetchInProgress.has(id)) {
        this.fetchInProgress.add(id)
        this.fetchByID(id)
      }
      return null
    }
  }

  screenReducer = (map: {}, screen: Screen) => {
    map[screen.id] = screen
    return map
  }

  /* Request the screen from the backend and add it to the store */
  fetchByID = (id: string | number): Promise<Screen> => {
    if (!id) throw new Error(`[ScreenStore.fetchByID] Invalid id: ${id}`)

    return API.screens
      .get(id)
      .then(data => {
        if (data.institution)
          institutionStore.add(Institution.fromResource(data.institution))
        const screen = Screen.fromResource(data)
        this.add(screen)
        this.fetchInProgress.delete(screen.id)

        return screen
      })
      .catch(data => {
        this.fetchInProgress.delete(id.toString())
        console.error(`ScreenStore.fetchByID( ${id} ) failed:`, data)
        return null
      })
  }

  /* Fetch screen from `/api/users/current/screens` and updates
      this.myScreenIDs and this.sharedScreenIDs */
  fetchShared = (): void => {
    API.current.screens().then(data => {
      const mine = []
      const shared = []
      const editable = []
      const myid = userStore.currentUser.id

      data.forEach(screenR => {
        if (screenR.institution)
          institutionStore.add(Institution.fromResource(screenR.institution))

        const screen = Screen.fromResource(screenR)
        this.add(screen)

        shared.push(screen.id)
        if (
          screenR.pivot.permission === 'editor' ||
          screenR.pivot.permission === 'owner'
        ) {
          editable.push(screen.id)
          if (screen.ownerID === myid) {
            mine.push(screen.id)
          }
        }
        screen.getThumbnails()

        screenR.playlists.forEach(plR =>
          playlistStore.addIfNew(Playlist.fromResource(plR))
        )
        screenR.showtimes.forEach(stR =>
          showtimeStore.add(Showtime.fromResource(stR))
        )
        screenR.users.forEach(usrR => {
          userStore.add(User.fromResource(usrR))
          screen.userPermissions.setPermission(
            usrR.pivot.user_id,
            usrR.pivot.permission
          )
        })
      })

      this.myScreenIDs.replace(mine)
      this.sharedScreenIDs.replace(shared)
      this.editableScreenIDs.replace(editable)
    })
  }

  updateMarquees = (
    screenIDs: string[],
    marquee: string,
    mq_duration: number, // tslint:disable-line
    mq_created: string // tslint:disable-line
  ) => {
    const screenPromises = screenIDs.map(id => {
      const screen = this.screensMap.get(id)
      if (!screen) throw new Error(`Screen ${id} not in store`)
      return screen.update({
        id: Number(id),
        marquee,
        mq_duration,
        mq_created
      })
    })

    return API.all(screenPromises).catch(err => {
      console.error(err)
      throw new Error('Failed to update Marquee')
    })
  }
}

export default new ScreenStore()
