import * as React from 'react'

import LocationControl from './Location'
import NicknameControl from './Nickname'
import ScheduleControl from './Schedule'
import StopMarqueeControl from './StopMarquee'
import PowerControl from './Power'

export {
  LocationControl,
  NicknameControl,
  ScheduleControl,
  StopMarqueeControl,
  PowerControl
}
