import * as React from 'react'
import {ContentStore} from 'src/stores/contentStore'

interface Props {
  store: ContentStore
}

const SearchBar: React.SFC<Props> = props => {
  const onSearch = e => {
    props.store.search(e.target.value.trim())
  }
  return <input id="searchbar" placeholder="Search..." onChange={onSearch} />
}

export default SearchBar
