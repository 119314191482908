import * as React from 'react'
import {Button, FormGroup} from 'react-bootstrap'
import {inject, observer} from 'mobx-react'

import Screen from 'stores/Screen'
import {AlertStore} from 'stores/alertStore'

interface Props {
  screen: Screen
  onHide: () => void
  alertStore?: AlertStore
}

interface State {
  disabled: boolean
}

@inject('alertStore')
@observer
class PowerControl extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      disabled: props.screen.reboot === 1,
    }
  }

  onConfirm = () => {
    const screenname = this.props.screen.title
    this.setState({disabled: true})

    const update = {
      id: Number(this.props.screen.id),
      reboot: 1,
    }

    this.props.screen
      .update(update)
      .then((screen: Screen) => {
        this.props.alertStore.addAlert(
          `Successfully submitted reboot request for ${screenname}`,
          'success'
        )
        // stay disabled; the request has been sent
        this.props.onHide()
      })
      .catch((err) => {
        console.error('Error caught requesting reboot:', err)
        this.props.alertStore.addAlert(
          'An error occurred while requesting reboot.',
          'danger',
          'Reboot request failed'
        )

        this.setState({disabled: false})
        this.props.onHide()
      })
  }

  render() {
    return (
      <div className="screen-expanded-control power-control">
        <h5 className="sec-title">Power Cycle</h5>
        <form onSubmit={(e) => e.preventDefault()}>
          <FormGroup>
            <Button
              variant="primary"
              onClick={this.onConfirm}
              disabled={this.state.disabled}
            >
              Reboot
            </Button>
            <Button
              variant="danger"
              onClick={this.props.onHide}
              disabled={this.state.disabled}
            >
              Nevermind
            </Button>
          </FormGroup>
        </form>
      </div>
    )
  }
}

export default PowerControl
