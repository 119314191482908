import * as React from 'react'
import {computed, IObservableArray, ObservableMap} from 'mobx'
import {observer} from 'mobx-react'
import {Overlay, Tooltip} from 'react-bootstrap'

import Showtime from 'stores/Showtime'
import Playlist from 'stores/Playlist'
import Screen from 'stores/Screen'
import {PLAYLIST_NONE} from './index'
import DrawShowtime from './DrawShowtime'

interface Props {
  editShowtime: (showtime: Showtime) => void
  screen: Screen
  playlists: IObservableArray<Playlist>
  showtimes: ObservableMap<string, ObservableMap<string, Showtime>>
  disabled: boolean
  selectKey: number
}

interface State {
  showTooltip: boolean
}

@observer
class ScheduleDrawPlaylist extends React.Component<Props, State> {
  myRef: React.RefObject<HTMLDivElement>
  constructor(props: Props) {
    super(props)

    this.myRef = React.createRef()

    this.state = {
      showTooltip: false
    }
  }

  @computed
  get playlist() {
    return this.isShown
      ? this.props.playlists[this.props.selectKey] || PLAYLIST_NONE
      : PLAYLIST_NONE
  }

  @computed
  get isShown() {
    return this.props.selectKey < this.props.playlists.length
  }

  @computed
  get isEmpty() {
    return (
      this.isShown &&
      this.playlist !== PLAYLIST_NONE &&
      this.props.showtimes.get(this.playlist.id).size === 0
    )
  }

  @computed
  get children() {
    if (!this.isShown || this.playlist === PLAYLIST_NONE) {
      return []
    }

    const myShowtimes = this.props.showtimes.get(this.playlist.id)

    return Array.from(myShowtimes
      .values())
      .map((showtime, key) => (
        <DrawShowtime
          key={showtime.id}
          showtime={showtime}
          disabled={this.props.disabled}
          editShowtime={() => this.props.editShowtime(showtime)}
        />
      ))
  }

  onBackgroundClick = (event) => {
    const xPcnt = event.nativeEvent.offsetX / event.target.offsetWidth
    const newShowtime = Showtime.createFromPercent(
      this.props.screen.id,
      this.playlist.id,
      xPcnt
    )

    this.props.showtimes.get(this.playlist.id).set(newShowtime.id, newShowtime)

    this.props.editShowtime(newShowtime)
  }

  onMouseOver = (event) => {
    if (this.myRef.current === event.target)
      this.setState({showTooltip: this.playlist !== PLAYLIST_NONE})
    else
      this.setState({showTooltip: false})
  }

  onMouseOut = () => {
    this.setState({showTooltip: false})
  }

  render() {

    if (!this.isShown) {
      return null
    }

    return (
      <div
        className="schedcon-draw-playlist-wrapper"
        ref={this.myRef}
        data-index={this.props.selectKey}
        data-playlistid={this.playlist.id}
        data-empty={this.isEmpty}
        style={{display: this.isShown ? 'block' : 'none'}}
        onClick={this.onBackgroundClick}
        onMouseOver={(event) => this.onMouseOver(event)}
        onMouseOut={this.onMouseOut}
      >
        {this.children}

        <Overlay
          target={this.myRef.current}
          show={this.state.showTooltip}
          placement="top"
        >
          <Tooltip id="schedcon-draw-playlist-tooltip">
            Add show times to {this.playlist.title}
          </Tooltip>
        </Overlay>

        {this.isEmpty && (
          <span className="scdp-emptywarning">No show times Added</span>
        )}
      </div>
    )
  }
}

export default ScheduleDrawPlaylist
