import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {computed} from 'mobx'
import {Accordion, Button, Card, Form, FormLabel, Modal, useAccordionToggle} from 'react-bootstrap'
import Select from 'react-select'

import {RealScreen} from 'api/realsources'
import {AlertStore} from 'stores/alertStore'
import {RealScreenStore} from 'modules/admin/adminstores'

interface Props {
  alertStore?: AlertStore
}

interface State {
  screen: RealScreen
  disabled: boolean
  showLogModal: boolean
}

interface ScreenDiff {
  id: number
  reboot?: 0 | 1
  send_log?: 0 | 1
}

@inject('alertStore')
@observer
class ScreensMaintenanceTab extends React.Component<Props, State> {
  
  accordionToggle: React.RefObject<HTMLSpanElement>
  
  constructor(props) {
    super(props)

    this.accordionToggle = React.createRef();

    this.state = {
      screen: null,
      disabled: true,
      showLogModal: false,
    }
  }

  @computed
  get canReboot() {
    return this.state.screen && !this.state.screen.reboot
  }

  @computed
  get hasLog() {
    return (
      this.state.screen &&
      this.state.screen.log &&
      this.state.screen.log.length > 0
    )
  }

  @computed
  get canSendlog() {
    return this.state.screen && !this.state.screen.send_log
  }

  onSelect = (newValue: RealScreen) => {
    //Selective toggling of the accordion
    if (this.state.screen === null && !(newValue instanceof Array))
      this.accordionToggle.current.click()
    if (newValue instanceof Array)
      this.setState({
        screen: null,
        disabled: true,
      })
    else
    {
      this.setState({
        screen: newValue,
        disabled: false,
      })
    }
  }

  onClickReboot = () => {
    this.state.screen.reboot = 1
    this.submitter({
      id: this.state.screen.id,
      reboot: 1,
    })
  }

  onClickSendlog = () => {
    this.state.screen.send_log = 1
    this.submitter({
      id: this.state.screen.id,
      send_log: 1,
    })
  }

  onClickViewLog = () => {
    if (this.hasLog) {
      this.setState({showLogModal: true})
    }
  }

  submitter = (screenUpdate: ScreenDiff): Promise<boolean> => {
    return RealScreenStore.update(screenUpdate, true)
      .then((screen) => {
        this.props.alertStore.addAlert(
          'Successfully submitted maintenance request.',
          'success'
        )
        return true
      })
      .catch((err) => {
        console.error('Error caught in maintenance request:', err)
        this.props.alertStore.addAlert(
          err,
          'danger',
          'Maintenance request failed'
        )
        return false
      })
  }

  render() {
    return (
      <div id="screen-maintenance" className="admin-inner-tab">
        <span className="description">
          Perform maintenance operations on a screen.
        </span>
        <Select
          value={this.state.screen}
          options={Array.from(RealScreenStore.values)}
          className="screen-maintenance-select"
          getOptionLabel={(opt) => opt.name}
          getOptionValue={(opt) => String(opt.id)}
          isMulti={false}
          blurInputOnSelect={true}
          onChange={this.onSelect}
        />
        <Accordion>
          <Accordion.Toggle eventKey="6">
            <span ref={this.accordionToggle} style={{display: "none"}}/>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="6">
            <Card>
              <Form.Group controlId="screen-maintenance-form-reboot">
                <FormLabel>Power</FormLabel>
                <Button
                  id="screen-maintenance-form-reboot"
                  variant="danger"
                  disabled={!this.canReboot}
                  title={!this.canReboot ? 'Waiting for reboot...' : null}
                  onClick={this.onClickReboot}
                >
                  Reboot
                </Button>
              </Form.Group>
              <Form.Group controlId="screen-maintenance-form-sendlog">
                <FormLabel>Logging</FormLabel>
                <Button
                  id="screen-maintenance-form-sendlog"
                  variant="success"
                  disabled={!this.canSendlog}
                  title={!this.canSendlog ? 'Waiting for log...' : null}
                  onClick={this.onClickSendlog}
                >
                  Request Log
                </Button>
                <Button
                  variant={this.hasLog ? 'info' : null}
                  disabled={!this.hasLog}
                  onClick={this.onClickViewLog}
                >
                  {this.hasLog ? 'View Log' : 'No log...'}
                </Button>
              </Form.Group>
              <Modal
                id="screen-maintenance-form-logmodal"
                show={this.state.showLogModal}
                onHide={() => this.setState({showLogModal: false})}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    Screen #{this.state.screen && this.state.screen.id}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <pre>{this.hasLog && this.state.screen.log}</pre>
                </Modal.Body>
              </Modal>
            </Card>
          </Accordion.Collapse>
        </Accordion>                  
      </div>
    )
  }
}

export default ScreensMaintenanceTab
