import * as React from 'react'
import {computed} from 'mobx'
import {observer} from 'mobx-react'

import {RealPlaylist} from 'api/realsources'

const VerticalIcon = require('images/elements/vertical-icon.svg')
const HorizontalIcon = require('images/elements/horizontal-icon.svg')

interface Props {
  playlist: RealPlaylist
  onClick: (plst: RealPlaylist) => void
}

@observer
class PlaylistsIndexRow extends React.Component<Props> {
  @computed
  get orientationIcon() {
    switch (this.props.playlist.orientation) {
      case 'vertical':
        return <VerticalIcon className="orientation-icon vertical" />
      case 'horizontal':
        return <HorizontalIcon className="orientation-icon horizontal" />
      default:
        return null
    }
  }

  render() {
    return (
      <tr
        data-playlistid={this.props.playlist.id}
        data-ownerid={this.props.playlist.ownerID || 'none'}
        onClick={() => this.props.onClick(this.props.playlist)}
      >
        <td>{this.props.playlist.id}</td>
        <td title={this.props.playlist.name}>{this.props.playlist.name}</td>
        <td title={this.props.playlist.orientation}>{this.orientationIcon}</td>
      </tr>
    )
  }
}

export default PlaylistsIndexRow
