import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {Link} from 'react-router-dom'

import Notification from 'stores/Notification'
import {NotificationStore} from 'stores/notificationStore'
import SVGButton from 'modules/common/SVGButton'

const Bell = require('images/elements/notification-icon.svg')
const CloseX = require('images/elements/close-x.svg')
const DismissAll = require('images/elements/dismiss-all-icon.svg')

interface Props {
  sendNotificationPanel: (panel: any, closer: any) => void
  notificationStore?: NotificationStore
}

interface State {
  panelVisible: boolean
}

@inject('notificationStore')
@observer
class NotificationIcon extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      panelVisible: false
    }
  }

  iconClicked = () => {
    if (this.state.panelVisible) {
      this.setState({
        panelVisible: false
      })
    } else {
      this.setState({
        panelVisible: true
      })
    }
  }

  notificationRenderer(notification: Notification): JSX.Element {
    return (
      <li key={notification.id} className="notification-li">
        {notification.link ? (
          <Link to={notification.link}>
            <p>{notification.messageText}</p>
          </Link>
        ) : (
          <p>{notification.messageText}</p>
        )}
        <CloseX
          className="clear-notification"
          onClick={() => notification.clear()}
        />
      </li>
    )
  }

  render() {
    const activeClassName = this.state.panelVisible ? 'active ' : ''

    return (
      <div id="notification-icon" className={activeClassName}>
        {this.state.panelVisible && (
          <div className="notification-container">
            <div
              className="close-notification-panel"
              onClick={this.iconClicked}
            />
            <div id="notification-handle" />
            <div id="handle-curve" />
            <div id="shadow-hide" />
            <div className="notification-panel">
              <h5>NOTIFICATIONS</h5>
              {this.props.notificationStore.uncleared.length > 0 && (
                <SVGButton
                  customClass="dismiss-all-icon"
                  svgIcon={<DismissAll />}
                  onClick={() => this.props.notificationStore.dismissAll()}
                />
              )}
              <ul className="notification">
                {this.props.notificationStore.uncleared.length > 0 ? (
                  this.props.notificationStore.uncleared.map(
                    this.notificationRenderer
                  )
                ) : (
                  <li key={1} className="notification-li">
                    <p className="empty">You have no new notifications</p>
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
        <Bell className={activeClassName} onClick={this.iconClicked} />
        {this.props.notificationStore.uncleared.length > 0 && (
          <div id="notification-indicator" />
        )}
      </div>
    )
  }
}

export default NotificationIcon
