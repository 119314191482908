import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { AxiosResponse } from 'axios'
import Select from 'react-select'

import { SelectOption } from 'globals'
import { PlaylistStore } from 'stores/playlistStore'
import userStore from 'stores/userStore'
import Playlist from 'stores/Playlist'
import Asset from 'stores/Asset'

interface Props {
  onSelect: (option: Playlist) => void
  playlistStore?: PlaylistStore
}

interface State {
  selectedPlaylist: Playlist
}

@inject('playlistStore')
@observer
class PlaylistSelectBox extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      selectedPlaylist: null
    }
  }

  getPlaylistOption = (playlist: Playlist) => {
    return (playlist
      ? {
        value: playlist.id,
        label: playlist.title
      }
      : {
        value: '-1',
        label: 'Select a Playlist'
      }) as SelectOption
  }

  generatePlaylistOptions = () => {
    const options: SelectOption[] = this.props.playlistStore.playlists.filter(
        (playlist) => playlist.ownerID == userStore.currentUser.id || 
            (playlist.sharedIDs.filter(e => e == userStore.currentUser.id).length > 0 &&
            playlist.userPermissions.myPermission == 'editor')
      ).map(
      (playlist, i) => (
        {
        value: playlist.id,
        label: playlist.title
      })
    )

    // Add a special option for favorites
    options.unshift({
      value: 'Favorites',
      label: 'Favorites'
    })

    return options
  }

  onSelect = (option: SelectOption) => {
    let playlist: Playlist

    // need to handle the Favorites option differently
    if (option.value !== 'Favorites') {
      playlist = this.props.playlistStore.findById(option.value)
    } else {
      // create a fake playlist (not actually stored in database)
      // so the rest of the code doesnt have to treat favorites differently
      playlist = new Playlist({
        id: null,
        title: 'Favorites',
        ownerID: null,
        orientation: null,
        inUse: true,
        promoted: false,
        assetIDs: [],
        sharedIDs: []
      })

      // Since we dont actually want to add assets to it on the database
      // change the function so it just toggles favorite (that will be saved on the database)
      // TODO: correctly type this
      playlist.addAsset = (asset: Asset): Promise<AxiosResponse | any> =>
        asset.toggleFavorite()
    }

    this.setState({
      selectedPlaylist: playlist
    })

    this.props.onSelect(playlist)
  }

  render() {
    return (
      <Select
        className="playlist-select-box"
        value={this.getPlaylistOption(this.state.selectedPlaylist)}
        options={this.generatePlaylistOptions()}
        onChange={this.onSelect}
        isClearable={false}
        isSearchable={false}
      />
    )
  }
}

export default PlaylistSelectBox
