import * as React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import {Accordion, Button} from 'react-bootstrap'

//Stores
import {RealScreenStore, RealPlaylistStore, RealAssetStore, RealUserStore} from 'modules/admin/adminstores'
//Tabs
import BaseAdminTab from './tabs/BaseAdminTab'
import BaseDeleteTab from './tabs/BaseDeleteTab'
//Screens
import ScreensIndex from './tabs/screens/IndexTab'
import ScreensUpdate from './tabs/screens/UpdateTab'
import ScreensCreate from './tabs/screens/CreateTab'
import ScreensMaintenance from './tabs/screens/MaintenanceTab'
//Playlists
import PlaylistIndex from './tabs/playlists/IndexTab'
import PlaylistCreate from './tabs/playlists/CreateTab'
//Content
import ContentIndex from './tabs/content/IndexTab'
import ContentMaintenance from './tabs/content/MaintenanceTab'
//Users
import UsersIndex from './tabs/users/IndexTab'
import UsersUpdate from './tabs/users/UpdateTab'
import UsersCreate from './tabs/users/CreateTab'

interface Props {
  show: boolean
}

const ScreenComponentMap = [
  {name: "Info", component: <ScreensIndex/>},
  {name: "Update", component: <ScreensUpdate/>},
  {name: "Create", component: <ScreensCreate/>},
  {name: "Maintenance", component: <ScreensMaintenance/>},
  {name: "Delete", 
   component:
    <BaseDeleteTab
      typeName="screen"
      adminStore={RealScreenStore}
    />
  }
]
const PlaylistComponentMap = [
  {name: "Info", component: <PlaylistIndex/>},
  {name: "Create", component: <PlaylistCreate/>},
  {name: "Delete", 
    component:
    <BaseDeleteTab
      typeName="playlist"
      adminStore={RealPlaylistStore}
    />
  }
]
const ContentComponentMap = [
  {name: "Info", component: <ContentIndex/>},
  {name: "Maintenance", component: <ContentMaintenance/>},
  {name: "Delete",
   component:
    <BaseDeleteTab
      typeName="asset"
      adminStore={RealAssetStore}
    />
  }
]
const UserComponentMap = [
  {name: "Info", component: <UsersIndex/>},
  {name: "Update", component: <UsersUpdate/>},
  {name: "Create", component: <UsersCreate/>},
  {name: "Delete", 
   component:
    <BaseDeleteTab
      typeName="user"
      adminStore={RealUserStore}
    />
  }
]

@observer
class AdminPanel extends React.Component<Props> {

  activeKey = observable.box("-1")

  render() {

    return (
      <Accordion
        onSelect={(eventKey) => this.activeKey.set(eventKey)}
        id="admin-panel-tabs"
      >
        {/* Toggles */}
        <Accordion.Toggle
          eventKey="0"
          className={this.activeKey.get() === "0" ? "active" : ""}
        >
          Screens
        </Accordion.Toggle>
        <Accordion.Toggle
          eventKey="1"
          className={this.activeKey.get() === "1" ? "active" : ""}
        >
          Playlists
        </Accordion.Toggle>
        <Accordion.Toggle
          eventKey="2"
          className={this.activeKey.get() === "2" ? "active" : ""}
        >
          Content
        </Accordion.Toggle>
        <Accordion.Toggle
          eventKey="3"
          className={`${this.activeKey.get() === "3" ? "active" : ""} right-most`}
        >
          Users
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <BaseAdminTab
            components={ScreenComponentMap}
            id="admin-panel-screens-tab"
            className="screenTab"
          />
        </Accordion.Collapse>
        <Accordion.Collapse eventKey="1">
          <BaseAdminTab
            components={PlaylistComponentMap}
            id="admin-panel-playlists-tab"
            className="playlistsTab"
          />
        </Accordion.Collapse>
        <Accordion.Collapse eventKey="2">
          <BaseAdminTab
            components={ContentComponentMap}
            id="admin-panel-content-tab"
            className="contentTab"
          />
        </Accordion.Collapse>
        <Accordion.Collapse eventKey="3">
          <Button/>
        </Accordion.Collapse>
      </Accordion>
    )
  }
}

export default AdminPanel
