import * as React from 'react'
import {computed} from 'mobx'
import {observer} from 'mobx-react'
import {Modal, Table} from 'react-bootstrap'

import {RealAsset, RealPlaylist, RealScreen, RealUser} from 'api/realsources'

type ComplexAttrGetter<T> = (entity: T) => string

interface ComplexAttrsObject<T> {
  [attr: string]: ComplexAttrGetter<T>
}

interface Props<T> {
  onHide: () => void
  simpleAttributes: string[]
  complexAttributes?: ComplexAttrsObject<T>
  id?: string
  className?: string
  entity?: T
  show?: boolean
  headerTitle?: string
}

interface State {
  show: boolean
}

@observer
class TypeModal<T> extends React.Component<Props<T>, State> {
  static defaultProps = {
    id: null,
    className: null,
    entity: null,
    show: true,
    headerTitle: 'ID#',
  }

  constructor(props: Props<T>) {
    super(props)

    this.state = {
      show: props.show,
    }
  }

  // UPDATE-TODO:
  // Move code with side effects to componentDidMount, and set initial state in the constructor.
  // UNSAFE_componentWillReceiveProps(nextProps: Props<T>) {
  componentDidUpdate(nextProps: Props<T>) {
    const pshow = !!nextProps.show

    if (pshow !== this.state.show) {
      this.setState({show: pshow})
    }
  }

  @computed
  get entity(): any {
    // TODO: Make null be an acceptable returned value, so that T can be the return type
    return this.props.entity ? this.props.entity : {}
  }

  @computed
  get sizeSimpleAttrs() {
    return this.props.simpleAttributes.length
  }

  get = (attr: string, defaultValue = 'N/A') => {
    if (this.props.entity) {
      return this.props.entity[attr]
    }

    return defaultValue
  }

  rowGenerator = (attr: string, value: any, key: number) => {
    return (
      <tr key={key}>
        <td>{attr}</td>
        <td>{String(value)}</td>
      </tr>
    )
  }

  simpleRow = (attr: string, key: number) => {
    return this.rowGenerator(attr, this.get(attr), key)
  }

  /* Resolve the values of complexAttributes by calling their ComplexAttrGetter
   */
  @computed
  get complexRows() {
    if (!this.props.complexAttributes) {
      return []
    }

    return Object.keys(
      this.props.complexAttributes
    ).map((attr: string, key: number) =>
      this.rowGenerator(
        attr,
        this.props.complexAttributes[attr](this.entity),
        key + this.sizeSimpleAttrs
      )
    )
  }

  close = () => {
    this.setState({show: false})
    this.props.onHide()
  }

  render() {
    return (
      <Modal
        id={this.props.id}
        className={'TypeModal ' + this.props.className}
        show={this.props.entity && this.state.show && this.props.show}
        onHide={this.close}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.headerTitle} {this.entity.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* removed condensed from <Table condensed> */}
          <Table>
            <thead>
              <tr>
                <th>Attribute</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {this.props.simpleAttributes.map((attr, key) =>
                this.simpleRow(attr, key)
              )}
              {this.complexRows}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    )
  }
}

interface ScreenTypeModal {
  new (): TypeModal<RealScreen>
}
interface UserTypeModal {
  new (): TypeModal<RealUser>
}
interface PlaylistTypeModal {
  new (): TypeModal<RealPlaylist>
}

interface AssetTypeModal {
  new (): TypeModal<RealAsset>
}

export const ScreenTypeModal = (TypeModal as any) as ScreenTypeModal
export const UserTypeModal = (TypeModal as any) as UserTypeModal
export const PlaylistTypeModal = (TypeModal as any) as PlaylistTypeModal
export const AssetTypeModal = (TypeModal as any) as AssetTypeModal

export default TypeModal
