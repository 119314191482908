/* tslint:disable:variable-name */

import {computed, observable} from 'mobx'

import {ShowtimeInterface} from 'globals'
import {ShowtimeResource} from 'api/resources'
import {
  DOWS_3,
  hhmmss_toSeconds,
  hhmmss_totimeinput,
  now_weektime,
  seconds_tohhmmss,
  SECPERDAY,
  SECPERHOUR,
  SECPERWEEK
} from 'time'
import playlistStore from './playlistStore'
import screenStore from './screenStore'
import Playlist from './Playlist'
import Screen from './Screen'

const DEFAULT_DURATION = 12

export default class Showtime implements ShowtimeInterface {
  static createDefaultFull(screenID: string, playlistID: string) {
    const uniqID = String(Date.now())
    return new Showtime({
      id: uniqID,
      screenID,
      playlistID,
      day_start: 0,
      time_start: '00:00:00',
      duration: 168
    })
  }

  static createFromPercent(screenID: string, playlistID: string, pcnt: number) {
    const weektime = SECPERWEEK * pcnt
    const uniqID = String(Date.now())
    return new Showtime({
      id: uniqID,
      screenID,
      playlistID,
      day_start: Math.floor(weektime / SECPERDAY),
      time_start: seconds_tohhmmss(weektime),
      duration: DEFAULT_DURATION
    })
  }

  static fromResource(resource: ShowtimeResource): Showtime {
    return new Showtime({
      id: resource.id.toString(),
      screenID: resource.screen_id.toString(),
      playlistID: resource.playlist_id.toString(),
      day_start: resource.day_start,
      time_start: resource.time_start,
      duration: resource.duration
    })
  }

  @observable id: string
  @observable screenID: string
  @observable playlistID: string
  @observable day_start: number // 0-6 <=> Sun-Sat
  @observable time_start: string // "hh:mm:ss"
  @observable duration: number // in hours

  @computed
  get start_weektime(): number {
    return this.day_start * SECPERDAY + hhmmss_toSeconds(this.time_start)
  }

  @computed
  get end_weektime(): number {
    return (this.start_weektime + this.duration * SECPERHOUR) % SECPERWEEK
  }

  @computed
  get doesWrap(): boolean {
    return this.start_weektime + this.duration * SECPERHOUR >= SECPERWEEK
  }

  @computed
  get day_end(): number {
    return Math.floor(this.end_weektime / SECPERDAY)
  }

  @computed
  get time_end(): string {
    return seconds_tohhmmss(this.end_weektime)
  }

  @computed
  get start_pretty(): string {
    return `${hhmmss_totimeinput(this.time_start)} ${DOWS_3[this.day_start]}`
  }

  @computed
  get end_pretty(): string {
    return `${hhmmss_totimeinput(this.time_end)} ${DOWS_3[this.day_end]}`
  }

  @computed
  get playlist(): Playlist {
    return playlistStore.findById(this.playlistID)
  }

  @computed
  get screen(): Screen {
    return screenStore.findById(this.screenID)
  }

  get active(): boolean {
    const now_wk = now_weektime()
    if (this.start_weektime < now_wk) {
      return now_wk < this.end_weektime
    }
    return now_wk + SECPERWEEK < this.end_weektime
  }

  constructor(attrs: ShowtimeInterface) {
    Object.assign(this, attrs)
  }

  asResource = (): ShowtimeResource => {
    return {
      id: Number(this.id),
      screen_id: Number(this.screenID),
      playlist_id: Number(this.playlistID),
      day_start: this.day_start,
      time_start: this.time_start,
      duration: this.duration
    }
  }

  /* Create a new instance duplicate */
  copy = (): Showtime => {
    return new Showtime(this)
  }

  equals = (other: Showtime): boolean => {
    return (
      this.id === other.id &&
      this.screenID === other.screenID &&
      this.day_start === other.day_start &&
      this.time_start === other.time_start &&
      this.duration === other.duration
    )
  }
}
