import * as React from 'react'
import {AxiosResponse} from 'axios'
import {computed} from 'mobx'
import {inject, observer} from 'mobx-react'
import {Button} from 'react-bootstrap'
import Select from 'react-select'

import {RealAsset, RealPlaylist, RealScreen, RealUser} from 'api/realsources'
import {AlertStore} from 'stores/alertStore'
import {RealStore} from 'modules/admin/adminstores'

const MAX_NAME_LEN = 25

interface BaseProps {
  id?: number
  name?: string
}

interface Props<T extends BaseProps> {
  typeName: string
  adminStore: RealStore<T>
  alertStore?: AlertStore
}

interface State<T extends BaseProps> {
  selected: T[]
  disabled: boolean
}

@inject('alertStore')
@observer
class BaseDeleteTab<T extends BaseProps> extends React.Component<
  Props<T>,
  State<T>
> {
  constructor(props: Props<T>) {
    super(props)

    this.state = {
      selected: [],
      disabled: false,
    }
  }

  @computed
  get canSubmit() {
    const {disabled, selected} = this.state

    return !disabled && selected.length > 0
  }

  optionRenderer = ({innerProps, data}) => {
    const name = data.name ? data.name.slice(0, MAX_NAME_LEN) : '<no name>'

    return <div {...innerProps}>{`${data.id}: ${name}`}</div>
  }

  onSelect = (newValues: T[]) => {
    if (!(newValues instanceof Array)) {
      newValues = []
    }

    this.setState({
      selected: newValues,
      disabled: false,
    })
  }

  onSubmit = (event) => {
    event.preventDefault()

    if (!this.canSubmit) {
      return
    }

    const selected = this.state.selected

    try {
      this.setState({disabled: true})

      const promises = selected.map((t) => this.props.adminStore.delete(t))

      Promise.all(promises)
        .then((resolved) => {
          const results = resolved.map((resp: AxiosResponse) => {
            if (resp.status && resp.status === 204) {
              return true
            } else {
              return false
            }
          })

          if (results.every(Boolean)) {
            this.props.alertStore.addAlert(
              `Successfully deleted all selected ${this.props.typeName}(s).`,
              'success',
              `Admin ${this.props.typeName} Deletion, s1`
            )

            this.setState({selected: [], disabled: false})
          } else {
            throw new Error(
              `(Partial?) failure to delete selected ${this.props.typeName}(s)`
            )
          }
        })
        .catch((err) => {
          this.props.alertStore.addAlert(
            err,
            'danger',
            `Admin ${this.props.typeName} Deletion, e2`
          )
          this.setState({disabled: false})
        })
    } catch (err) {
      this.setState({disabled: false})
      this.props.alertStore.addAlert(
        err,
        'danger',
        'An error occurred while submitting deletion.'
      )
    }
  }

  render() {
    const typeName = this.props.typeName

    return (
      <div
        id={`${typeName}-delete-tab`}
        className="admin-delete-tab admin-inner-tab"
      >
        <form onSubmit={this.onSubmit}>
          <span className="description">
            Use this form to delete existing {typeName}(s).
          </span>
          <Select
            value={this.state.selected}
            options={Array.from(this.props.adminStore.values)}
            className={`${typeName}-delete-Select admin-delete-Select`}
            getOptionLabel={(opt) => opt.name}
            getOptionValue={(opt) => String(opt.id)}
            isMulti={true}
            onChange={this.onSelect}
          />
          <Button
            type="submit"
            disabled={this.state.disabled || !this.canSubmit}
            variant="primary"
          >
            Delete {typeName}
            {this.state.selected.length === 1 ? '' : 's'}
          </Button>
        </form>
      </div>
    )
  }
}

export default BaseDeleteTab