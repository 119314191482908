/* tslint:disable:variable-name */

import {
  computed,
  IObservableArray,
  IObservableMapInitialValues,
  observable,
  ObservableMap
} from 'mobx'
import {AxiosResponse} from 'axios'

import {OrientationString, PermissionLevel, ScreenInterface} from 'globals'
import API from 'api'
import {ScreenResource, ScreenResource_Partial} from 'api/resources'
import userStore from './userStore'
import playlistStore from './playlistStore'
import {screenConverter} from './converters'
import User from './User'
import Playlist from './Playlist'
import UserPermissions from './UserPermissions'
import showtimeStore from './showtimeStore'
import Showtime from './Showtime'

interface ShowtimeCUD {
  create: Showtime[]
  update: Showtime[]
  remove: Showtime[]
}

const SCREENVALUEATTRS = [
  'title',
  'ownerID',
  'activePlaylistID',
  'orientation',
  'location',
  'institution',
  'department',
  'lat',
  'lon'
]

export default class Screen implements ScreenInterface {
  static fromResource(resource: ScreenResource): Screen {
    return new Screen(screenConverter.toModel<ScreenResource, Screen>(resource))
  }

  @observable
  id: string
  @observable
  title: string
  @observable
  ownerID: string
  @observable
  activePlaylistID: string // "none"|"multiple"|/\d+/
  @observable
  orientation: OrientationString
  @observable
  last_req: any | null // TODO: type as a moment object
  @observable
  marquee: 'string' | null
  @observable
  mq_created: any | null // TODO: type as moment object
  @observable
  mq_duration: number
  @observable
  location: string
  @observable
  playlistIDs: IObservableArray<string> = observable.array()
  @observable
  institution: string
  @observable
  department: string
  @observable
  sharedIDs: IObservableArray<string> = observable.array()
  @observable
  userPermissions: UserPermissions
  @observable
  lat: number
  @observable
  lon: number
  @observable
  thumbnails: IObservableArray<string> = observable.array()

  @computed
  get owner() {
    if (this.ownerID) {
      return userStore.findById(this.ownerID)
    } else {
      throw Error(`Screen#${this.id} has invalid owner ${this.ownerID}`)
    }
  }

  @computed
  get playlists() {
    // load playlists from playlistStore
    // filter out undefined values (in case playlists did not load yet)
    return this.playlistIDs
      .map(id => playlistStore.findById(id))
      .filter(Boolean)
  }

  @computed
  get showtimes() {
    // showtimes[plID][stID]
    return this.buildPlaylistShowtimeMap
  }

  @computed
  get sharedWith() {
    return this.userPermissions.users
  }

  @computed
  get sharedWithSortedForDisplay() {
    return this.userPermissions.usersSortedForDisplay
  }

  @computed
  get marquee_expires() {
    if (!(this.marquee && this.mq_created && this.mq_duration)) {
      return null
    }

    return new Date(this.mq_created + this.mq_duration * 60000)
  }

  constructor(attrs: ScreenInterface) {
    Object.assign(this, attrs)

    this.userPermissions = new UserPermissions(this.ownerID, false)
  }

  asResource = () => screenConverter.toResource<Screen, ScreenResource>(this)

  update = (partial: ScreenResource_Partial): Promise<Screen> =>
    API.screens
      .update(partial)
      .then((res: AxiosResponse) => {
        if (res.data && res.data.status === 'success') {
          this.merge(partial)
          return this
        } else {
          throw res
        }
      })
      .catch(err => {
        console.error(err)
        throw new Error('Failed to update screen')
      })

  merge = (partial: ScreenResource_Partial) => {
    if (partial.id.toString() !== this.id)
      throw new Error(
        `Cannot update Screen with different screen; ${partial.id} !== ${
          this.id
        }`
      )

    const allowed = [
      'orientation',
      'location',
      'lat',
      'lon',
      'marquee',
      'mq_duration',
      'mq_created'
    ]
    allowed.forEach(attr => {
      if (partial[attr] !== undefined && this[attr] !== partial[attr]) {
        this[attr] = partial[attr]
      }
    })
    // special stuff
    if (partial.name !== undefined && this.title !== partial.name) {
      this.title = partial.name
    }
    if (
      partial.ownerID !== undefined &&
      this.ownerID !== partial.ownerID.toString()
    ) {
      this.ownerID = String(partial.ownerID)
    }
    if (partial.mq_created !== undefined) {
      const marqueeCreated = new Date(partial.mq_created)
      if (marqueeCreated !== this.mq_created) {
        this.mq_created = marqueeCreated
      }
    }
  }

  refresh = () =>
    API.screens.get(this.id).then(screenR => {
      const newScrn = Screen.fromResource(screenR)
      SCREENVALUEATTRS.forEach(attr => {
        if (this[attr] !== newScrn[attr]) this[attr] = newScrn[attr]
      })
      // peek was deprecated. believe slice will work the same (creates shallow copy of array)
      if (this.playlistIDs.slice() !== newScrn.playlistIDs.slice())
        this.playlistIDs.replace(newScrn.playlistIDs)
      if (this.sharedIDs.slice() !== newScrn.sharedIDs.slice())
        this.sharedIDs.replace(newScrn.sharedIDs)
    })

  getThumbnails = () =>
    API.screens.getThumbnails(this.id).then(thumbs => {
      this.thumbnails.replace(thumbs)
      return thumbs
    })

  shareTo = (userid: string, permission: PermissionLevel) =>
    API.screens
      .shareTo(this.id, userid, permission)
      .then(resp => {
        if (200 <= resp.status && resp.status <= 204) {
          if (resp.statusText === 'Created') {
            const user = User.fromResource(resp.data.data)
            userStore.addIfNew(user)
          }

          if (this.sharedIDs.indexOf(userid) === -1) {
            this.sharedIDs.push(userid)
          }

          this.userPermissions.setPermission(userid, permission)
        } else {
          throw new Error(resp.statusText)
        }
      })
      .catch(err => {
        let msg

        if (err.response) {
          msg = err.response.statusText
        } else {
          msg = err
        }

        console.error(err)

        throw Error(msg)
      })

  unshareTo = (userid: string) =>
    API.screens
      .unshareTo(this.id, userid)
      .then((resp: AxiosResponse) => {
        if (200 <= resp.status && resp.status <= 205) {
          this.sharedIDs.remove(userid)

          this.userPermissions.unshareTo(userid)
        } else {
          throw Error(resp.statusText)
        }
      })
      .catch(err => {
        let msg

        if (err.response) {
          msg = err.response.statusText
        } else {
          msg = err
        }

        console.error(err)

        throw Error(msg)
      })

  buildPlaylistShowtimeMap = () => {
    const showtimes: IObservableMapInitialValues<any> = this.playlists.map(
      plst => {
        return [plst.id, this.buildShowtimeMap(plst)]
      }
    )
    return observable.map(showtimes) as ObservableMap<string, ObservableMap<string, Showtime>>
  }

  buildShowtimeMap = (playlist: Playlist) => {
    const showtimes = {}
    showtimeStore
      .getComboShowtimes(this.id, playlist.id)
      .forEach((st: Showtime) => {
        showtimes[st.id] = st.copy()
      })

    return observable.map(showtimes) as ObservableMap<Showtime>
  }

  diffPlaylistShowtimeMap = (
    newPsm: ObservableMap<string, ObservableMap<string, Showtime>>
  ) => {
    const create = []
    const update = []
    const remove = []

    const oldPsm = this.buildPlaylistShowtimeMap()

    oldPsm.forEach((oldStmap, plID) => {
      if (!newPsm.has(plID)) {
        // this playlist was removed
        oldStmap.forEach((st) => remove.push(st))
      } else {
        const newStmap = newPsm.get(plID)
        oldStmap.forEach(st => {
          if (!newStmap.has(st.id)) {
            // this showtime was removed
            remove.push(st)
          } else if (!st.equals(newStmap.get(st.id))) {
            // this showtime changed
            update.push(newStmap.get(st.id))
          }
          // else showtime unchanged
        })
      }
    })

    newPsm.forEach((newStmap, plID) => {
      if (!oldPsm.has(plID)) {
        // this playlist is new
        newStmap.forEach((st) => create.push(st))
      } else {
        const oldStmap = oldPsm.get(plID)
        newStmap.forEach(st => {
          if (!oldStmap.has(st.id)) {
            // this showtime was added
            create.push(st)
          }
          // changed showtimes already handled
        })
      }
    })

    return {create, update, remove} as ShowtimeCUD
  }
}
