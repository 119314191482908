import { AssetTypeString, OrientationString } from 'globals'
import Asset from './Asset'
import User from './User'
import Playlist from './Playlist'
import Screen from './Screen'
import Notification from './Notification'
import contentStore from './contentStore'
import playlistStore from './playlistStore'
import screenStore from './screenStore'
import userStore from './userStore'
import notificationStore from './notificationStore'

const STOCK_VER =
  'https://minerbytes-t3.managed.mst.edu/api/files/2017101/phpLwCVSO.png'
const STOCK_HOR =
  'https://minerbytes-t3.managed.mst.edu/api/files/2017101/phpHFBasu.png'
const FUTURE = '2019-12-22 23:59:59'
const FREEDOM = '1776-07-04 12:00:00'

const dummyAssets: Asset[] = [
  {
    id: '0',
    orientation: null as OrientationString,
    title: '0 - Nothing',
    ownerID: '2',
    description: 'Nothing asset',
    lifeSpanBegin: FREEDOM,
    lifeSpanEnd: FUTURE,
    assetType: 'IMAGE' as AssetTypeString,
    isFavorite: false,
    isPrivate: false
  },
  {
    id: '1',
    horizontalURL: STOCK_HOR,
    orientation: 'horizontal' as OrientationString,
    title: '1 - HorizOnly',
    ownerID: '2',
    description: 'Horizontal-only asset',
    lifeSpanBegin: FREEDOM,
    lifeSpanEnd: FUTURE,
    assetType: 'IMAGE' as AssetTypeString,
    isFavorite: false,
    isPrivate: false
  },
  {
    id: '2',
    verticalURL: STOCK_VER,
    orientation: 'vertical' as OrientationString,
    title: '2 - verticOnly',
    ownerID: '2',
    description: 'Vertical-only asset',
    lifeSpanBegin: FREEDOM,
    lifeSpanEnd: FUTURE,
    assetType: 'IMAGE' as AssetTypeString,
    isFavorite: false,
    isPrivate: false
  },
  {
    id: '3',
    verticalURL: STOCK_VER,
    horizontalURL: STOCK_HOR,
    orientation: null as OrientationString,
    title: '3 - Bothly',
    ownerID: '2',
    description: 'Horizontal and Vertical asset',
    lifeSpanBegin: FREEDOM,
    lifeSpanEnd: FUTURE,
    assetType: 'IMAGE' as AssetTypeString,
    isFavorite: false,
    isPrivate: false
  },
  {
    id: '4',
    verticalURL: STOCK_VER,
    orientation: 'horizontal' as OrientationString,
    title: '4 - Mismatch V/H',
    ownerID: '2',
    description: 'Vertical asset labelled horizontal',
    lifeSpanBegin: FREEDOM,
    lifeSpanEnd: FUTURE,
    assetType: 'IMAGE' as AssetTypeString,
    isFavorite: false,
    isPrivate: false
  },
  {
    id: '5',
    horizontalURL: STOCK_HOR,
    orientation: 'vertical' as OrientationString,
    title: '5 - Mismatch H/V',
    ownerID: '2',
    description: 'Horizontal asset labelled vertical',
    lifeSpanBegin: FREEDOM,
    lifeSpanEnd: FUTURE,
    assetType: 'IMAGE' as AssetTypeString,
    isFavorite: false,
    isPrivate: false
  }
].map(attrs => new Asset(attrs))

const dummyUsers: User[] = [
  {
    id: '0',
    broadcast_priv: 1 as 1,
    name: 'Nate Krofft',
    username: 'nwkvzb1',
    email: 'nwkvzb1@mst.edu',
    active: false,
    imageURL: null,
    isAdmin: false,
    institutionID: '1',
    department: 'Computer Science',
    sysAdmin: null,
    touch_dirs: null
  },
  {
    id: '1',
    broadcast_priv: 1 as 1,
    name: 'Me',
    username: 'me',
    email: 'me@mst.edu',
    active: true,
    imageURL: null,
    isAdmin: true,
    institutionID: '1',
    department: 'ITRSS',
    sysAdmin: 'me',
    touch_dirs: null
  },
  {
    id: '2',
    broadcast_priv: 1 as 1,
    name: 'Louie Bertoncin',
    username: 'lbvzd',
    email: 'lbvzd@mst.edu',
    active: true,
    imageURL: null,
    isAdmin: true,
    institutionID: '1',
    department: 'ITRSS',
    sysAdmin: null,
    touch_dirs: null
  },
  {
    id: '3',
    broadcast_priv: 1 as 1,
    name: 'Samuel Grush',
    username: 'skgn78',
    email: 'skgn78@mst.edu',
    active: true,
    imageURL: null,
    isAdmin: true,
    institutionID: '1',
    department: 'ITRSS',
    sysAdmin: null,
    touch_dirs: null
  },
  {
    id: '4',
    broadcast_priv: 1 as 1,
    name: 'Ben Giles',
    username: 'bdgmb2',
    email: 'bdgmb2@mst.edu',
    active: true,
    imageURL: null,
    isAdmin: true,
    institutionID: '1',
    department: 'ITRSS',
    sysAdmin: 'bdgmb2',
    touch_dirs: null
  },
  {
    id: '5',
    broadcast_priv: 1 as 1,
    name: 'Jared Rainwater',
    username: 'jqrvz6',
    email: 'jqrvz6@mst.edu',
    active: true,
    imageURL: null,
    isAdmin: true,
    institutionID: '1',
    department: 'ITRSS',
    sysAdmin: null,
    touch_dirs: null
  },
  {
    id: '6',
    broadcast_priv: 1 as 1,
    name: 'Levi Gunsallus',
    username: 'lsgv2b',
    email: 'lsgv2b@mst.edu',
    active: true,
    imageURL: null,
    isAdmin: false,
    institutionID: '1',
    department: 'ITRSS',
    sysAdmin: null,
    touch_dirs: null
  },
  {
    id: '7',
    broadcast_priv: 1 as 1,
    name: 'Don Howdeshell',
    username: 'dmh4k4',
    email: 'dmh4k4@mst.edu',
    active: true,
    imageURL: null,
    isAdmin: true,
    institutionID: '1',
    department: 'ITRSS',
    sysAdmin: 'dmh4k4',
    touch_dirs: null
  },
  {
    id: '8',
    broadcast_priv: 1 as 1,
    name: 'Gordon Rhea',
    username: 'rheal',
    email: 'rheal@mst.edu',
    active: true,
    imageURL: null,
    isAdmin: false,
    institutionID: '1',
    department: 'ITRSS',
    sysAdmin: 'rheal',
    touch_dirs: null
  }
].map(attrs => new User(attrs))
const me = dummyUsers[1]

const dummyPlaylists: Playlist[] = [
  {
    id: '100',
    title: 'Playlist 0',
    ownerID: dummyUsers[0].id,
    orientation: 'vertical' as OrientationString,
    inUse: true,
    promoted: false,
    assetIDs: ['0', '1', '2', '3', '4', '5'],
    sharedIDs: ['1', '2']
  },
  {
    id: '101',
    title: 'Playlist 1',
    ownerID: me.id,
    orientation: 'vertical' as OrientationString,
    inUse: false,
    promoted: false,
    assetIDs: ['0', '2', '3', '4'],
    sharedIDs: null // 0
  },
  {
    id: '102',
    title: 'Playlist 2',
    ownerID: dummyUsers[2].id,
    orientation: 'horizontal' as OrientationString,
    inUse: false,
    promoted: false,
    assetIDs: ['0', '1', '3', '5'],
    sharedIDs: ['3', '4', '5']
  },
  {
    id: '103',
    title: 'Playlist 3',
    ownerID: dummyUsers[3].id,
    orientation: 'horizontal' as OrientationString,
    inUse: true,
    promoted: false,
    assetIDs: ['0'],
    sharedIDs: ['4', '5', '6', '7']
  },
  {
    id: '104',
    title: 'Playlist 4',
    ownerID: dummyUsers[5].id,
    orientation: 'vertical' as OrientationString,
    inUse: true,
    promoted: true,
    assetIDs: ['3', '4'],
    sharedIDs: ['0']
  }
].map(attrs => new Playlist(attrs))

const dummyScreens: Screen[] = [
  {
    id: '100',
    title: 'Dead Screen',
    activePlaylistID: null,
    ownerID: '3',
    last_req: new Date(FREEDOM),
    marquee: null,
    mq_created: null,
    mq_duration: 0,
    orientation: 'vertical' as OrientationString,
    location: 'Trashcan',
    playlistIDs: [],
    institution: 'Missouri S&T',
    department: 'Physical Facilities',
    sharedIDs: [],
    lat: 37.952875,
    lon: -91.772689
  },
  {
    id: '101',
    title: 'Havener Screen',
    activePlaylistID: '102',
    ownerID: me.id,
    last_req: new Date(),
    marquee: null,
    mq_created: null,
    mq_duration: 0,
    orientation: 'horizontal' as OrientationString,
    location: 'Havener Center',
    playlistIDs: ['102', '103'],
    institution: 'Missouri S&T',
    department: 'Residential Life',
    sharedIDs: dummyUsers.slice(3, 8).map(usr => usr.id),
    lat: 37.95472,
    lon: -91.776241
  },
  {
    id: '102',
    title: 'Darth Signus',
    activePlaylistID: '100',
    ownerID: '4',
    last_req: new Date(),
    marquee: null,
    mq_created: null,
    mq_duration: 0,
    orientation: 'vertical' as OrientationString,
    location: 'Centennial Hall',
    playlistIDs: ['100', '101', '102', '103', '104'],
    institution: 'Missouri S&T',
    department: 'ITRSS',
    sharedIDs: dummyUsers.slice(0, 9).map(usr => usr.id),
    lat: 37.952984,
    lon: -91.773105
  }
].map(attrs => new Screen(attrs))

const dummyNotifications = [
  {
    id: '1',
    userID: me.id,
    messageText: "Yo your screen in Toomey hasn't checked in for a while",
    code: 1001,
    link: '/',
    itemID: '22',
    cleared: false
  },
  {
    id: '2',
    userID: me.id,
    messageText: 'test notification',
    code: 1001,
    link: '/#22',
    itemID: '22',
    cleared: false
  },
  {
    id: '3',
    userID: me.id,
    messageText: 'content',
    code: 2001,
    link: '/content#22',
    itemID: '22',
    cleared: false
  },
  {
    id: '4',
    userID: me.id,
    messageText: 'playlist',
    code: 3001,
    link: '/playlists#22',
    itemID: '22',
    cleared: false
  },
  {
    id: '5',
    userID: me.id,
    messageText: 'test notification',
    code: 1001,
    link: '/#22',
    itemID: '22',
    cleared: false
  },
  {
    id: '6',
    userID: me.id,
    messageText: 'test notification',
    code: 1001,
    link: '/#22',
    itemID: '22',
    cleared: false
  }
].map(attrs => new Notification(attrs))

function addAll() {
  dummyAssets.forEach(asst => contentStore.add(asst))
  dummyUsers.forEach(usr => userStore.add(usr))
  userStore.currentUserId = me.id
  dummyPlaylists.forEach(pllst => playlistStore.add(pllst))
  dummyScreens.forEach(scrn => screenStore.add(scrn))
  dummyNotifications.forEach(note => notificationStore.add(note))
}

export default {
  assets: dummyAssets,
  users: dummyUsers,
  playlists: dummyPlaylists,
  screens: dummyScreens,
  addAll
}
