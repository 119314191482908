import * as React from 'react'
import {Link} from 'react-router-dom'

import Footer from './home/Footer'

const Logo = require('images/elements/mb-logo.svg')

const NotFound: React.StatelessComponent = () => (
  <div id="not-found">
    <div className="billboard-wrapper">
      <div className="billboard">
        <Logo id="logo" />
        <h1>This Bytes! We can't seem to find the page you requested.</h1>
        <Link to="/" role="button" className="btn btn-lg">
          BACK TO HOME
        </Link>
      </div>
    </div>
    <Footer />
  </div>
)

export default NotFound
