import * as React from 'react'
import {useState} from 'react'
import {Container, Nav, Navbar} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import { withRouter } from 'react-router';

// Imports for routing
import {Route, Switch} from 'react-router-dom'
import HelpHome from './Help'
import HelpScreens from './HelpScreens'
import HelpPlaylists from './HelpPlaylists'
import HelpAssets from './HelpAssets'
import HelpWifi from './HelpWifi'

const HelpNav = (props) => {
  const [selectedButton, setSelectedButton] = useState(1)

  const handleClick = (id) => {
    setSelectedButton(id);
  }

  return(
  <div className="help-page">
    <Container className="help-grid">
      <h1 className="help-title">Help</h1>
    </Container>
    <Navbar className=" navbar-default navbar-help">
      <Nav  className="help-nav" activeKey={window.location.pathname}>
        <Link to="/help" id={1} onClick={() => handleClick(1)} className={1 === selectedButton ? "active-button" : ""}>Home</Link>
        <Link to="/help/screens" id={2}  onClick={() => handleClick(2)} className={2 === selectedButton ? "active-button" : ""}>Screens</Link>
        <Link to="/help/playlists" id={3}  onClick={() => handleClick(3)} className={3 === selectedButton ? "active-button" : ""}>Playlists</Link>
        <Link to="/help/assets" id={4}  onClick={() => handleClick(4)} className={4 === selectedButton ? "active-button" : ""}>Content</Link>
        <Link to="/help/wifi" id={5}  onClick={() => handleClick(5)} className={5 === selectedButton ? "active-button" : ""}> WifiConnect</Link>
      </Nav>
    </Navbar>
    {/* Used for routing to children of HelpNav */}
    <Switch>
      <Route exact path='/help' component={HelpHome}/>
      <Route exact path='/help/screens' component={HelpScreens}/>
      <Route exact path='/help/playlists' component={HelpPlaylists}/>
      <Route exact path='/help/assets' component={HelpAssets}/>
      <Route exact path='/help/wifi' component={HelpWifi}/>
    </Switch>
  </div>
)}

export default withRouter(HelpNav)
