import * as React from 'react'

import {RealAsset} from 'api/realsources'
import {AssetTypeModal} from 'modules/admin/TypeModal'

const SIMPLE_ATTRS = [
  'id',
  'name',
  'description',
  'file_type',
  'vert_url',
  'hori_url',
  'vert_file_id',
  'hori_file_id',
  'web_url',
  'web_file_id',
  'youtube_id',
  'youtube_file_id',
  'is_private',
  'widget_json',
  'avail_date_time',
  'expr_date_time',
  'created_at',
  'updated_at',
  'orientation',
  'ownerID'
]

interface AssetModalProps {
  onHide: () => void
  asset: RealAsset
  show: boolean
}

const AssetModal: React.StatelessComponent<AssetModalProps> = props => (
  <AssetTypeModal
    onHide={props.onHide}
    entity={props.asset}
    show={props.show}
    simpleAttributes={SIMPLE_ATTRS}
    id="AssetTypeModal"
    headerTitle="Asset ID#"
  />
)

export default AssetModal
