import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {Card, Accordion} from 'react-bootstrap'
import * as RB from 'react-bootstrap'
import Select from 'react-select'

import {RealUser} from 'api/realsources'
import {AlertStore} from 'stores/alertStore'
import {RealUserStore} from 'modules/admin/adminstores'
import UserForm from './form'

interface Props {
  alertStore?: AlertStore
  Accord?: Accordion
}

interface State {
  user: RealUser
  disabled: boolean
}

@inject('alertStore')
@observer
class UsersUpdateTab extends React.Component<Props, State> {
  accordionToggle: React.RefObject<HTMLButtonElement>
  constructor(props) {
    super(props)

    this.accordionToggle = React.createRef()
    this.state = {
      user: null,
      disabled: true,
    }
  }

  onSelect = (newValue: RealUser | RealUser[]) => {
    if (newValue instanceof Array)
    {
      this.setState({
        user: null,
        disabled: true,
      })
    }
    else
    {
      if (this.state.user == null)
        this.accordionToggle.current.click()
      this.setState({
        user: newValue,
        disabled: false,
      })
    }
  }

  onSubmit = (userSubmitted: RealUser, cb: (success: boolean) => void) => {
    const userID = this.state.user.id
    userSubmitted.id = userID

    RealUserStore.update(userSubmitted)
      .then((user) => {
        this.setState({
          user: RealUserStore.get(userID),
        })
        this.props.alertStore.addAlert(
          "Successfully updated user.\nIt's recommended to refresh the page.",
          'success',
          'User Admin Update'
        )

        cb(true)
      })
      .catch((err) => {
        this.props.alertStore.addAlert(err, 'danger', 'User Admin Update, e1')
        cb(false)
      })
  }

  render() {
    return (
      <div id="user-update" className="admin-inner-tab">
        <span className="description">
          Use this form to update an existing user.
        </span>
        <Select
          value={this.state.user}
          options={Array.from(RealUserStore.map.values())}
          className="user-update-select"
          getOptionLabel={(opt) => opt.name}
          getOptionValue={(opt) => String(opt.id)}
          isMulti={false}
          blurInputOnSelect={true}
          onChange={this.onSelect}
        />

        <Accordion>
          <Accordion.Toggle eventKey="0">
            <button style={{display: "none"}} ref={this.accordionToggle}/>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <UserForm
              id="user-updateform"
              formType="update"
              onSubmit={(user, callback) => this.onSubmit(user, callback)}
              disabled={this.state.disabled}
              reference={this.state.user}
            />
          </Accordion.Collapse>
        </Accordion>
      </div>
    )
  }
}

export default UsersUpdateTab