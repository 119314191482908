import * as React from 'react'

import {RealPlaylist} from 'api/realsources'
import {PlaylistTypeModal} from 'modules/admin/TypeModal'

const SIMPLE_ATTRS = [
  'id',
  'name',
  'orientation',
  'ownerID',
  'created_at',
  'updated_at',
  'assetIDs',
  'sharedIDs'
]

interface PlaylistModalProps {
  onHide: () => void
  playlist?: RealPlaylist
  show?: boolean
}

const PlaylistModal: React.StatelessComponent<PlaylistModalProps> = props => (
  <PlaylistTypeModal
    onHide={props.onHide}
    entity={props.playlist}
    show={props.show}
    simpleAttributes={SIMPLE_ATTRS}
    id="PlaylistTypeModal"
    headerTitle="Playlist ID#"
  />
)

export default PlaylistModal
