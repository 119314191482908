import * as React from 'react'
import {useState} from 'react'
import { Nav, Navbar, NavLink, Container} from 'react-bootstrap'
import { Link } from "react-router-dom";

const HelpNav = (props) => {
  const [selectedButton, setSelectedButton] = useState(1)

  const handleClick = (id) => {
    setSelectedButton(id);
  }
  return(
  <div className="preview-page  mb-3">
    <Container className="preview-container">
      <h1 className="help-title">Preview</h1>
    </Container>
    <Navbar className="navbar navbar-default preview-help">
      <Nav className="preview-nav ml-4" activeKey={props.children}>
          <Link to="/preview" id={1} onClick={() => handleClick(1)} className={1 === selectedButton ? "active-button" : ""}> Home </Link>
          <Link to="/preview/screens" id={2} onClick={() => handleClick(2)} className={2 === selectedButton ? "active-button" : ""}> Screens </Link>
          <Link to="/preview/playlists" id={3} onClick={() => handleClick(3)} className={3 === selectedButton ? "active-button" : ""}> Playlists </Link>
          <Link to="/preview/assets" id={4} onClick={() => handleClick(4)} className={4 === selectedButton ? "active-button" : ""}> Content </Link>
      </Nav>
    </Navbar>
    {/* Renders the help text only if on the main screen */}
    { props.children == "/preview" ? 
    <span className="ml-4">
      Click one of the navigation links directly above to get help on a
        specific module.
    </span>
    : null }
  </div>
  )
}

export default HelpNav
