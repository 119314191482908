import * as React from 'react'
import {computed} from 'mobx'
import {observer} from 'mobx-react'
import Glyphicon from 'glyphicons'
import moment from 'moment'

import {RealScreen} from 'api/realsources'

const VerticalIcon = require('images/elements/vertical-icon.svg')
const HorizontalIcon = require('images/elements/horizontal-icon.svg')

interface Props {
  screen: RealScreen
  onClick: (screen: RealScreen) => void
}

@observer
class ScreensIndexRow extends React.Component<Props> {
  @computed
  get orientationIcon() {
    switch (this.props.screen.orientation) {
      case 'vertical':
        return <VerticalIcon className="orientation-icon vertical" />
      case 'horizontal':
        return <HorizontalIcon className="orientation-icon horizontal" />
      default:
        return null
    }
  }

  @computed
  get ownerStatus() {
    if (this.props.screen.ownerID === null || isNaN(this.props.screen.ownerID))
      return (
        <span
          className="fa fa-user screenstatus screenstatus-no-owner"
          title="No owner"
          style={{color: 'red'}}
        />
      )

    return null
  }

  @computed
  get checkedInStatus() {
    if (this.props.screen.last_req === null)
      return (
        <span
          className="fa fa-clock screenstatus screenstatus-never-checked-in"
          title="Never checked in"
          style={{color: 'red'}}
        />
      )

    const diffHrs = moment().diff(this.props.screen.last_req) / (1000 * 60 * 60)
    if (diffHrs < -4 || 24 < diffHrs) {
      const difftxt = moment(this.props.screen.last_req).fromNow()
      return (
        <span
          className="fa fa-clock screenstatus screenstatus-late-check-in"
          title={`Last check in ${difftxt}`}
          style={{color: 'orange'}}
        />
      )
    }
    return null
  }

  render() {
    return (
      <tr
        data-screenid={this.props.screen.id}
        data-ownerid={this.props.screen.ownerID || 'none'}
        onClick={() => this.props.onClick(this.props.screen)}
      >
        <td>{this.props.screen.id}</td>
        <td title={this.props.screen.name}>{this.props.screen.name}</td>
        <td title={this.props.screen.orientation}>{this.orientationIcon}</td>
        <td>
          {this.ownerStatus}
          {this.checkedInStatus}
        </td>
      </tr>
    )
  }
}

export default ScreensIndexRow
