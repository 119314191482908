/* tslint:disable:max-classes-per-file */

import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {computed, observable} from 'mobx'
import {Accordion, Button, Card, Form, FormControl, FormLabel} from 'react-bootstrap'
import Select from 'react-select'
import Glyphicon from 'glyphicons'
import {nameToFnameLname, ValidationState} from 'globals'
import {AlertStore} from 'stores/alertStore'
import {RealInstitution, RealUser} from 'api/realsources'
import {RealInstitutionStore} from 'modules/admin/adminstores'
import {netValidity} from '../formhelpers'

// NOT RFC-5322 official standard, but is W3C standard
const EMAIL_RE = /^[a-z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/i
const USERNAME_RE = /^[a-z0-9](\.?[a-z0-9_-])+$/i

/*
 username
 name           `${fname} ${lname}`
 fname          name[0]
 lname          name[1:]
 email
 active         (default: 0)
 img_url
 broadcast_priv     (default: 0)
 is_admin       (default: 0)
 institution_id
 dept
 sys_admin
*/

interface Props {
  formType: 'create' | 'update'
  onSubmit: (user: RealUser, cb: (success: boolean) => void) => void
  id?: string
  className?: string
  reference?: RealUser
  disabled?: boolean
  alertStore?: AlertStore
}

interface State {
  disabled: boolean
  permPanelOpen: boolean
  instPanelOpen: boolean
}

function userFormPropsUnchanged(props, nextProps) {
  return ['formType', 'id', 'className', 'reference', 'disabled'].every(
    (prop) => {
      return props[prop] === nextProps[prop]
    }
  )
}

@inject('alertStore')
@observer
class UserForm extends React.Component<Props, State> {
  static defaultProps = {
    id: 'UserForm',
    className: '', // no default; UserForm is always applied
    reference: null,
    disabled: false,
  }

  static stateDefaults(props: Props): State {
    return {
      disabled: props.disabled,
      permPanelOpen: false,
      instPanelOpen: false,
    }
  }

  validDefaults = {
    name: 'warning' as 'warning',
    email: 'warning' as 'warning',
    username: 'warning' as 'warning',
    active: 'success' as 'success',
    broadcast_priv: 'success' as 'success',
    is_admin: 'success' as 'success',
    institution_id: 'warning' as 'warning',
    dept: 'warning' as 'warning',
  }

  problemsDefaults = {
    name: 'Name required',
    email: 'Email required',
    username: null,
    active: null,
    broadcast_priv: null,
    is_admin: null,
    institution_id: 'Institution required',
    dept: 'Department required',
  }

  inputRefs: {
    name: React.RefObject<HTMLInputElement>,
    email: React.RefObject<HTMLInputElement>,
    username: React.RefObject<HTMLInputElement>,
    active: HTMLInputElement,
    broadcast_priv: HTMLInputElement,
    is_admin: HTMLInputElement,
    dept: React.RefObject<HTMLInputElement>
  }

  values: {
    name: string
    email: string
    username: string
    active: boolean
    broadcast_priv: boolean
    is_admin: boolean
    institution: RealInstitution
    dept: string
  }

  valid: {
    name: ValidationState
    email: ValidationState
    username: ValidationState
    active: ValidationState
    broadcast_priv: ValidationState
    is_admin: ValidationState
    institution_id: ValidationState
    dept: ValidationState
  }

  problems: {
    name: string | null
    email: string | null
    username: string | null
    active: string | null
    broadcast_priv: string | null
    is_admin: string | null
    institution_id: string | null
    dept: string | null
  }

  constructor(props: Props) {
    super(props)

    if (!props.disabled && props.formType === 'update' && !props.reference)
      throw new Error(
        "UserForm requires prop 'reference' when prop 'formType' == \"update\" and !'disabled'"
      )

    this.inputRefs = observable.object({
      name: React.createRef(),
      email: React.createRef(),
      username: React.createRef(),
      active: null,
      broadcast_priv: null,
      is_admin: null,
      dept: React.createRef()
    })

    this.valid = observable.object(this.validDefaults)
    this.values = observable.object(this.valuesDefault(props))
    this.problems = observable.object(this.problemsDefaults)
    this.state = UserForm.stateDefaults(props)
  }

  valuesDefault = (props: Props) => {
    return {
      name: this.getDefault('name', '', props),
      email: this.getDefault('email', '', props),
      username: this.getDefault('username', '', props),
      active: Boolean(this.getDefault('active', 1, props)),
      broadcast_priv: Boolean(this.getDefault('broadcast_priv', 0, props)),
      is_admin: Boolean(this.getDefault('is_admin', 0, props)),
      institution: RealInstitutionStore.get(
        this.getDefault('institution_id', null, props)
      ),
      dept: this.getDefault('dept', '', props),
    }
  }

  @computed
  get canSubmit(): boolean {
    const valids = [
      this.valid.name,
      this.valid.email,
      this.valid.username,
      this.valid.institution_id,
      this.valid.dept,
      this.valid.active,
      this.valid.broadcast_priv,
      this.valid.is_admin,
    ]

    return !this.state.disabled && netValidity(valids) === 'success'
  }

  get statusOptionsValid(): ValidationState {
    return netValidity([
      this.valid.active,
      this.valid.broadcast_priv,
      this.valid.is_admin,
    ])
  }

  @computed
  validateName(): boolean {
    return this.valid.name == "success"
  }

  @computed
  validateEmail(): boolean {
    return this.valid.email == "success"
  }

  @computed
  validateUsername(): boolean {
    return this.valid.username == "success"
  }

  @computed
  validateDepartment(): boolean {
    return this.valid.dept == "success"
  }



  @computed
  get suggestedUsername(): string {
    if (this.values.email) {
      let uname = this.values.email
      if (this.values.email.includes('@'))
        uname = this.values.email.split('@', 1)[0]

      if (USERNAME_RE.test(uname)) return uname
    }
    return ''
  }

  @computed
  get suggestedUnameUsed(): boolean {
    return this.values.username === this.suggestedUsername
  }

  componentDidUpdate(lastProps: Props) {
    if (userFormPropsUnchanged(lastProps, this.props)) {
      return
    }

    if (
      !this.props.disabled &&
      this.props.formType === 'update' &&
      !this.props.reference
    ) {
      throw new Error(
        "UserForm requires prop 'reference' when prop 'formType' == \"update\" and !'disabled'"
      )
    }

    this.reset(null, this.props)
  }

  getDefault = (field: string, defaultValue: any = null, props?: Props) => {
    props = props || this.props

    if (props.reference) {
      return props.reference[field]
    }

    return defaultValue
  }

  onNameChange = (event?) => {

    const value = event ? event.target.value : this.values.name

    let valid = null
    let problems = null

    this.values.name = value

    if (!value) {
      valid = 'warning'
      problems = 'Name required'
    } else if (
      !(
        this.props.formType === 'update' &&
        this.props.reference.name === value.trim()
      )
    ) {
      valid = 'success'
    }

    this.valid.name = valid
    this.problems.name = problems
  }

  onEmailChange = (event?) => {
    const value = event ? event.target.value.trim() : this.values.email
    let valid = null
    let problems = null
    let updateUname = false

    if (value.includes('@')) {
      if (this.values.email.startsWith(`${this.values.username}@`)) {
        updateUname = true
      }
    } else if (this.values.email === this.values.username) {
      updateUname = true
    }

    this.values.email = value

    if (updateUname) {
      this.inputRefs.username.current.value = value.split('@', 1)[0]
      this.onUsernameChange()
    }

    if (!value) {
      valid = 'warning'
      problems = 'Email address required'
    } else if (!EMAIL_RE.test(value)) {
      valid = 'error'
      problems = 'Invalid email address'
    } else if (
      !(
        this.props.formType === 'update' && this.props.reference.email === value
      )
    ) {
      valid = 'success'
    }

    this.valid.email = valid
    this.problems.email = problems
  }

  onUsernameChange = (event?) => {
    const value = event ? event.target.value.trim() : this.values.username
    let valid = null
    let problems = null

    this.values.username = value

    if (!value) {
      valid = 'warning'
      problems = 'Username required'
    } else if (!USERNAME_RE.test(value)) {
      valid = 'error'
      problems = 'Invalid username'
    } else if (
      !(
        this.props.formType === 'update' &&
        this.props.reference.username === value
      )
    ) {
      valid = 'success'
    }

    this.valid.username = valid
    this.problems.username = problems
  }

  onActiveChange = (event?) => {
    if (this.inputRefs.active) {
      const value = Boolean(this.inputRefs.active.checked)

      this.values.active = value
      this.valid.active =
        this.props.formType === 'update' &&
        Boolean(this.props.reference.active) === value
          ? null
          : 'success'
    }
  }

  onAlertPrivChange = (event?) => {
    if (this.inputRefs.broadcast_priv) {
      const value = Boolean(this.inputRefs.broadcast_priv.checked)

      this.values.broadcast_priv = value
      this.valid.broadcast_priv =
        this.props.formType === 'update' &&
        Boolean(this.props.reference.broadcast_priv) === value
          ? null
          : 'success'
    }
  }

  onIsAdminChange = (event?) => {
    if (this.inputRefs.is_admin) {
      const value = Boolean(this.inputRefs.is_admin.checked)

      this.values.is_admin = value
      this.valid.is_admin =
        this.props.formType === 'update' &&
        Boolean(this.props.reference.is_admin) === value
          ? null
          : 'success'
    }
  }

  onInstitutionChange = (value: RealInstitution) => {
    let valid = null
    let problems = null

    if (value && value instanceof Object) {
      if (
        !(
          this.props.formType === 'update' &&
          this.props.reference.institution_id === value.id
        )
      ) {
        valid = 'success'
      }
    } else {
      value = null
      valid = value ? 'error' : 'warning'
      problems = value ? 'Invalid institution' : 'Must select an institution'
    }

    this.values.institution = value
    this.valid.institution_id = valid
    this.problems.institution_id = problems
  }

  onDeptChange = (event?) => {
    let value = event ? event.target.value : this.values.dept
    let valid = null
    let problems = null

    this.values.dept = value

    value = value.trim()
    if (!value) {
      valid = 'warning'
      problems = 'Department required'
    } else if (
      !(this.props.formType === 'update' && this.props.reference.dept === value)
    ) {
      valid = 'success'
    }

    this.valid.dept = valid
    this.problems.dept = problems
  }

  onSubmit = (event) => {
    event.preventDefault()

    if (!this.canSubmit) return

    try {
      this.setState({disabled: true})

      const [fname, lname] = nameToFnameLname(this.values.name.trim())

      const newUser: RealUser = {
        name: this.values.name.trim(),
        fname,
        lname,
        email: this.values.email.trim(),
        username: this.values.username.trim(),
        active: this.values.active ? 1 : 0,
        broadcast_priv: this.values.broadcast_priv ? 1 : 0,
        is_admin: this.values.is_admin ? 1 : 0,
        institution_id: this.values.institution.id,
        dept: this.values.dept.trim(),
      }

      if (this.props.formType === 'update') {
        newUser.id = this.props.reference.id
      }

      this.props.onSubmit(newUser, (success) => this.onSubmitCallback(success))
    } catch (err) {
      this.setState({disabled: false})
      this.props.alertStore.addAlert(
        err,
        'danger',
        'An error occurred while submitting'
      )
    }
  }

  onSubmitCallback = (success: boolean) => {
    this.reset(null)
  }

  togglePermPanel = () => {
    this.setState({permPanelOpen: !this.state.permPanelOpen})
  }

  reset = (event, props: Props = null) => {
    if (event) {
      event.preventDefault()
    }

    if (!props) {
      props = this.props
    }

    Object.assign(this.values, this.valuesDefault(props))
    this.setState(UserForm.stateDefaults(props), () => {
      if (props && props.reference) {

        this.inputRefs.name.current.value = props.reference.name
        this.inputRefs.email.current.value = props.reference.email
        this.inputRefs.username.current.value = props.reference.username
        this.inputRefs.dept.current.value = props.reference.dept
        this.inputRefs.broadcast_priv.checked = Boolean(props.reference.broadcast_priv)
        this.inputRefs.is_admin.checked = Boolean(props.reference.is_admin)
        this.inputRefs.active.checked = Boolean(props.reference.active)
        this.onNameChange()
        this.onEmailChange()
        this.onUsernameChange()
        this.onActiveChange()
        this.onAlertPrivChange()
        this.onIsAdminChange()
        this.onInstitutionChange(
          RealInstitutionStore.get(props.reference.institution_id)
        )
        this.onDeptChange()
      } else {
        Object.assign(this.valid, this.validDefaults)
        Object.assign(this.problems, this.problemsDefaults)
      }
    })
  }


  render() {

    return (
      <form
        id={this.props.id}
        className={`UserForm ${this.props.className}`}
        onSubmit={this.onSubmit}
        noValidate
      >
        {/* NAME */}
        <Form.Group
          className="UserForm-name-group"
          controlId={`${this.props.id}-name`}
        >
          <FormLabel>Name</FormLabel>
          <FormControl
            type="text"
            isInvalid={this.problems.name !== null}
            onChange={this.onNameChange}
            title={this.problems.name}
            required
            disabled={this.state.disabled}
            inputMode="text"
            placeholder="Joe Miner"
            ref={this.inputRefs.name}
          />
          <FormControl.Feedback />
        </Form.Group>
        {/* EMAIL */}
        <Form.Group
          className="UserForm-email-group"
          controlId={`${this.props.id}-email`}
        >
          <FormLabel>E-Mail</FormLabel>
          <FormControl
            type="email"
            value={this.values.email}
            isInvalid={this.problems.email !== null}
            onChange={this.onEmailChange}
            title={this.problems.email}
            required
            disabled={this.state.disabled}
            inputMode="email"
            placeholder="jminer@mst.edu"
            ref={this.inputRefs.email}
          />
          <FormControl.Feedback />
        </Form.Group>
        {/* USERNAME */}
        <Form.Group
          className={
            'UserForm-username-group' +
            (this.suggestedUnameUsed ? ' username-from-email' : '')
          }
          controlId={`${this.props.id}-username`}
        >
          <FormLabel>Username</FormLabel>
          <FormControl
            type="text"
            value={this.values.username}
            isInvalid={this.problems.username !== null}
            onChange={this.onUsernameChange}
            title={this.problems.username}
            required
            disabled={this.state.disabled}
            pattern={USERNAME_RE.source}
            inputMode="text"
            placeholder={this.suggestedUsername}
            ref={this.inputRefs.username}
          />
          <FormControl.Feedback />
        </Form.Group>
        {/* INSTITUTION */}
        <Form.Group
          className="UserForm-institution-group"
          controlId={`${this.props.id}-institution`}
        >
          <FormLabel title={this.problems.institution_id}>
            Institution
          </FormLabel>
          <InstitutionSelect
            value={this.values.institution}
            id={`${this.props.id}-institution`}
            onChange={(value) => this.onInstitutionChange(value)}
            title={this.problems.institution_id}
            disabled={this.state.disabled}
          />
        </Form.Group>
        {/* DEPARTMENT */}
        <Form.Group
          className="UserForm-dept-group"
          controlId={`${this.props.id}-dept`}
        >
          <FormLabel title={this.problems.dept}>Department</FormLabel>
          <FormControl
            type="text"
            value={this.values.dept}
            isInvalid={this.problems.dept !== null}
            onChange={this.onDeptChange}
            title={this.problems.dept}
            required
            disabled={this.state.disabled}
            ref={this.inputRefs.dept}
          />
          <FormControl.Feedback />
        </Form.Group>
        {/* Status Options */}
        <Accordion>
          <Form.Group
            className="panel-label-fg"
          >
            <Accordion.Toggle as={FormLabel} className="panel-label" eventKey="0">
              Status Options&hellip;
            </Accordion.Toggle>
          </Form.Group>
          <Accordion.Collapse eventKey="0">
            <Card>
              <Form.Group
                className="UserForm-active-group"
                controlId={`${this.props.id}-active`}
              >
                <Form.Check
                  id={`${this.props.id}-active`}
                  onChange={this.onActiveChange}
                  title={this.problems.active}
                  disabled={this.state.disabled}
                  label="Active"
                  ref={(ref) => this.inputRefs.active = ref}
                  inline
                />
                <span className="fa fa-check"/>
              </Form.Group>
              <Form.Group
                className="UserForm-broadcast_priv-group"
                controlId={`${this.props.id}-broadcast_priv`}
              >
                <Form.Check
                  id={`${this.props.id}-broadcast_priv`}
                  onChange={this.onAlertPrivChange}
                  title={this.problems.broadcast_priv}
                  disabled={this.state.disabled}
                  label="Can Broadcast"
                  ref={(ref) => this.inputRefs.broadcast_priv = ref}
                  inline
                />
                <span className="fa fa-bullhorn"/>
              </Form.Group>
              <Form.Group
                className="UserForm-is_admin-group"
                controlId={`${this.props.id}-is_admin`}
              >
                <Form.Check
                  id={`${this.props.id}-is_admin`}
                  onChange={this.onIsAdminChange}
                  title={this.problems.is_admin}
                  disabled={this.state.disabled}
                  label="Is Admin"
                  ref={(ref) => this.inputRefs.is_admin = ref}
                  inline
                />
                <span className="fa fa-key"/>
              </Form.Group>
            </Card>
          </Accordion.Collapse>
        </Accordion>
        <Button type="submit" disabled={!this.canSubmit} variant="primary">
          {this.props.formType === 'create' ? 'Create' : 'Update'} User
        </Button>
        <Button
          type="button"
          onClick={this.reset}
          style={{
            backgroundColor: "#ffffff"
          }}
        >
          Reset
        </Button>
      </form>
    )
  }
}

interface InstitutionSelectProps {
  value: RealInstitution
  onChange: (newValue: RealInstitution) => void
  id?: string
  disabled?: boolean
  title?: string
  name?: string
  resultLimit?: number
}

@observer
export class InstitutionSelect extends React.Component<InstitutionSelectProps> {
  static defaultProps = {
    id: 'InstitutionSelect',
    disabled: false,
    title: null,
    name: 'institution',
    resultlimit: 5,
  }

  render() {
    return (
      <Select
        {...this.props}
        getOptionValue={(opt) => String(opt.id)}
        getOptionLabel={(opt) => opt.name}
        isMulti={false}
        blurInputOnSelect={true}
        options={Array.from(RealInstitutionStore.map.values())}
      />
    )
  }
}

export default UserForm
