import * as React from 'react'

interface Props {
  minimized: boolean
  customClass: string
}

const SideNavSelector: React.StatelessComponent<Props> = props => {
  const minimized = props.minimized ? ' minimized' : ''
  const className = 'side-nav-selector ' + props.customClass + minimized
  return (
    <div className={className}>
      <div className={'l2r-curve-join' + minimized}>
        <div className="stroke" />
      </div>
    </div>
  )
}

export default SideNavSelector
