import * as React from 'react'
import {Col, Container, Row} from 'react-bootstrap'

import HelpImageMap, {HelpContent} from './HelpImageMap'

const newPlaylistImage = require('images/urls/new-playlist.png')
const playlistsImage = require('images/urls/help-playlists.png')
const changeViewImage = require('images/urls/playlist-change-view.png')
const editPlaylistImage = require('images/urls/edit-playlist.png')
const assetSchedulerImage = require('images/urls/asset-scheduler.png')
const sharePlaylistImage = require('images/urls/share-with.png')
const deletePlaylistImage = require('images/urls/delete-playlist.png')

const newPlaylist: HelpContent = {
  title: 'New Playlist - Create a New Playlist',
  body: (
    <div>
      <img className="newplaylist-image" src={newPlaylistImage} />
      <br /> <br />
      <ol className="">
        <li>Enter a name for the new playlist.</li>
        <li>Click "Create Playlist" to confirm new playlist.</li>
      </ol>
    </div>
  ),
  coordinates: {
    left: 4,
    top: 2,
    width: 19,
    height: 21,
  },
}

const viewMode: HelpContent = {
  title: 'Change View',
  body: (
    <div>
      <img className="changeview-image" src={changeViewImage} />
      <br /> <br />
      <span>Switches Playlists page between Card View and List View.</span>
    </div>
  ),
  coordinates: {
    left: 27,
    top: 2,
    width: 30,
    height: 13,
  },
}

const editPlaylist: HelpContent = {
  title: 'Edit Playlist - Edit Content and Duration Settings',
  body: (
    <div>
      <img className="editplaylist-image" src={editPlaylistImage} />
      <br /> <br />
      <Container>
        <Row>
          <Col md={8}>
            <ul className="">
              <li>
                <strong>Duration</strong> - Duration to display an asset.
              </li>
              <li>
                <strong>Enabled</strong> - Enable or Disable the display of an
                asset.
              </li>
              <li>
                <strong>Asset Scheduler</strong> - Schedule when an asset is
                shown during a week.
              </li>
              <ul className="sub-list">
                <li>
                  <strong>Display Time Range</strong> - Set the time of day an
                  asset should be displayed.
                </li>
                <li>
                  <strong>Display Days</strong> - Select which days of the week
                  an asset should be displayed.
                </li>
                <li>
                  <strong>Display Date Range</strong> - Date range that the
                  asset should be displayed.
                </li>
              </ul>
              <li>
                Use the three horizontal bars to drag and drop asssets and
                change the asset display order in the playlist.
              </li>
              <li>
                Click the playlist name at the top of the page to rename a
                playlist.
              </li>
            </ul>
          </Col>
          <Col md={4}>
            <img className="editplaylist-image" src={assetSchedulerImage} />
          </Col>
        </Row>
      </Container>
    </div>
  ),
  coordinates: {
    left: 29,
    top: 87.5,
    width: 23,
    height: 9,
  },
}

const sharePlaylist: HelpContent = {
  title: 'Share Playlist - Collaborate With Others',
  body: (
    <div>
      <img className="changeview-image" src={sharePlaylistImage} />
      <br /> <br />
      <ol className="">
        <li>Begin typing a name in the text box to view suggestions.</li>
        <li>Click a name to select the user.</li>
        <li>Click the icon next to the text box to select user permissions.</li>
        <li>Click 'ADD' to share the playlist with the users.</li>
        <ul className="sub-list">
          <li>
            Viewers may only view playlist information, such as name, assets,
            etc.
          </li>
          <li>Editors may edit a screen's assets.</li>
          <li>Owners may edit, share, and delete a playlist.</li>
        </ul>
      </ol>
      <ul>
        <li>
          Use the dropdown next to a user to change user's permission levels.
        </li>
        <li>Select the X to un-share the playlist with a user.</li>
      </ul>
    </div>
  ),
  coordinates: {
    left: 52,
    top: 87.5,
    width: 21,
    height: 9,
  },
}

const deletePlaylist: HelpContent = {
  title: 'Delete Playlist',
  body: (
    <div>
      <img className="changeview-image" src={deletePlaylistImage} />
      <br /> <br />
      <span>Click "YES" to confirm the deletion of a playlist.</span>
    </div>
  ),
  coordinates: {
    left: 73,
    top: 87.5,
    width: 23,
    height: 9,
  },
}

const imageMap: HelpContent[] = [
  newPlaylist,
  viewMode,
  editPlaylist,
  sharePlaylist,
  deletePlaylist,
]

const HelpPlaylists: React.StatelessComponent = () => (
  <div className="help-playlists">
    <div className="help-content">
      <Container className="help-grid">
          <h1>Playlists</h1>
          <h4>Click on a button to learn more about a function.</h4>
          <p>
            Playlists are a collection of assets to be displayed on a screen.
            Playlists display content for a defined period of time, and in a
            specified order.
          </p>
        <Row>
          <HelpImageMap image={playlistsImage} content={imageMap} />
        </Row>
      </Container>
    </div>
  </div>
)

export default HelpPlaylists
