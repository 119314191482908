import * as React from 'react'
import {FormControl} from 'react-bootstrap'

interface Props {
  onDateChange: any
  date?: string
  error?: boolean
}

interface State {
  valid: boolean
}

const dateRegex = /^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{2})$/

class DatePicker extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      valid: true
    }
  }

  onDateChange = e => {
    const date = e.target.value
    const valid = dateRegex.test(date)

    this.setState({valid})

    this.props.onDateChange({
      date,
      valid
    })
  }

  render() {
    return (
      <FormControl
        className={!this.state.valid || this.props.error ? 'invalid' : ''}
        type="text"
        placeholder={'mm/dd/yy'}
        value={this.props.date}
        onChange={this.onDateChange}
      />
    )
  }
}

export default DatePicker
