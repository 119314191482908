import * as React from 'react'
import {Button, Row} from 'react-bootstrap'
import {inject, observer} from 'mobx-react'

import Asset from 'stores/Asset'
import {ContentStore} from 'stores/contentStore'
import {AppStateStore} from 'stores/appStateStore'
import FavoritesContentCard from './FavoritesContentCard'

interface Props {
  addAssetsToPlaylist: () => void
  contentStore?: ContentStore
  appStateStore?: AppStateStore
}
const SideNavFavorites: React.StatelessComponent<Props> = inject(
  'contentStore',
  'appStateStore'
)(
  observer((props) => (
    <div className="favorites-side-nav">
      <Row className="favorites-cards-container">
        {props.contentStore.favorites.map((asset, i) => (
          <FavoritesContentCard key={asset.id} asset={asset} />
        ))}
      </Row>
      <hr className="nested-hr" />

      <Button
        block
        style={{backgroundColor: "white"}}
        className="add-button"
        type="submit"
        onClick={() => {
          props.appStateStore.toggleMultiSelect();
          props.addAssetsToPlaylist();
        }}
        disabled={props.appStateStore.multiSelectedAssets.length === 0}
      >
        Add To Playlist
      </Button>
    </div>
  ))
)

export default SideNavFavorites
