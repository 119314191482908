import * as React from 'react'
import {Button, Form, FormControl, InputGroup} from 'react-bootstrap'
import {inject, observer} from 'mobx-react'

import Screen from 'stores/Screen'
import {AlertStore} from 'stores/alertStore'

interface Props {
  screen: Screen
  alertStore?: AlertStore
}

interface State {
  nickname: string
  status: string
  canSubmit: boolean
  canEdit: boolean
}

@inject('alertStore')
@observer
class NicknameControl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      nickname: props.screen.title,
      status: null,
      canSubmit: false,
      canEdit: true,
    }
  }

  onSubmit = (event) => {
    event.preventDefault()

    const nickname = this.state.nickname.trim()

    if (nickname.length && nickname !== this.props.screen.title) {
      this.setState({
        status: 'Updating...',
        canSubmit: false,
        canEdit: false,
      })

      const screenResource = {
        id: Number(this.props.screen.id),
        name: nickname,
      }

      this.props.screen
        .update(screenResource)
        .then((screen: Screen) => {
          this.setState({
            status: 'Updated!',
            canEdit: true,
            canSubmit: false,
          })
        })
        .catch((err) => {
          console.error('Error caught updating screen nickname:', err)
          this.setState({
            status: 'Failed',
            canSubmit: true,
            canEdit: true,
          })
          this.props.alertStore.addAlert(
            err,
            'danger',
            'Error updating screen nickname [2]'
          )
        })
    }
  }

  onInputChange = (event) => {
    const nickname = event.target.value

    this.setState({
      nickname,
      status: null,
      canEdit: true,
      canSubmit: nickname.length && nickname.trim() !== this.props.screen.title,
    })
  }

  render() {
    return (
      <div className="screen-expanded-control nickname-control">
        <h5 className="sec-title">CHANGE NICKNAME</h5>
        <form onSubmit={this.onSubmit}>
          <Form.Group
            controlId={`screen-nickname-form-${this.props.screen.id}`}
          >
            <InputGroup>
              <FormControl
                type="text"
                value={this.state.nickname}
                onChange={this.onInputChange}
              />
              <InputGroup.Prepend>
                <Button type="submit" disabled={!this.state.canSubmit}>
                  <span className="fa fa-check"/>
                </Button>
              </InputGroup.Prepend>
            </InputGroup>
          </Form.Group>
        </form>
      </div>
    )
  }
}

export default NicknameControl
