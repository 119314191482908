/* tslint:disable:variable-name */

import {computed, observable} from 'mobx'

import {fnameLnameToName, nameToFnameLname, UserInterface} from 'globals'
import {
  DirectoryPermissions,
  UserResource,
  UserResource_Partial
} from 'api/resources'
import institutionStore from './institutionStore'
import Institution from './Institution'

export default class User implements UserInterface {
  static fromResource(resource: UserResource): User {
    return new User({
      id: resource.id.toString(),
      broadcast_priv: resource.broadcast_priv,
      username: resource.username,
      fname: resource.fname,
      lname: resource.lname,
      email: resource.email,
      active: Boolean(resource.active),
      imageURL: window.localStorage.profileImage,
      isAdmin: Boolean(resource.is_admin),
      institutionID: resource.institution_id.toString(),
      department: resource.dept,
      sysAdmin: resource.sys_admin,
      touch_dirs: resource.dir_directory_ids
    })
  }

  @observable id: string
  @observable broadcast_priv: 0 | 1
  @observable username: string
  @observable fname: string
  @observable lname: string
  @observable email: string
  @observable active: boolean
  @observable imageURL: string
  @observable isAdmin: boolean
  @observable institutionID: string
  @observable department: string
  @observable sysAdmin: string
  @observable touch_dirs: DirectoryPermissions[] = observable.array()

  @computed
  get name(): string {
    const {fname, lname} = this
    return fnameLnameToName(fname, lname)
  }

  @computed
  get institution(): Institution {
    return institutionStore.findById(this.institutionID)
  }

  constructor(attrs: UserInterface) {
    const fullname = (attrs as any).name
    if (fullname) delete (attrs as any).name
    Object.assign(this, attrs)

    if (fullname && (!attrs.fname && !attrs.lname)) {
      [this.fname, this.lname] = nameToFnameLname(fullname)
    }
  }

  asResource = (): UserResource => {
    return {
      id: Number(this.id),
      broadcast_priv: this.broadcast_priv,
      username: this.username,
      name: this.name,
      fname: this.fname,
      lname: this.lname,
      email: this.email,
      active: Number(this.active),
      is_admin: Number(this.isAdmin),
      dept: this.department,
      institution_id: Number(this.institutionID),
      sys_admin: this.sysAdmin,
      dir_directory_ids: this.touch_dirs
    }
  }

  update = (partial: UserResource_Partial) => {
    if (partial.id.toString() !== this.id)
      throw Error('Cannot update user with different user')
    if (partial.name) {
      [this.fname, this.lname] = nameToFnameLname(partial.name)
    } else if (partial.fname && partial.lname !== null) {
      this.fname = partial.fname
      this.lname = partial.lname
    }
    if (partial.username) this.username = partial.username
    if (partial.active !== undefined) this.active = Boolean(partial.active)
  }
}
