import * as React from 'react'
import {Container} from 'react-bootstrap'

const HelpWifi: React.StatelessComponent = () => (
  <div className="help-wifi">
    <Container className="help-grid">
      <h1>WifiConnect Q&A</h1>
      <p className="q">Q: What is WifiConnect?</p>
      <p>
        A: WifiConnect is designed to help remove distracting wiring from your
        MinerBytes sign. This not only makes your MinerBytes sign look more
        elegant, but makes connecting to the internet even easier.
      </p>
      <p className="q">Q: How does WifiConnect work?</p>
      <p>
        A: WifiConnect works by connecting your MinerBytes sign to the wireless
        network you select. When you start WifiConnect on your sign, you can
        connect to your sign directly using your smartphone, laptop, or any
        other device that can connect to a Wi-Fi network. Once connected, your
        broswer will be redirected to the MinerBytes WifiConnect page where you
        will be prompted to select a wireless network and enter the network
        password (if necessary). Once you submit this information, your screen
        will attempt to connect to the wireless network that you have selected.
      </p>
      <p className="q">Q: When would I need to use WifiConnect?</p>
      <p>
        A: WifiConnect simplifies the process of connecting your MinerBytes sign
        to the internet. You would typically use it when relocating your sign,
        or if your Wi-Fi network settings have changed.
      </p>
      <p className="q">Q: Do I need to use WifiConnect?</p>
      <p>
        A: No, you do not! WifiConnect was designed to remove the need for a
        wired connection. You can still use a wired connection with your
        MinerBytes sign.
      </p>
      <p className="q">Q: When should I use a wired connection?</p>
      <p>
        A: When an active network port is nearby and wires aren't an issue, you
        should consider using a wired connection. Wired connections are almost
        always more stable than wireless connections when available.
      </p>
      <p className="q">Q: How do I start WifiConnect?</p>
      <p>
        A: On your MinerBytes BrainBox there is a button with the Wi-Fi Icon.
        Press this button and WifiConnect will display the next steps on your
        sign.
      </p>
      <p className="q">
        Q: What happens if I have a wired connection and a wireless connection
        at the same time?
      </p>
      <p>A: The wired connection will take priority.</p>
      <p className="q">
        Q: What if my wireless connection uses a captive portal?
      </p>
      <p>
        A: Unfortunately, MinerBytes does not currently support connecting
        through captive portals.
      </p>
      <p className="q">Q: What is a captive portal?</p>
      <p>
        A: A captive portal is a webpage where many organizations with public or
        semi-public Wi-Fi networks require the user to provide a password,
        accept terms of service, or complete some other user input. Captive
        portals are typically found in airports, hotels, coffee shops, and other
        venues.
      </p>
      <p className="q">Q: How do I know if WifiConnect is working?</p>
      <p>
        A: Once you submit your wireless network information, your MinerBytes
        sign should connect to the internet within 15-30 seconds. If your sign
        is still displaying the red network warning symbol and your playlist
        isn't loading, then the information you entered is incorrect or the
        network you're connecting to may be blocking your access to the
        internet.
      </p>
    </Container>
  </div>
)

export default HelpWifi
