import * as React from 'react'

import {observer} from 'mobx-react'
import {computed} from 'mobx'
import {Accordion, Button, Card, Form, FormLabel} from 'react-bootstrap'

import API, {DataResponse} from 'api'

interface State {
  cleanFilesExpanded: boolean
  cleanFilesInProgress: boolean
  cleanFilesDryRun: boolean
  cleanFilesReturned: any
  validateAssetExpanded: boolean
  validateAssetInProgress: boolean
}

@observer
class ContentMaintenanceTab extends React.Component<{}, State> {
  constructor(props) {
    super(props)

    this.state = {
      cleanFilesExpanded: false,
      cleanFilesInProgress: false,
      cleanFilesDryRun: false,
      cleanFilesReturned: null,
      validateAssetExpanded: false,
      validateAssetInProgress: false,
    }
  }

  onDryRunChange = (e) => {
    this.setState({
      cleanFilesDryRun: e.target.checked,
    })
  }

  onClickClean = () => {
    this.setState({
      cleanFilesInProgress: true,
    })
    API.admin.misc.cleanFiles(this.state.cleanFilesDryRun).then((res) => {
      console.info('clean response:', res)
      this.setState({
        cleanFilesInProgress: false,
        cleanFilesReturned: res,
      })
    })
  }

  @computed
  get cleanFilesOutput() {
    const ret = this.state.cleanFilesReturned
    if (!ret || !ret.status) return 'No Results'
    if (ret.status !== 'success') return `Status: ${ret.status}`
    else
      return (
        <table>
          <tbody>
            <tr>
              <td>Status:</td>
              <td>{ret.status}</td>
            </tr>
            <tr>
              <td>Dry-Run:</td>
              <td>{ret.data['dry-run'] ? 'true' : 'false'}</td>
            </tr>
            <tr>
              <td>Directories:</td>
              <td>
                <pre>{ret.data.directories.join('\n')}</pre>
              </td>
            </tr>
          </tbody>
        </table>
      )
  }

  render() {
    return (
      <div id="content-maintenance" className="admin-inner-tab">
        <span className="description">
          Perform backend maintenance operations for content.
        </span>
        <Accordion>
          <Form.Group className="panel-label-fg">
            <Accordion.Toggle as={FormLabel} eventKey="0" className="panel-label">
              Clean Files&hellip;
            </Accordion.Toggle>
          </Form.Group>
          <Accordion.Collapse eventKey="0">
            <Card>
              <Form.Group controlId="content-maintenance-form-clean">
                <FormLabel>
                  Clean
                </FormLabel>
                <Form.Check
                  id="content-maintenance-form-dry-run"
                  onChange={this.onDryRunChange}
                  type="checkbox"
                  label="Dry Run"
                />
                <Button
                  id="content-maintenance-form-clean"
                  variant="success"
                  disabled={this.state.cleanFilesInProgress}
                  title={this.state.cleanFilesInProgress ? 'In Progress...' : null}
                  onClick={this.onClickClean}
                >
                  Clean Files
                </Button>
              </Form.Group>
              <Form.Group>
                <FormLabel>Output</FormLabel>
                <br />
                {this.cleanFilesOutput}
              </Form.Group>
            </Card>
          </Accordion.Collapse>
        </Accordion>
        <Accordion>
          <Form.Group className="panel-label-fg">
            <Accordion.Toggle as={FormLabel} eventKey="1" className="panel-label">
              Validate Assets&hellip;
            </Accordion.Toggle>
          </Form.Group>
          <Accordion.Collapse eventKey="1">  
            <Card>
              <Form.Group controlId="content-maintenance-form-validate">
                <FormLabel id="validate-form-label">
                  Validate
                </FormLabel>
                <Button
                  id="content-maintenance-form-validate"
                  variant="success"
                  disabled={true || this.state.validateAssetInProgress}
                  title="Disabled"
                >
                  Validate Assets
                </Button>
              </Form.Group>
              <Form.Group>
                <FormLabel>Output</FormLabel>
                <br />
                {this.cleanFilesOutput}
              </Form.Group>
            </Card>
          </Accordion.Collapse>
        </Accordion>
      </div>
    )
  }
}

export default ContentMaintenanceTab
