import {observable} from 'mobx'

import alertStore from './alertStore'

export type AlertStyle = 'success' | 'warning' | 'danger' | 'info'

const DEFAULT_ALERT_STYLE: AlertStyle = 'info'
const DEFAULT_TIMEOUT = 2

/**
 * An alert instance.
 * @property {string}      message  - The alert message
 * @property {AlertStyle}  bsStyle  - The style of the alert, defaults to 'info'
 * @property {string|null} title    - The title, defaults to null.
 * @property {number|null} timeout  - Display timeout in seconds. If null or 0,
 *                                    no timeout. Defaults to 10 seconds.
 */
export default class Alert {
  @observable readonly uid: number
  @observable message: string
  @observable bsStyle: AlertStyle
  @observable title: string | null
  @observable timeout: number | null

  constructor(
    uid: number,
    message: string,
    bsStyle?: AlertStyle,
    title?: string | null,
    timeout?: number | null
  ) {
    this.uid = uid
    this.message = message
    this.bsStyle = bsStyle || DEFAULT_ALERT_STYLE
    this.title = title || null
    if (timeout === undefined) this.timeout = DEFAULT_TIMEOUT
    else if (timeout && timeout > 0) this.timeout = timeout
    else this.timeout = null

    setTimeout(() => alertStore.clearAlert(this), 1000 * this.timeout)
  }
}
