import * as React from 'react'

const Close = require('images/elements/close-x.svg')

interface Props {
  type: string
  title: string
  customClass: string
  minimized: boolean
  closeClicked: () => void
}

const SideNavDisplayBox: React.StatelessComponent<Props> = props => {
  let minimized = props.minimized ? ' minimized' : ' maximized'
  if (props.minimized == null) {
    minimized = ''
  }

  return (
    <div className={'side-nav-display ' + props.customClass + minimized}>
      <div className="header">
        <h4 className="title">{props.title}</h4>
        <button className="close-btn" onClick={props.closeClicked}>
          <Close id="close-x" />
        </button>
      </div>
      <hr />
      <div className="overflow-wrapper">{props.children}</div>
    </div>
  )
}

export default SideNavDisplayBox
