import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {Badge} from 'react-bootstrap'

import {ScreenStore} from 'stores/screenStore'
import {UserStore} from 'stores/userStore'
import {BroadcastStore} from 'stores/broadcastStore'
import {AppStateStore} from 'stores/appStateStore'
import SingleScreen from './SingleScreen'
import SideNavMarquee from './SideNavMarquee'
import SideNavBroadcast from './SideNavBroadcast'
import SideNavStopBroadcast from './SideNavStopBroadcast'
import SideNavElement from 'modules/navs/SideNavElement'
import SideNav from '../navs/SideNav'

const Marquee = require('images/elements/marquee.svg')
const Broadcast = require('images/elements/broadcast.svg')
const StopBroadcastSVG = require('images/elements/stop-broadcast.svg')

interface Props {
  userStore?: UserStore
  screenStore?: ScreenStore
  broadcastStore?: BroadcastStore
  appStateStore?: AppStateStore
}

@inject('screenStore', 'userStore', 'broadcastStore', 'appStateStore')
@observer
class Screens extends React.Component<Props, any> {
  get userBroadcastPriv() {
    return this.props.userStore.currentUser
      ? this.props.userStore.currentUser.broadcast_priv
      : 0
  }

  render() {
    return (
      <div id="screens-page">
        <SideNav>
          {<li id="marquee">
            <SideNavElement
              customClass="marquee"
              svgIcon={<Marquee id="marquee-svg-icon" />}
              title="Marquee"
            >
              {<SideNavMarquee />}
            </SideNavElement>
          </li>
          }
          {this.userBroadcastPriv > 0 && (
            <li id="broadcast">
              <SideNavElement
                customClass="broadcast"
                svgIcon={<Broadcast id="broadcast-svg-icon" />}
                title="Broadcast"
              >
                <SideNavBroadcast />
              </SideNavElement>
            </li>
          )}
          {this.userBroadcastPriv > 0 &&
            this.props.broadcastStore.broadcastCount > 0 && (
              <li id="stop-broadcast">
                <SideNavElement
                  customClass="stop-broadcast"
                  svgIcon={<StopBroadcastSVG id="stop-broadcast-svg-icon" />}
                  title="Stop Broadcast"
                >
                  <SideNavStopBroadcast
                    broadcasts={this.props.broadcastStore.broadcasts}
                    disabled={false}
                  />
                </SideNavElement>
                <Badge className="broadcast-badge">
                  {this.props.broadcastStore.broadcastCount}
                </Badge>
              </li>
            )}
        </SideNav>
        <div className="screen-display">
          {this.props.screenStore.sharedScreens.map((screen, i) => {
            return <SingleScreen screen={screen} key={screen.id} />
          })}
          <div className="single-screen-container" />
          <div className="single-screen-container" />
          <div className="single-screen-container" />
          <div className="single-screen-container" />
          <div className="single-screen-container" />
          <div className="single-screen-container" />
          <div className="single-screen-container" />
          <div className="single-screen-container" />
        </div>
      </div>
    )
  }
}

export default Screens
