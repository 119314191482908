import * as React from 'react'
import {computed} from 'mobx'
import {observer} from 'mobx-react'
import {OverlayTrigger, Table, Tooltip} from 'react-bootstrap'

import {RealAsset} from 'api/realsources'
import {RealAssetStore, RealUserStore} from 'modules/admin/adminstores'
import AssetModal from './Modal'
import IndexRow from './IndexRow'
import Refresher from '../Refresher'

interface State {
  selectedAsset: RealAsset
  filterTerm: string
}

@observer
class ContentIndexTab extends React.Component<{}, State> {
  constructor(props) {
    super(props)

    this.state = {
      selectedAsset: null,
      filterTerm: '',
    }
  }

  @computed
  get filterRegex() {
    if (this.state.filterTerm) {
      return new RegExp(this.state.filterTerm, 'i')
    }
  }

  onClickAsset = (asset: RealAsset) => {
    this.setState({selectedAsset: asset})
  }

  onCloseModal = () => {
    this.setState({selectedAsset: null})
  }

  filterResults = (asset: RealAsset, index, array) => {
    if (!this.filterRegex) {
      return true
    }

    return (
      this.filterRegex.test(asset.name) ||
      this.filterRegex.test(asset.orientation) ||
      this.filterRegex.test(asset.description) ||
      this.filterRegex.test(asset.file_type)
    )
  }

  onChangeFilter = (event) => {
    this.setState({filterTerm: event.target.value})
  }

  @computed
  get rows() {
    // dependency validation: required for computed functionality.
    this.state.filterTerm // tslint:disable-line
    return Array.from(RealAssetStore.values)
      .filter((asset, index, array) => this.filterResults(asset, index, array))
      .map((asset: RealAsset, idx: number) => (
        <IndexRow
          asset={asset}
          key={asset.id}
          onClick={(clickedAsset) => this.onClickAsset(clickedAsset)}
        />
      ))
  }

  render() {
    const filterTooltip = (
      <Tooltip
        className="admin-filter-help-tooltip"
        id="admin-filter-help-content-tooltip"
      >
        Filter against name, orientation, description, or file-type.
      </Tooltip>
    )
    return (
      <div id="asset-indextab" className="admin-inner-tab">
        <span className="description">Update the admin store of assets.</span>
        <Refresher stores={[RealAssetStore, RealUserStore]} />
        <input
          ref="filter_input"
          id="asset-indextab-filter-input"
          type="search"
          placeholder="Filter..."
          aria-label="Filter Content"
          value={this.state.filterTerm}
          onChange={this.onChangeFilter}
        />
        <OverlayTrigger placement="left" overlay={filterTooltip}>
          <span className="admin-filter-help">?</span>
        </OverlayTrigger>

        <div className="tablescroller" data-rows={this.rows.length}>
          {/* condensed */}
          <Table bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Orient.</th>
                <th>Private</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>{this.rows}</tbody>
          </Table>
          <AssetModal
            asset={this.state.selectedAsset}
            onHide={this.onCloseModal}
            show={this.state.selectedAsset !== null}
          />
        </div>
      </div>
    )
  }
}

export default ContentIndexTab
