import * as React from 'react'
import Toggle from 'react-toggle'
import { default as AvatarEditorType, ImageState } from 'react-avatar-editor'
import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import {
  Alert,
  Button,
  Col,
  Form,
  FormControl,
  FormLabel,
  Popover,
  Row,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap'
import {debounce} from 'lodash'
import moment from 'moment'
import Dropzone from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'

import Asset from 'stores/Asset'
import { ContentStore } from 'stores/contentStore'
import { AlertStore } from 'stores/alertStore'
import { AppStateStore } from 'stores/appStateStore'
import { PlaylistStore } from 'stores/playlistStore'
import AssetPreviewModal from './modals/AssetPreview'
import DatePicker from 'modules/common/DatePicker'
import AssetFormVideoPreview, { FileObject } from './AssetFormVideoPreview'

const ImageIcon = require('images/elements/image-icon.svg')
const ClearIcon = require('images/elements/close-x.svg')
const LoadingIcon = require('images/elements/mb-logo.svg')

const Y2K = moment('2000/01/01', 'YYYY/MM/DD')

const ACCEPTED_IMAGES = ['image/jpeg', 'image/png', 'image/bmp'].join(', ')
const ACCEPTED_VIDEOS = ['video/mp4', 'video/x-matroska'].join(', ')
const ACCEPTED_TYPES = [ACCEPTED_IMAGES, ACCEPTED_VIDEOS].join(', ')

const YOUTUBE_SHORT_DOMAIN = /youtu\.be$/i
const YOUTUBE_NORMAL_DOMAINS = [/youtube\.com$/i, /youtube-nocookie\.com$/i]
// "good-enough" URL/URI regex.
const URL_RE = new RegExp(
  '^(https?://)?' + // protocol
  '([a-z0-9-]+\\.)+[a-z0-9-]+' + // domain
  '(:[1-6]?\\d{2,4})?' + // port
  '(/.*)?$', // path
  'i'
)

// allow at most 1:1.2 on a 16:9 / 1.2:1 on a 9:16
const MINIMUM_IMAGE_RATIO = 0.45

interface Props {
  asset?: Asset
  onDelete?: () => void
  closeModal?: () => void
  closeSideNav?: () => void
  contentStore?: ContentStore
  playlistStore?: PlaylistStore
  alertStore?: AlertStore
  appStateStore?: AppStateStore
}

interface State {
  assetTitle: string
  customWebURL: string
  customWebURLData: string
  verticalUploadFile: FileObject
  verticalCropper: AvatarEditorType
  verticalZoom: number
  verticalRotate: number
  verticalFileURL: string
  horizontalUploadFile: FileObject
  horizontalCropper: AvatarEditorType
  horizontalZoom: number
  horizontalRotate: number
  horizontalFileURL: string
  lifeSpanBegin: string
  lifeSpanEnd: string
  isPrivate: boolean
  validStates: ValidStates
  showDelete: boolean
  waiting: boolean
  previewModal: boolean
  previewIndex: number
  minVertZoom: number
  minHoriZoom: number
}

interface ValidStates {
  assetTitle: boolean
  customWebURL: boolean
  verticalUploadFile: boolean
  verticalFileURL: boolean
  horizontalUploadFile: boolean
  horizontalFileURL: boolean
  lifeSpanBegin: boolean
  lifeSpanEnd: boolean
  lifeSpanEndAfterBegin: boolean
}

interface Position {
  x: number
  y: number
}

function IPT_to_type(ipt: string): 'VIDEO' | 'IMAGE' | 'WEB' {
  switch (ipt) {
    case IMAGE_PREVIEW_TYPE.CUSTOM_WEB_URL:
    case IMAGE_PREVIEW_TYPE.FILE_URL:
      return 'WEB'
    case IMAGE_PREVIEW_TYPE.IMAGE_UPLOAD:
      return 'IMAGE'
    case IMAGE_PREVIEW_TYPE.VIDEO_UPLOAD:
      return 'VIDEO'
    case IMAGE_PREVIEW_TYPE.EDIT_UPLOAD:
    case IMAGE_PREVIEW_TYPE.EMPTY:
    default:
      return null
  }
}

/* Return the Youtube ID from the URL, or undefined. */
function checkYoutubeURL(urlstr: string) {
  let url

  if (urlstr.indexOf('://') === -1) urlstr = `https://${urlstr}`

  try {
    url = new URL(urlstr)
  } catch (e) {
    return undefined
  }

  if (
    YOUTUBE_SHORT_DOMAIN.test(url.hostname) &&
    url.pathname.length >= 10 &&
    url.pathname.lastIndexOf('/') === 0
  ) {
    return url.pathname.substr(1)
  } else if (
    YOUTUBE_NORMAL_DOMAINS.some((dom) => dom.test(url.hostname)) &&
    url.pathname.startsWith('/watch') &&
    url.searchParams.has('v')
  ) {
    return url.searchParams.get('v')
  }

  return undefined
}

/* Get the Youtube thumbnail URL corresponding to the Youtube ID. */
function getYoutubeThumbnail(youtubeID: string) {
  return `https://img.youtube.com/vi/${youtubeID}/hqdefault.jpg`
}

const IMAGE_PREVIEW_TYPE = {
  CUSTOM_WEB_URL: 'web_url',
  FILE_URL: 'file_url',
  IMAGE_UPLOAD: 'image_upload',
  VIDEO_UPLOAD: 'video_upload',
  EDIT_UPLOAD: 'edit',
  EMPTY: 'empty',
}

@inject('contentStore', 'alertStore', 'appStateStore', 'playlistStore')
@observer
class AssetForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = this.makeInitialState()

    this.handleCustomWebURL = debounce(this.handleCustomWebURL.bind(this), 1000)
    this.handleVerticalFileURL = debounce(
      this.handleVerticalFileURL.bind(this),
      1000
    )
    this.handleHorizontalFileURL = debounce(
      this.handleHorizontalFileURL.bind(this),
      1000
    )
  }

  makeInitialState = () => {
    const asset = this.props.asset

    const defaults = {
      assetTitle: '',
      customWebURL: '',
      customWebURLData: '',
      verticalFileURL: '',
      horizontalFileURL: '',
      lifeSpanBegin: moment().format('MM/DD/YY'),
      lifeSpanEnd: moment().add(3, 'months').format('MM/DD/YY'),
      isPrivate: false,
    }

    if (asset) {
      defaults.assetTitle = asset.title
      defaults.customWebURL = asset.webURL || ''
      defaults.verticalFileURL = asset.verticalURL || ''
      defaults.horizontalFileURL = asset.horizontalURL || ''
      defaults.lifeSpanBegin = moment(asset.lifeSpanBegin).format('MM/DD/YY')
      defaults.lifeSpanEnd = moment(asset.lifeSpanEnd).format('MM/DD/YY')
      defaults.isPrivate = asset.isPrivate
      defaults.customWebURLData = asset.webURLData
    }

    const validity = asset ? true : null

    return {
      assetTitle: defaults.assetTitle,
      customWebURL: defaults.customWebURL,
      customWebURLData: defaults.customWebURLData,
      verticalUploadFile: null,
      verticalCropper: null,
      verticalZoom: 1.0,
      verticalRotate: 0,
      verticalFileURL: defaults.verticalFileURL,
      horizontalUploadFile: null,
      horizontalCropper: null,
      horizontalZoom: 1.0,
      horizontalRotate: 0,
      horizontalFileURL: defaults.horizontalFileURL,
      lifeSpanBegin: defaults.lifeSpanBegin,
      lifeSpanEnd: defaults.lifeSpanEnd,
      isPrivate: defaults.isPrivate,
      validStates: {
        assetTitle: validity,
        customWebURL: validity,
        verticalUploadFile: validity,
        verticalFileURL: validity,
        horizontalUploadFile: validity,
        horizontalFileURL: validity,
        lifeSpanBegin: true,
        lifeSpanEnd: true,
        lifeSpanEndAfterBegin: true,
      },
      showDelete: false,
      waiting: false,
      previewModal: false,
      previewIndex: 0,
      minVertZoom: 1,
      minHoriZoom: 1,
    }
  }

  closeModal = () => {
    this.setState({ previewModal: false })
  }

  handlePreviewSelect = (selectedIndex) => {
    this.setState({
      previewIndex: selectedIndex,
    })
  }

  @computed
  get isValid() {
    let validImage = false

    validImage = Boolean(
      this.state.customWebURL ||
        this.state.validStates.verticalUploadFile ||
        this.state.validStates.horizontalUploadFile ||
        this.props.asset ||
        this.state.validStates.verticalFileURL ||
        this.state.validStates.horizontalFileURL
    )

    return Boolean(
      this.state.validStates.assetTitle &&
      validImage &&
      this.state.validStates.lifeSpanBegin &&
      this.state.validStates.lifeSpanEnd &&
      this.state.validStates.lifeSpanEndAfterBegin
    )
  }

  @computed
  get overallPseudoType() {
    const vert =
      this.verticalImageType === IMAGE_PREVIEW_TYPE.EMPTY
        ? null
        : this.verticalImageType
    const hori =
      this.horizontalImageType === IMAGE_PREVIEW_TYPE.EMPTY
        ? null
        : this.horizontalImageType

    if (!(vert || hori)) {
      // both are unset
      return null
    } else if (vert === hori) {
      // they're the same
      return vert
    } else if (!(vert && hori)) {
      // one is unset
      return vert || hori
    } else {
      // they differ
      console.warn(
        `Uploaded asset types appear to differ: '${vert}' vs '${hori}'`
      )
      return null
    }
  }

  @computed
  get verticalImageType() {
    if (this.state.customWebURL) return IMAGE_PREVIEW_TYPE.CUSTOM_WEB_URL
    else if (this.state.verticalUploadFile) {
      // assumes verticalUploadFile's MIME is in ACCEPTED_TYPES
      if (ACCEPTED_IMAGES.includes(this.state.verticalUploadFile.type)) {
        return IMAGE_PREVIEW_TYPE.IMAGE_UPLOAD
      }

      return IMAGE_PREVIEW_TYPE.VIDEO_UPLOAD
    } else if (this.state.verticalFileURL) {
      return IMAGE_PREVIEW_TYPE.FILE_URL
    } else if (this.props.asset && this.props.asset.verticalFile) {
      return IMAGE_PREVIEW_TYPE.EDIT_UPLOAD
    }

    return IMAGE_PREVIEW_TYPE.EMPTY
  }

  @computed
  get horizontalImageType() {
    if (this.state.customWebURL) {
      return IMAGE_PREVIEW_TYPE.CUSTOM_WEB_URL
    } else if (this.state.horizontalUploadFile) {
      // assumes horizontalUploadFile's MIME is in ACCEPTED_TYPES
      if (ACCEPTED_IMAGES.includes(this.state.horizontalUploadFile.type)) {
        return IMAGE_PREVIEW_TYPE.IMAGE_UPLOAD
      }

      return IMAGE_PREVIEW_TYPE.VIDEO_UPLOAD
    } else if (this.state.horizontalFileURL) {
      return IMAGE_PREVIEW_TYPE.FILE_URL
    } else if (this.props.asset && this.props.asset.horizontalFile) {
      return IMAGE_PREVIEW_TYPE.EDIT_UPLOAD
    }

    return IMAGE_PREVIEW_TYPE.EMPTY
  }

  handleTitleChange = (event) => {
    const title = event.target.value

    this.setState({
      assetTitle: title,
      validStates: {
        ...this.state.validStates,
        assetTitle: title.length > 0,
      },
    })
  }

  onCustomURLChange = (event) => {
    if (this.state.customWebURL !== event.target.value) {
      this.setState({ customWebURL: '', customWebURLData: '' })
    }

    this.setState({
      customWebURL: event.target.value,
    })
  }

  validateCustomURL = (event) => {
    const customURL = event.target.value
    const isValid = URL_RE.test(customURL)

    this.setState({
      validStates: {
        ...this.state.validStates,
        customWebURL: customURL.trim() ? isValid : null,
      },
    })

    if (isValid) this.handleCustomWebURL()
  }

  handleCustomWebURL = () => {
    const customURL = this.state.customWebURL
    if (!customURL.trim()) {
      this.setState({
        validStates: {
          ...this.state.validStates,
          customWebURL: null,
        },
      })
      return
    }

    const ytid = checkYoutubeURL(customURL)

    if (ytid) {
      this.setState({
        customWebURLData: getYoutubeThumbnail(ytid),
      })
    } else {
      this.props.contentStore
        .previewWebURL(customURL)
        .then((data) => {
          this.setState({
            customWebURLData: data,
          })
        })
        .catch((err) => {
          console.error('Error fetching asset preview.', err)
          this.setState({
            customWebURLData: '',
            validStates: {
              ...this.state.validStates,
              customWebURL: false,
            },
          })
        })
    }
  }

  handleVerticalFileUpload = (files) =>
    this.setState({
      verticalUploadFile: files[0],
      validStates: {
        ...this.state.validStates,
        verticalUploadFile: true,
      },
    })

  clearVerticalFile = () =>
    this.setState({
      verticalUploadFile: null,
      verticalCropper: null,
      verticalZoom: 1.0,
      verticalRotate: 0,
      validStates: {
        ...this.state.validStates,
        verticalUploadFile: false,
      },
    })

  setVerticalCropperRef = (cropper: AvatarEditorType) =>
    this.setState({
      verticalCropper: cropper,
    })

  handleVerticalZoomChange = (event) =>
    this.setState({
      verticalZoom: event.target.valueAsNumber,
    })

  handleVerticalRotateChange = (event) =>
    this.setState({
      verticalRotate: (this.state.verticalRotate + 90) % 360,
    })

  vertOnload = () =>
    this.setState({
      validStates: {
        ...this.state.validStates,
        verticalFileURL: true,
      },
    })

  vertOnerror = () =>
    this.setState({
      validStates: {
        ...this.state.validStates,
        verticalFileURL: false,
      },
    })

  handleVerticalFileURLChange = (event) => {
    this.setState({
      verticalFileURL: event.target.value,
    })
  }

  validateVerticalFileURL = (event) => {
    const value = event.target.value
    const isValid = URL_RE.test(value)

    this.setState({
      validStates: {
        ...this.state.validStates,
        verticalFileURL: value.trim() ? isValid : null,
      },
    })

    if (isValid) this.handleVerticalFileURL()
  }

  handleVerticalFileURL = () => {
    const value = this.state.verticalFileURL

    if (value !== '') {
      const image = new Image()

      image.onload = this.vertOnload
      image.onerror = this.vertOnerror

      image.src = value
    }
  }

  handleHorizontalFileUpload = (files) =>
    this.setState({
      horizontalUploadFile: files[0],
      validStates: {
        ...this.state.validStates,
        horizontalUploadFile: true,
      },
    })

  clearHorizontalFile = () =>
    this.setState({
      horizontalUploadFile: null,
      horizontalCropper: null,
      horizontalZoom: 1.0,
      validStates: {
        ...this.state.validStates,
        horizontalUploadFile: false,
      },
    })

  setHorizontalCropperRef = (cropper: AvatarEditorType) =>
    this.setState({
      horizontalCropper: cropper,
    })

  handleHorizontalZoomChange = (event) =>
    this.setState({
      horizontalZoom: event.target.valueAsNumber,
    })

  handleHorizontalRotateChange = (event) =>
    this.setState({
      horizontalRotate: (this.state.horizontalRotate + 90) % 360,
    })

  horiOnload = () =>
    this.setState({
      validStates: {
        ...this.state.validStates,
        horizontalFileURL: true,
      },
    })

  horiOnerror = () =>
    this.setState({
      validStates: {
        ...this.state.validStates,
        horizontalFileURL: false,
      },
    })

  handleHorizontalFileURLChange = (event) => {
    this.setState({
      horizontalFileURL: event.target.value,
    })
  }

  validateHorizontalFileURL = (event) => {
    const value = event.target.value
    const isValid = URL_RE.test(value)

    this.setState({
      validStates: {
        ...this.state.validStates,
        horizontalFileURL: value.trim() ? isValid : null,
      },
    })

    if (isValid) this.handleHorizontalFileURL()
  }

  handleHorizontalFileURL = () => {
    const value = this.state.horizontalFileURL

    if (value !== '') {
      const image = new Image()

      image.onload = this.horiOnload
      image.onerror = this.horiOnerror

      image.src = value
    }
  }

  checkLifeSpanValidty = (begin, end, valid) => {
    const lse = moment(end, 'MM/DD/YY')
    if (lse < Y2K) {
      lse.add(100, 'years')
    }

    const lsb = moment(begin, 'MM/DD/YY')

    this.setState({
      validStates: {
        ...this.state.validStates,
        lifeSpanEndAfterBegin: valid ? lse.isAfter(lsb) : valid,
      },
    })
  }

  handleLifeSpanBeginChange = (state) => {
    const date = state.date
    const valid = state.valid

    this.setState({
      lifeSpanBegin: date,
      validStates: {
        ...this.state.validStates,
        lifeSpanBegin: valid,
      },
    })

    this.checkLifeSpanValidty(date, this.state.lifeSpanEnd, valid)
  }

  handleLifeSpanEndChange = (state) => {
    const date = state.date
    const valid = state.valid

    this.setState({
      lifeSpanEnd: date,
      validStates: {
        ...this.state.validStates,
        lifeSpanEnd: valid,
      },
    })

    this.checkLifeSpanValidty(this.state.lifeSpanBegin, date, valid)
  }

  handlePrivateChange = (event) =>
    this.setState({
      isPrivate: event.target.checked,
    })

  @computed
  get webURLDisabled() {
    return Boolean(
      this.state.verticalFileURL ||
      this.state.verticalCropper ||
      this.state.horizontalFileURL ||
      this.state.horizontalCropper ||
      this.props.asset
    )
  }

  @computed
  get imageURLDisabled() {
    return Boolean(
      this.state.verticalCropper ||
      this.state.horizontalCropper ||
      this.state.customWebURL ||
      this.props.asset
    )
  }

  @computed
  get dropzoneDisabled() {
    return Boolean(this.props.asset && this.props.asset.assetType !== 'IMAGE')
  }

  @computed
  get currentlyAcceptedTypes() {
    if (this.props.asset) {
      switch (this.props.asset.assetType) {
        case 'IMAGE':
          return ACCEPTED_IMAGES
        case 'VIDEO':
          return ACCEPTED_VIDEOS
        default:
          return 'none/none'
      }
    } else {
      return ACCEPTED_TYPES
    }
  }

  @computed
  get verticalPreview() {

    if (this.verticalImageType === IMAGE_PREVIEW_TYPE.CUSTOM_WEB_URL) {
      return this.state.customWebURL && this.state.customWebURLData ? (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="click-to-preview">
              Click to Preview
            </Tooltip>
          }
        >
          <div
            onClick={() => this.setState({ previewModal: true, previewIndex: 0 })}
            className={
              'web-url-preview vertical ' +
              (checkYoutubeURL(this.state.customWebURL) ? 'youtube' : null)
            }
            style={{
              backgroundImage: `url(${this.state.customWebURLData})`,
              cursor: 'pointer',
            }}
          />
        </OverlayTrigger>
      ) : (
          <div
            className={
              'web-url-preview loading vertical ' +
              (this.state.validStates.customWebURL === false ? 'error' : '')
            }
          >
            <LoadingIcon />
          </div>
        )
    } else if (this.verticalImageType === IMAGE_PREVIEW_TYPE.IMAGE_UPLOAD) {
      return (
        <div className="image-cropper vertical">
          <div className="clear-selection" onClick={this.clearVerticalFile}>
            <ClearIcon className="clear-icon" />
          </div>
          <div
            className="rotate-selection"
            onClick={this.handleVerticalRotateChange}
          >
            <span className="fa fa-redo"/>
          </div>
          <AvatarEditor
            ref={this.setVerticalCropperRef}
            image={this.state.verticalUploadFile}
            width={this.state.verticalRotate % 180 ? 176 : 99}
            height={this.state.verticalRotate % 180 ? 99 : 176}
            border={0}
            scale={this.state.verticalZoom}
            rotate={this.state.verticalRotate}
            onLoadSuccess={this.onVerticalAvatarLoad}
          />
          <div className="zoom-slider-grp">
            <span className="zoom-out-indicator">-</span>
            <input
              type="range"
              min={this.state.minVertZoom}
              max="3"
              step="0.1"
              value={this.state.verticalZoom}
              onChange={this.handleVerticalZoomChange}
            />
            <span className="zoom-in-indicator">+</span>
          </div>
        </div>
      )
    } else if (this.verticalImageType === IMAGE_PREVIEW_TYPE.VIDEO_UPLOAD) {
      return (
        <div className="file-url-div video-file-div vertical">
          <div className="clear-selection" onClick={this.clearVerticalFile}>
            <ClearIcon className="clear-icon" />
          </div>

          <AssetFormVideoPreview
            orientation="vertical"
            file={this.state.verticalUploadFile}
          />
        </div>
      )
    } else if (this.verticalImageType === IMAGE_PREVIEW_TYPE.EDIT_UPLOAD) {
      return (
        <div
          className="file-url-div vertical"
          style={
            !this.state.validStates.verticalFileURL
              ? {}
              : {
                  backgroundImage: 'url(' + this.props.asset.verticalFile + ')',
                }
          }
        />
      )
    } else if (
      this.verticalImageType === IMAGE_PREVIEW_TYPE.FILE_URL &&
      this.state.validStates.verticalFileURL !== false
    ) {

      return (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="click-to-preview">
              Click to Preview
            </Tooltip>
          }
        >
          <div
            onClick={() => this.setState({previewModal: true, previewIndex: 0})}
            className="file-url-div vertical"
            style={
              !this.state.validStates.verticalFileURL
                ? {}
                : {
                    backgroundColor: 'black',
                    backgroundImage: 'url(' + this.state.verticalFileURL + ')',
                    cursor: 'pointer',
                  }
            }
          />
        </OverlayTrigger>
      )
    } else if (
      this.verticalImageType === IMAGE_PREVIEW_TYPE.FILE_URL &&
      this.state.validStates.verticalFileURL === false
    ) {
      return (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="bad-url-tooltip">
              non-image url
            </Tooltip>
          }
        >
          <ImageIcon className="file-url-div vertical" />
        </OverlayTrigger>
      )
    } else {
      if (this.dropzoneDisabled)
        return (
          <div
            className="no-asset dropzone vertical"
          >
            No Vertical Asset
          </div>
        )
    }
  }

  @computed
  get horizontalPreview() {

    if (this.horizontalImageType === IMAGE_PREVIEW_TYPE.CUSTOM_WEB_URL) {
      return this.state.customWebURL && this.state.customWebURLData ? (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="click-to-preview">
              Click to Preview
            </Tooltip>
          }
        >
          <div
            onClick={() => this.setState({ previewModal: true, previewIndex: 1 })}
            className={
              'web-url-preview horizontal ' +
              (checkYoutubeURL(this.state.customWebURL) ? 'youtube' : null)
            }
            style={{
              backgroundImage: `url(${this.state.customWebURLData})`,
              cursor: 'pointer',
            }}
          />
        </OverlayTrigger>
      ) : (
          <div
            className={
              'web-url-preview loading horizontal ' +
              (this.state.validStates.customWebURL === false ? 'error' : '')
            }
          >
            <LoadingIcon />
          </div>
        )
    } else if (this.horizontalImageType === IMAGE_PREVIEW_TYPE.IMAGE_UPLOAD) {
      return (
        <div className="image-cropper">
          <div className="clear-selection" onClick={this.clearHorizontalFile}>
            <ClearIcon className="clear-icon" />
          </div>
          <div
            className="rotate-selection"
            onClick={this.handleHorizontalRotateChange}
          >
            <span className="fa fa-redo"/>
          </div>
          <AvatarEditor
            ref={this.setHorizontalCropperRef}
            image={this.state.horizontalUploadFile}
            width={this.state.horizontalRotate % 180 ? 99 : 176}
            height={this.state.horizontalRotate % 180 ? 176 : 99}
            border={0}
            scale={this.state.horizontalZoom}
            rotate={this.state.horizontalRotate}
            onLoadSuccess={this.onHorizontalAvatarLoad}
          />
          <div className="zoom-slider-grp">
            <span className="zoom-out-indicator">-</span>
            <input
              type="range"
              min={this.state.minHoriZoom}
              max="3"
              step="0.1"
              value={this.state.horizontalZoom}
              onChange={this.handleHorizontalZoomChange}
            />
            <span className="zoom-in-indicator">+</span>
          </div>
        </div>
      )
    } else if (this.horizontalImageType === IMAGE_PREVIEW_TYPE.VIDEO_UPLOAD) {
      return (
        <div className="file-url-div video-file-div horizontal">
          <div className="clear-selection" onClick={this.clearHorizontalFile}>
            <ClearIcon className="clear-icon" />
          </div>
          <AssetFormVideoPreview
            orientation="horizontal"
            file={this.state.horizontalUploadFile}
          />
        </div>
      )
    } else if (this.horizontalImageType === IMAGE_PREVIEW_TYPE.EDIT_UPLOAD) {
      return (
        <div
          className="file-url-div horizontal"
          style={
            !this.state.validStates.horizontalFileURL
              ? {}
              : {
                  backgroundImage:
                    'url(' + this.props.asset.horizontalFile + ')',
                }
          }
        />
      )
    } else if (
      this.horizontalImageType === IMAGE_PREVIEW_TYPE.FILE_URL &&
      this.state.validStates.horizontalFileURL !== false
    ) {
      return (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="click-to-preview">
              Click to Preview
            </Tooltip>
          }
        >
          <div
            onClick={() => this.setState({ previewModal: true, previewIndex: 1 })}
            className="file-url-div horizontal"
            style={
              !this.state.validStates.horizontalFileURL
                ? {}
                : {
                    backgroundImage:
                      'url(' + this.state.horizontalFileURL + ')',
                    cursor: 'pointer',
                  }
            }
          />
        </OverlayTrigger>
      )
    } else if (
      this.horizontalImageType === IMAGE_PREVIEW_TYPE.FILE_URL &&
      this.state.validStates.horizontalFileURL === false
    ) {
      return (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="bad-url-tooltip">
              non-image url
            </Tooltip>
          }
        >
          <ImageIcon className="file-url-div horizontal" />
          </OverlayTrigger>
      )
    } else {
      if (this.dropzoneDisabled)
        return (
          <div
            className="no-asset dropzone horizontal"
          >
            No Horizontal Asset
          </div>
        )
    }
  }

  onVerticalAvatarLoad = (imgInfo: ImageState) => {
    /*
      We want to scale the image to fit within the bounds but without cropping.
      This essentially means adding the minimum amount of transparent bars to
      the image until it fits snuggly in the bounds.
      Process:
        - Calculate ratios of width and height over their expected output
          values (1920x1080).
        - Divide the minimum ratio by the maximum ratio to get the fit-scale.
    */
    if (!imgInfo.width || !imgInfo.height) return 1
    const [min, max] = [imgInfo.width / 1080, imgInfo.height / 1920].sort()
    const zoom = min / max
    if (zoom < MINIMUM_IMAGE_RATIO)
      this.props.alertStore.addAlert(
        'Image is too horizontal and will be cropped.',
        'warning',
        'Asset Creation Warning',
        10
      )
    this.setState({ minVertZoom: Math.max(zoom, MINIMUM_IMAGE_RATIO) })
  }

  onHorizontalAvatarLoad = (imgInfo: ImageState) => {
    /* see onVerticalAvatarLoad() for description */
    if (!imgInfo.width || !imgInfo.height) return 1
    const [min, max] = [imgInfo.width / 1920, imgInfo.height / 1080].sort()
    const zoom = min / max
    if (zoom < MINIMUM_IMAGE_RATIO)
      this.props.alertStore.addAlert(
        'Image is too vertical and will be cropped.',
        'warning',
        'Asset Creation Warning',
        10
      )
    this.setState({ minHoriZoom: Math.max(zoom, MINIMUM_IMAGE_RATIO) })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    if (this.props.asset) {
      this.editAsset()
    } else {
      this.createAsset()
    }
  }

  createAsset = () => {
    if (this.isValid) {
      const lse = moment(this.state.lifeSpanEnd, 'MM/DD/YY')
      if (lse < Y2K) {
        lse.add(100, 'years')
      }

      const attrs: any = {
        title: this.state.assetTitle,
        assetType: IPT_to_type(this.overallPseudoType),
        lifeSpanBegin: moment(this.state.lifeSpanBegin, 'MM/DD/YY'),
        lifeSpanEnd: lse,
        isPrivate: this.state.isPrivate,
      }

      const files: any = {}

      if (this.state.customWebURL) {
        const ytid = checkYoutubeURL(this.state.customWebURL)
        if (ytid) {
          attrs.assetType = 'VIDEO'
          attrs.youtubeID = ytid
        } else {
          attrs.webURL = this.state.customWebURL
        }
      }

      if (this.state.verticalFileURL) {
        attrs.verticalURL = this.state.verticalFileURL
      } else if (this.state.verticalCropper) {
        files.verticalFile = this.state.verticalCropper.getImage()
      } else if (this.state.verticalUploadFile) {
        files.verticalFile = this.state.verticalUploadFile
      }

      if (this.state.horizontalFileURL) {
        attrs.horizontalURL = this.state.horizontalFileURL
      } else if (this.state.horizontalCropper) {
        files.horizontalFile = this.state.horizontalCropper.getImage()
      } else if (this.state.horizontalUploadFile) {
        files.horizontalFile = this.state.horizontalUploadFile
      }

      this.setState({ waiting: true })

      this.props.contentStore
        .create(attrs, files)
        .then((newAsset) => {
          this.reset()
          this.props.alertStore.addAlert(
            'Asset successfully created',
            'success',
            'Asset Creation'
          )
          this.props.appStateStore.closeSideNav()
        })
        .catch((err) => {
          this.setState({waiting: false})

          const validations = err.validations
          let errMessage = err.message

          if (validations) {
            if ('name' in validations) {
              errMessage = errMessage + validations.name
              this.setState({
                validStates: {
                  ...this.state.validStates,
                  assetTitle: false,
                },
              })
            }

            if ('expr_date_time' in validations) {
              errMessage = errMessage + validations.expr_date_time
              this.setState({
                validStates: {
                  ...this.state.validStates,
                  lifeSpanEnd: false,
                },
              })
            }
          }

          this.props.alertStore.addAlert(errMessage, 'danger', 'Asset Creation')
        })
    }
  }

  editAsset = () => {
    const attrs: any = {}
    const files: any = {}

    if (
      this.state.assetTitle &&
      this.state.assetTitle !== this.props.asset.title
    ) {
      attrs.title = this.state.assetTitle
    }

    if (this.state.customWebURL) {
      const ytid = checkYoutubeURL(this.state.customWebURL)
      // check that something's changed before adding it
      if (
        !(
          this.props.asset.youtubeID === ytid ||
          this.props.asset.webURL === this.state.customWebURL
        )
      ) {
        if (ytid) {
          attrs.assetType = 'VIDEO'
          attrs.youtubeID = ytid
        } else {
          attrs.webURL = this.state.customWebURL
        }
      }
    }

    if (
      this.state.verticalFileURL &&
      this.state.verticalFileURL !== this.props.asset.verticalURL
    ) {
      attrs.verticalURL = this.state.verticalFileURL
    } else if (this.state.verticalCropper) {
      files.verticalFile = this.state.verticalCropper.getImage()
    } else if (this.state.verticalUploadFile) {
      files.verticalFile = this.state.verticalUploadFile
    }

    if (
      this.state.horizontalFileURL &&
      this.state.horizontalFileURL !== this.props.asset.horizontalURL
    ) {
      attrs.horizontalURL = this.state.horizontalFileURL
    } else if (this.state.horizontalCropper) {
      files.horizontalFile = this.state.horizontalCropper.getImage()
    } else if (this.state.horizontalUploadFile) {
      files.horizontalFile = this.state.horizontalUploadFile
    }

    if (this.state.lifeSpanBegin) {
      const lsb = moment(this.state.lifeSpanBegin, 'MM/DD/YY')
      if (!lsb.isSame(this.props.asset.lifeSpanBegin, 'day'))
        attrs.lifeSpanBegin = lsb.format()
    }

    if (this.state.lifeSpanEnd) {
      const lse = moment(this.state.lifeSpanEnd, 'MM/DD/YY')
      if (lse < Y2K) {
        lse.add(100, 'years')
      }
      if (!lse.isSame(this.props.asset.lifeSpanEnd, 'day'))
        attrs.lifeSpanEnd = lse.format()
    }

    if (this.state.isPrivate !== this.props.asset.isPrivate) {
      attrs.isPrivate = this.state.isPrivate
    }

    const changedKeys = Object.keys(attrs).concat(Object.keys(files))
    console.info('Changed Keys', changedKeys)
    if (
      !changedKeys.length ||
      (changedKeys.length === 1 && 'id' in changedKeys)
    ) {
      this.props.alertStore.addAlert(
        'The form was not submitted because no changes were made',
        'warning',
        'Asset unchanged'
      )
      return
    } else {
      console.log('Changed:', changedKeys)
    }

    this.props.asset
      .edit(attrs, files)
      .then(() => {
        this.reset()
        this.props.alertStore.addAlert('Asset successfully updated', 'success')
        this.props.closeModal()
      })
      .catch(() => this.props.alertStore.addAlert('Error occurred', 'danger'))
  }

  // Make sure there are no lingering showtimes or playlist entries for deleted assets.
  assetCleanup = () => {

    // loop over playlists in the store
    this.props.playlistStore.playlists.forEach(playlist => {
      // If you find the deleted asset on a playlist, remove it from the playlist in question and remove all of it's showtimes.
      if (playlist.assets.find(asset => asset.id === this.props.asset.id))
        return (playlist.softRemoveAsset(this.props.asset), playlist.removeShowtimeFor(this.props.asset))
    })


    // Alert the user that the asset they deleted has been removed from playlists and showtimes.
    this.props.alertStore.addAlert('Asset removed from playlists', 'success')
  }

  deleteAsset = () => {

    this.props.asset.delete()
    this.props.onDelete()
    this.props.contentStore.remove(this.props.asset)
    this.reset()
    this.props.closeModal()

    this.props.alertStore.addAlert('Asset deleted', 'success')

    this.assetCleanup()

  }

  reset = () => {
    // TODO: properly implement
    this.setState(this.makeInitialState())
  }

  @computed
  get errorMessage() {
    let message = ''

    if (this.state.validStates.assetTitle === false) {
      message = 'An asset title is required.'
    }
    if (this.state.validStates.customWebURL === false) {
      message =
        'The custom web URL is invalid. Links to images should be placed in the file URL fields.'
    }
    if (this.state.validStates.horizontalFileURL === false) {
      message =
        'The horizontal file URL is invalid. Links to webpages should be placed in the custom web URL field.'
    }
    if (this.state.validStates.verticalFileURL === false) {
      message =
        'The vertical file URL is invalid. Links to webpages should be placed in the custom web URL field.'
    }

    return message
  }

  render() {
    const privateTooltip = (
      <Tooltip id="private-tooltip">
        If private is turned on, this asset will not be on the public content
        marketplace.
      </Tooltip>
    )

    return (
      <form className="asset-form" onSubmit={this.handleSubmit} noValidate>
        <Row>
          <Alert
            variant="warning"
            className={
              (this.errorMessage !== '' ? '' : 'hidden ') + 'new-asset-warning'
            }
          >
            {this.errorMessage}
          </Alert>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group
              controlId="asset-title"
              // validationState={
              //   this.state.validStates.assetTitle === false ? 'error' : null
              // }
            >
              <FormLabel>asset title</FormLabel>
              <FormControl
                type="text"
                placeholder={'my asset title'}
                value={this.state.assetTitle}
                onChange={this.handleTitleChange}
                isInvalid={this.state.validStates.assetTitle === false}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group
              controlId="web-url"
              // validationState={
              //   this.state.validStates.customWebURL === false ? 'error' : null
              // }
            >
              <FormLabel>custom web URL</FormLabel>
              <FormControl
                inputMode="url"
                pattern={URL_RE.source}
                placeholder={'https://example.com'}
                disabled={this.webURLDisabled}
                value={this.state.customWebURL}
                onChange={this.onCustomURLChange}
                onBlur={this.validateCustomURL}
                isInvalid={this.state.validStates.customWebURL === false}
              />
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Form.Group controlId="image-uploading">
          <Row>
            <Col sm={6} className="vertical">
              <FormLabel>vertical</FormLabel>
              <div
                className={`dropzone vertical ${
                  this.dropzoneDisabled || this.verticalImageType !== IMAGE_PREVIEW_TYPE.EMPTY
                    ? 'disabled' : ''
                }`}
              >
                <Dropzone 
                  onDrop={acceptedFiles => this.handleVerticalFileUpload(acceptedFiles)}
                  accept={this.currentlyAcceptedTypes}
                  multiple={false}
                  disabled={this.dropzoneDisabled}
                  noClick={this.dropzoneDisabled}
                  noDrag={this.dropzoneDisabled}
                >
                  {(params) => {
                    let text = 'Click to import or drop file here to upload'
                    let dynamicClassName = ''
                    if (params.isDragReject) {
                      text = 'Incorrect file type'
                      dynamicClassName = ' invalid'
                    } else if (params.isDragActive) {
                      text = 'Drop to import file'
                      dynamicClassName = ' valid'
                    }

                    return (
                      <div 
                        className={'file-input' + dynamicClassName}
                        {...params.getRootProps()}
                      >
                        <input {...params.getInputProps()}/>
                        <div className="placeholder-text">{text}</div>
                      </div>
                    )
                  }}
                </Dropzone>
              </div>
              {this.verticalPreview}
              <Form.Group
                controlId="file-url-vertical"
                // validationState={
                //   this.state.validStates.verticalFileURL === false
                //     ? 'error'
                //     : null
                // }
              >
                <FormLabel className="file-url-label">
                  vertical file URL
                </FormLabel>
                <FormControl
                  disabled={this.imageURLDisabled}
                  type="text"
                  inputMode="url"
                  placeholder={'http://example.com/vert.png'}
                  value={this.state.verticalFileURL || ''}
                  onChange={this.handleVerticalFileURLChange}
                  onBlur={this.validateVerticalFileURL}
                  isInvalid={this.state.validStates.verticalFileURL === false}
                />
              </Form.Group>
            </Col>
            <Col sm={6} className="horizontal">
              <FormLabel>horizontal</FormLabel>
              <div
                className={`dropzone horizontal ${
                  this.dropzoneDisabled || this.horizontalImageType !== IMAGE_PREVIEW_TYPE.EMPTY
                    ? 'disabled' : ''
                }`}
              >
                <Dropzone 
                  onDrop={acceptedFiles => this.handleHorizontalFileUpload(acceptedFiles)}
                  accept={this.currentlyAcceptedTypes}
                  multiple={false}
                  disabled={this.dropzoneDisabled}
                  noClick={this.dropzoneDisabled}
                  noDrag={this.dropzoneDisabled}
                >
                  {(params) => {
                    let text = 'Click to import or drop file here to upload'
                    let dynamicClassName = ''
                    if (params.isDragReject) {
                      text = 'Incorrect file type'
                      dynamicClassName = ' invalid'
                    } else if (params.isDragActive) {
                      text = 'Drop to import file'
                      dynamicClassName = ' valid'
                    }

                    return (
                      <div 
                        className={'file-input' + dynamicClassName}
                        {...params.getRootProps()}
                      >
                        <input {...params.getInputProps()}/>
                        <div className="placeholder-text">{text}</div>
                      </div>
                    )
                  }}
                </Dropzone>
              </div>
              {this.horizontalPreview}
              <Form.Group
                controlId="file-url-horizontal"
                // validationState={
                //   this.state.validStates.horizontalFileURL === false
                //     ? 'error'
                //     : null
                // }
              >
                <FormLabel className="file-url-label">
                  horizontal file URL
                </FormLabel>
                <FormControl
                  disabled={this.imageURLDisabled}
                  type="text"
                  inputMode="url"
                  placeholder={'http://example.com/horz.png'}
                  value={this.state.horizontalFileURL || ''}
                  onChange={this.handleHorizontalFileURLChange}
                  onBlur={this.validateHorizontalFileURL}
                  isInvalid={this.state.validStates.horizontalFileURL === false}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr />
        </Form.Group>
        <Form.Group controlId="life-span-and-private">
          <Row>
            <Col sm={6} className="life-span-group">
              <FormLabel>asset life span</FormLabel>
              <div className="life-span-inputs">
                <Form.Group controlId="life-span-begin">
                  <DatePicker
                    date={this.state.lifeSpanBegin}
                    onDateChange={(state) =>
                      this.handleLifeSpanBeginChange(state)
                    }
                    error={
                      !this.state.validStates.lifeSpanBegin ||
                      !this.state.validStates.lifeSpanEndAfterBegin
                    }
                  />
                </Form.Group>
                <div className="life-span-to-div">to</div>
                <Form.Group controlId="life-span-end">
                  <DatePicker
                    date={this.state.lifeSpanEnd}
                    onDateChange={(state) =>
                      this.handleLifeSpanEndChange(state)
                    }
                    error={
                      !this.state.validStates.lifeSpanEnd ||
                      !this.state.validStates.lifeSpanEndAfterBegin
                    }
                  />
                </Form.Group>
              </div>
            </Col>
            <Col sm={6}>
              <FormLabel>
                private
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="private-tooltip">
                      {privateTooltip}
                    </Tooltip>
                  }
                >
                  <span className="private-switch-help">?</span>
                </OverlayTrigger>
              </FormLabel>
              <Toggle
                checked={this.state.isPrivate}
                onChange={this.handlePrivateChange}
                icons={false}
              />
            </Col>
          </Row>
        </Form.Group>
        {!this.props.asset && (
          <Form.Group className="submit-button" controlId="submit-btn">
            <hr />
            <Button
              type="submit"
              id="submit-btn"
              style={{backgroundColor: 'white'}}
              disabled={!this.isValid || this.state.waiting}
              className={this.state.waiting ? 'loading' : ''}
            >
              Create Asset
            </Button>
            <Button variant="danger" type="reset" onClick={this.reset}>
              Reset
            </Button>
          </Form.Group>
        )}
        {this.props.asset && (
          <Form.Group className="edit-btns" controlId="submit-btn">
            <OverlayTrigger
              rootClose={true}
              placement="top"
              trigger="click"
              overlay={
                <Popover
                  id="popover-trigger-click-root-close"
                  className="delete"
                >
                  Are You Sure?
                  <div className="popover-container">
                    <button
                      className="cancel"
                      onClick={() =>
                        document.body.click()
                      }
                    >
                      CANCEL
                    </button>
                    <button className="deleteConfirm" onClick={this.deleteAsset}>
                      YES
                    </button>
                  </div>
                </Popover>
              }
            >
              <Button
                className="delete"
                onClick={() =>
                  this.setState({
                    showDelete: !this.state.showDelete,
                  })
                }
                id="delete"
              >
                Delete
              </Button>
            </OverlayTrigger>
            <Button
              className="save"
              type="submit"
              id="submit-btn"
              disabled={!this.isValid || this.state.waiting}
            >
              Save
            </Button>
            <Button variant="danger" type="reset" onClick={this.reset}>
              Reset
            </Button>
          </Form.Group>
        )}
        <FormControl.Feedback />

        {/* This appears to be useless code.  Added ownerName and expires just to stop conflictions with this code's duplicate in ContentCard.tsx  */}
        <AssetPreviewModal
          show={this.state.previewModal}
          close={this.closeModal}
          verticalURL={
            this.state.verticalFileURL || this.state.customWebURLData
          }
          horizontalURL={
            this.state.horizontalFileURL || this.state.customWebURLData
          }
          youtubeID={checkYoutubeURL(this.state.customWebURL)}
          title={this.state.assetTitle}
          assetType={this.state.customWebURLData ? 'WEB' : 'IMAGE'}
          previewIndex={this.state.previewIndex}
          handlePreviewSelect={this.handlePreviewSelect}

          ownerName={''}
          expires={''}
          privacy={false}
        />
      </form>
    )
  }
}

export default AssetForm
