import * as React from 'react'
import {computed} from 'mobx'
import {observer} from 'mobx-react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

import {SECPERHOUR, SECPERWEEK} from 'time'
import Showtime from 'stores/Showtime'

interface Props {
  editShowtime: () => void
  showtime: Showtime
  disabled: boolean
}

const weektimeToPercent = (weektime: number) => {
  return `${100 * weektime / SECPERWEEK}%`
}

@observer
class ScheduleDrawShowtime extends React.Component<Props> {
  @computed
  get wrappedChild() {
    if (!this.props.showtime.doesWrap) {
      return null
    }

    return (
      <OverlayTrigger placement="top" overlay={this.tooltip}>
        <div
          className="schedcon-draw-showtime schedcon-draw-showtime-wraparound"
          style={{
            width: weektimeToPercent(this.props.showtime.end_weektime)
          }}
          onClick={this.onClick}
          data-showtime-id={this.props.showtime.id}
          onMouseOver={e => e.stopPropagation()}
        />
      </OverlayTrigger>
    )
  }

  @computed
  get style() {
    const style = {
      left: weektimeToPercent(this.props.showtime.start_weektime),
      right: undefined,
      width: undefined
    }

    if (this.props.showtime.doesWrap) {
      style.right = 0
    } else {
      style.width = weektimeToPercent(this.props.showtime.duration * SECPERHOUR)
    }

    return style
  }

  @computed
  get tooltip() {
    return (
      <Tooltip id="schedcon-draw-showtime-tooltip">
        {`${this.props.showtime.start_pretty} - ${this.props.showtime
          .end_pretty}`}
        {!this.props.disabled && ' (click to edit)'}
      </Tooltip>
    )
  }

  onClick = event => {
    // stop click from propagating up to playlist wrapper
    event.stopPropagation()

    if (!this.props.disabled) {
      this.props.editShowtime()
    }
  }

  render() {
    return (
      <div className="schedcon-draw-showtime-wrapper">
        <OverlayTrigger placement="top" overlay={this.tooltip}>
          <div
            className="schedcon-draw-showtime"
            style={this.style}
            onClick={this.onClick}
            data-showtime-id={this.props.showtime.id}
            onMouseOver={e => e.stopPropagation()}
          />
        </OverlayTrigger>
        {this.wrappedChild}
      </div>
    )
  }
}

export default ScheduleDrawShowtime
