/* tslint:disable:variable-name */

import {computed, IObservableArray, observable} from 'mobx'

import {BroadcastInterface} from 'globals'
import API from 'api'
import {BroadcastResource} from 'api/resources'
import userStore from './userStore'
import screenStore from './screenStore'
import User from './User'
import Screen from './Screen'

export default class Broadcast implements BroadcastInterface {
  static fromResource(resource: BroadcastResource): Broadcast {
    return new Broadcast({
      id: resource.id.toString(),
      user_id: resource.user_id.toString(),
      override: resource.override,
      message_text: resource.message_text,
      screenIDs: resource.screenIDs.map(String)
    })
  }

  @observable id: string
  @observable user_id: string
  @observable override: 0 | 1
  @observable message_text: string
  @observable screenIDs: IObservableArray<string> = observable.array()

  @computed
  get user(): User {
    if (this.user_id) return userStore.findById(this.user_id)
    else
      throw new Error(`Broadcast#${this.id} has invalid user ${this.user_id}`)
  }

  @computed
  get screens(): Screen[] {
    return this.screenIDs.map(id => screenStore.findById(id)).filter(Boolean)
  }

  constructor(attrs: BroadcastInterface) {
    Object.assign(this, attrs)
  }

  asResource = (): BroadcastResource => {
    return {
      id: Number(this.id),
      user_id: Number(this.user_id),
      override: this.override,
      message_text: this.message_text,
      screenIDs: this.screenIDs.map(Number)
    }
  }

  addScreen = (screen: Screen): void => {
    API.broadcasts
      .addScreen(this.id, screen.id)
      .then(() => this.screenIDs.push(screen.id))
  }

  removeScreen = (screen: Screen): void => {
    API.broadcasts
      .removeScreen(this.id, screen.id)
      .then(() => this.screenIDs.remove(screen.id))
  }
}
