import * as React from 'react'
import { useAccordionToggle, Accordion } from 'react-bootstrap'
import { observer } from 'mobx-react'

export interface ComponentMap {
  component: JSX.Element,
  name: string
}

interface Props {
  components: ComponentMap[],
  id: string,
  className?: string
}

interface State {
  activeKey: string
}

const AccordionToggleButton = (props) => {
  const onClick = useAccordionToggle(props.eventKey, () => {})

  return (
    <button
      onClick={onClick}
      style={
        props.active ?
        {border: "1px solid #388835", backgroundColor: "#388835", color:"white"} :
        {border: "1px solid #388835", color: "#388835"}
      }
    >
      {props.children}
    </button>
  )
}

@observer
class BaseAdminTab extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      activeKey: "-1"
    }
  }
  
  render() {

    return (
      <Accordion
        className={this.props.className || "adminTab"}
        id={this.props.id}
        onSelect={(key) => this.setState({activeKey: key})}
      >
      {
        //Generate the buttons
        this.props.components.map( (component,ind) => {
          return (
            <AccordionToggleButton
              eventKey={ind.toString()}
              active={ind.toString() === this.state.activeKey}
            >
              {component.name}
            </AccordionToggleButton>
          )
        })
      }
      {
        //Generate the actual components in a card
        this.props.components.map( (component,ind) => {
          return (
            <Accordion.Collapse
              className={this.props.className + "Collapse"}
              eventKey={ind.toString()}
            >
              {component.component}
            </Accordion.Collapse>
          )
        })
      }
      </Accordion>
    )
  }
}

export default BaseAdminTab
