import * as React from 'react'

class SideNav extends React.PureComponent {
  componentDidMount() {
    window.addEventListener('scroll', this.updateStickyNav)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateStickyNav)
  }

  updateStickyNav = () => {
    const sidenav = document.getElementById('sidenav-wrapper')
    window.pageYOffset > 52 && window.innerWidth >= 768
      ? sidenav.classList.add('sticky')
      : sidenav.classList.remove('sticky')
  }
  render() {
    return (
      <div id="sidenav-wrapper" className="side-nav-col col-sm-2 col-2">
        <nav className="navbar">
          <ul className="nav">{this.props.children}</ul>
        </nav>
      </div>
    )
  }
}

export default SideNav
