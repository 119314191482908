import * as React from 'react'
import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Accordion, Button, Card } from 'react-bootstrap'

import { UserStore } from 'stores/userStore'
import AdminPanel from './Panel'

interface Props {
  userStore?: UserStore
}

interface State {
  showPanel: boolean
  panelFloat: 'left' | 'right'
}

@inject('userStore')
@observer
class AdminPanelOpener extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      showPanel: false,
      panelFloat: localStorage.adminPanelFloat || 'right',
    }
  }

  @computed
  get isAdmin() {
    return this.props.userStore.currentUser
      ? this.props.userStore.currentUser.isAdmin
      : null
  }

  changeFloatSide = () => {
    const panelFloat = this.state.panelFloat === 'right' ? 'left' : 'right'

    localStorage.adminPanelFloat = panelFloat

    this.setState({ panelFloat })
  }

  render() {
    return this.isAdmin ? (
      <div id="admin-panel-opener" data-float={this.state.panelFloat}>
          <Accordion className="admin-panel">
            <Accordion.Collapse eventKey="1">
              <AdminPanel show={this.state.showPanel} />
            </Accordion.Collapse>
            {localStorage.adminPanelFloat === 'right' ?
              <span onClick={this.changeFloatSide} className="fa fa-chevron-left admin-panel-float-button mt-2 adminPanelLeft"/>
              : null
            }
            <Accordion.Toggle as={Button} className="adminButton" eventKey="1">
              Admin
            </Accordion.Toggle>
            {localStorage.adminPanelFloat === 'left' ?
              <span onClick={this.changeFloatSide} className="fa fa-chevron-right admin-panel-float-button mt-2"/>
              : null
            }
          </Accordion>
      </div>
    ) : null
  }
}

export default AdminPanelOpener
