import * as React from 'react'
import {computed} from 'mobx'
import {observer} from 'mobx-react'
import {OverlayTrigger, Table, Tooltip} from 'react-bootstrap'

import {RealUser} from 'api/realsources'
import {RealInstitutionStore, RealUserStore} from 'modules/admin/adminstores'
import IndexRow from './IndexRow'
import UserModal from './Modal'
import Refresher from '../Refresher'

interface State {
  selectedUser: RealUser
  filterTerm: string
}

@observer
class UsersIndexTab extends React.Component<{}, State> {
  constructor(props) {
    super(props)

    this.state = {
      selectedUser: null,
      filterTerm: ''
    }
  }

  @computed
  get filterRegex() {
    if (this.state.filterTerm) {
      return new RegExp(this.state.filterTerm, 'i')
    }

    return null
  }

  filterResults = (user: RealUser, index, array) => {
    if (!this.filterRegex) {
      return true
    }

    return this.filterRegex.test(user.name) || this.filterRegex.test(user.email)
  }

  @computed
  get rows() {
    // dependency validation: required for computed functionality.
    this.state.filterTerm // tslint:disable-line

    return Array.from(RealUserStore.values)
      .filter((user, index, array) => this.filterResults(user, index, array))
      .map((user: RealUser, idx: number) => (
        <IndexRow
          user={user}
          key={user.id}
          onClick={() => this.setState({selectedUser: user})}
        />
      ))
  }

  render() {
    const filterTooltip = (
      <Tooltip
        className="admin-filter-help-tooltip"
        id="admin-filter-help-users-tooltip"
      >
        Filter against name or email.
      </Tooltip>
    )

    return (
      <div id="user-indextab" className="admin-inner-tab">
        <span className="description">Update the admin store of users.</span>
        <Refresher stores={[RealUserStore, RealInstitutionStore]} />
        <input
          id="user-indextab-filter-input"
          type="search"
          placeholder="Filter..."
          aria-label="Filter Users"
          value={this.state.filterTerm}
          onChange={event => this.setState({filterTerm: event.target.value})}
        />
        <OverlayTrigger placement="left" overlay={filterTooltip}>
          <span className="admin-filter-help">?</span>
        </OverlayTrigger>
        <div className="tablescroller" data-rows={this.rows.length}>
          {/* <Table bordered condensed hover> 
                This changed the padding on all of the th elements inside it from 8 to 5*/}
          <Table bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Status</th>
                <th>Instit.</th>
              </tr>
            </thead>
            <tbody>{this.rows}</tbody>
          </Table>
        </div>
        <UserModal
          user={this.state.selectedUser}
          onHide={() => this.setState({selectedUser: null})}
        />
      </div>
    )
  }
}

export default UsersIndexTab
