import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { computed } from 'mobx'
import { Tooltip } from 'react-bootstrap'

import { PlaylistStore } from 'stores/playlistStore'
import { AlertStore } from 'stores/alertStore'
import Playlist from 'stores/Playlist'
import PlaylistRow from './PlaylistRowElement'
import PlaylistCard from './PlaylistCardElement'


interface Props {
  playlist: Playlist
  displayType: 'grid' | 'row'
  assetNumber: number
  toggleShare: (playlist: Playlist) => void
  showingShare: boolean
  playlistStore?: PlaylistStore
  alertStore?: AlertStore
}

interface State {
  showDelete: boolean
}

@inject('playlistStore', 'alertStore')
@observer
class PlaylistElement extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      showDelete: false
    }
  }

  @computed
  get permission() {
    return this.props.playlist.userPermissions.myPermission
  }

  @computed
  get isOwner() {
    return this.permission === 'creator'
  }

  @computed
  get canEdit() {
    return (this.permission === 'creator' || this.permission === 'editor')
  }

  toggleDelete = () => {
    this.setState({
      showDelete: !this.state.showDelete
    })
  }

  deleteConfirmedClicked = () => {
    this.setState({ showDelete: false })
    this.props.playlistStore
      .delete(this.props.playlist)
      .then(() =>
        this.props.alertStore.addAlert(
          `Successfully deleted playlist '${this.props.playlist.title}'`,
          'success'
        )
      )
      .catch(err => {
        this.props.alertStore.addAlert(
          `An error occurred while deleting playlist: ${err.message}`,
          'danger'
        )
      })
  }

  @computed
  get previewAssets() {
    const prevAssts = this.props.playlist.previewAssets
    const L = prevAssts.length
    const ret: JSX.Element[] = []
    for (let i = 0; i < 4; i++) {
      ret.push(
        i < L ? (
          <div
            key={i + 1}
            className={`image-${i + 1}`}
            style={{ backgroundImage: `url('${prevAssts[i].tpath}')` }}
          />
        ) : (
            <div key={i + 1} className={`image-${i + 1}`} />
          )
      )
    }
    return ret
  }

  toggleShare = () => this.props.toggleShare(this.props.playlist)

  render() {
    const numAssetsString = `${this.props.assetNumber} Asset${
      this.props.assetNumber === 1 ? '' : 's'
      }`

    const sharedWith = this.props.playlist.sharedWith.filter(user => {
      return (user.id !== this.props.playlist.ownerID)
    })

    let sharedNames =
      sharedWith.length !== 0 ? 'Shared with ' : 'Shared with no one'

    for (let index = 0; index < sharedWith.length; index++) {
      sharedNames += sharedWith[index].name + ', '
      if (index === 2 && sharedWith.length > 3) {
        sharedNames += 'and ' + (sharedWith.length - 3) + ' more'
        break
      } else if (index === sharedWith.length - 2) {
        sharedNames += 'and '
      } else if (index === sharedWith.length - 1) {
        sharedNames = sharedNames.slice(0, -2)
        break
      }
    }

    const tooltip = (
      <Tooltip
        id="tooltip-bottom"
        className={this.props.displayType === 'grid' ? 'grid' : ''}
      >
        {sharedNames}
      </Tooltip>
    )

    return this.props.displayType === 'grid' ? (
      <PlaylistCard
        playlist={this.props.playlist}
        previewAssets={this.previewAssets}
        tooltip={tooltip}
        tooltipContainer={this}
        numAssetsText={numAssetsString}
        canEdit={this.canEdit}
        showingShare={this.props.showingShare}
        toggleShare={this.toggleShare}
        isOwner={this.isOwner}
        showDelete={this.state.showDelete}
        toggleDelete={this.toggleDelete}
        deleteConfirmedClicked={this.deleteConfirmedClicked}
        key={this.props.playlist.id}
      />
    ) : (
        <PlaylistRow
          playlist={this.props.playlist}
          tooltip={tooltip}
          numAssetsText={numAssetsString}
          canEdit={this.canEdit}
          toggleShare={this.toggleShare}
          isOwner={this.isOwner}
          showDelete={this.state.showDelete}
          toggleDelete={this.toggleDelete}
          deleteConfirmedClicked={this.deleteConfirmedClicked}
          key={this.props.playlist.id}
        />
      )
  }
}

export default PlaylistElement
