import * as React from 'react'
import {computed} from 'mobx'
import {inject, observer} from 'mobx-react'
import {Button, Form, FormControl, FormLabel, Modal} from 'react-bootstrap'

import {nameToFnameLname, ValidationState} from 'globals'
import {UserResource_Partial} from 'api/resources'
import {AlertStore} from 'stores/alertStore'
import {UserStore} from 'stores/userStore'
import User from 'stores/User'

/* Editable Attributes:
username
fname
lname
*/
interface Props {
  closeModal: () => void
  show: boolean
  user: User
  userStore?: UserStore
  alertStore?: AlertStore
}

interface State {
  user: User
  username: string
  name: string
  displayMessage: string | null
  previewReloadTimeout: any
  fields: {
    username: string
    name: string
  }
  validStates: {
    username: ValidationState
    name: ValidationState
  }
  validStrings: {
    username: string
    name: string
  }
}

const BAD_USERNAME_CHARS = /[^a-z0-9]/g
const BAD_NAME_CHARS = /["\\]/g
const BAD_SPACE_CHARS = /\s{2,}|[\b\t\n\v\f\r\x85\xA0\u1680\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u2028\u2029\u202F\u205F\u3000]/g

function usernameProblem(username: string) {
  if (!username) {
    return 'Empty username'
  } else if (BAD_USERNAME_CHARS.test(username)) {
    return 'Invalid username character'
  } else if (username.length < 4 || 16 < username.length) {
    return 'Username should be 4-16 characters.'
  }
}

function nameProblem(name: string) {
  if (!name || name.length < 3) {
    return 'Name too short'
  } else if (BAD_NAME_CHARS.test(name)) {
    return `Invalid name character`
  } else if (BAD_SPACE_CHARS.test(name)) {
    return `Invalid space character in name`
  }
}

@inject('userStore', 'alertStore')
@observer
class ProfileEditModal extends React.Component<Props, State> {
  componentDidMount() {
    this.reset()
  }

  // UPDATE-TODO:
  // Move code with side effects to componentDidMount, and set initial state in the constructor.
  // UNSAFE_componentWillReceiveProps(nextProps: Props) {
    componentDidUpdate(nextProps: Props) {
    if (nextProps.user && this.state.user !== nextProps.user) this.reset()
  }

  reset = () => {
    const user =
      this.props.userStore.currentUser || this.props.userStore.fakeUser

    this.setState({
      user,
      username: user.username,
      name: user.name,
      displayMessage: null,
      previewReloadTimeout: null,
      fields: {
        username: null,
        name: null,
      },
      validStates: {
        username: null,
        name: null,
      },
      validStrings: {
        username: null,
        name: null,
      },
    })
  }

  /* validate the user input.
        Returns:
          (false) if the input is INVALID
          (null) there is no change
          (UserResource_Partial) the partial created by the input
    */
  validate(): false | null | UserResource_Partial {
    const user = {id: Number(this.state.user.id)} as UserResource_Partial
    let anyChange = false

    if (this.state.validStates.username === 'success') {
      user.username = this.state.fields.username
      anyChange = true
    } else if (this.state.validStates.username !== null) {
      return false
    }

    if (this.state.validStates.name === 'success') {
      // tslint:disable-next-line
      ;[user.fname, user.lname] = nameToFnameLname(this.state.fields.name)
      anyChange = true
    } else if (this.state.validStates.name !== null) {
      return false
    }

    return anyChange ? user : null
  }

  @computed
  get canSubmit() {
    let invalid = false
    if (!this.state) {
      return invalid
    }

    for (const key in this.state.validStates) {
      if (this.state.validStates.hasOwnProperty(key)) {
        const validString = this.state.validStates[key]
        if (validString === 'error') {
          return false
        } else if (validString === 'success') {
          invalid = true
        }
      }
    }

    return invalid
  }

  submit = (event) => {
    event.preventDefault()

    const result = this.validate()

    if (result === false) {
      this.setState({displayMessage: 'Invalid input'})
    } else if (result === null) {
      this.setState({displayMessage: 'No change'})
    } else if (result) {
      this.props.userStore
        .search(result.username)
        .then((users: User[]) => {
          for (const taken of users) {
            if (result.username === taken.username) {
              throw new Error('Username already taken')
            }
          }
          return this.props.userStore.update(result)
        })
        .then((user: User) => {
          this.reset()
          this.props.closeModal()
          this.props.alertStore.addAlert('Profile updated!', 'success')
        })
        .catch((err) => {
          this.props.alertStore.addAlert(err, 'danger', err.message)
          console.error(err)
        })
    }
  }

  onUsernameChange = (event) => {
    const value = event.target.value.toLowerCase()
    const problem = usernameProblem(value)

    let newValidState = null

    if (problem) {
      newValidState = 'error'
    } else if (value !== this.state.user.username) {
      newValidState = 'success'
    }

    this.setState({
      displayMessage: problem,
      fields: {
        ...this.state.fields,
        username: value,
      },
      validStates: {
        ...this.state.validStates,
        username: newValidState,
      },
    })
  }

  onNameChange = (event) => {
    const value = event.target.value.trim()
    const problem = nameProblem(value)

    let newValidState = null

    if (problem) {
      newValidState = 'error'
    } else if (value !== this.state.user.name) {
      newValidState = 'success'
    }

    this.setState({
      displayMessage: problem,
      fields: {
        ...this.state.fields,
        name: value,
      },
      validStates: {
        ...this.state.validStates,
        name: newValidState,
      },
    })
  }

  render() {
    return (
      this.state && (
        <Modal show={this.props.show} onHide={this.props.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Your Profile</Modal.Title>
          </Modal.Header>
          <form onSubmit={this.submit} noValidate>
            <Modal.Body>
              <Form.Group
                controlId="userform-username"
                // validationState={this.state.validStates.username}
              >
                <FormLabel>Username</FormLabel>
                <FormControl
                  type="text"
                  defaultValue={
                    this.state.fields.username !== null
                      ? this.state.fields.username
                      : this.state.username
                  }
                  onChange={this.onUsernameChange}
                  title={usernameProblem(this.state.fields.username)}
                  isValid={this.state.validStates.username == "success"}
                  isInvalid={this.state.validStates.username != "success"}
                />
                <FormControl.Feedback />
              </Form.Group>
              <Form.Group
                controlId="userform-name"
                // validationState={this.state.validStates.name}
              >
                <FormLabel>Name</FormLabel>
                <FormControl
                  type="text"
                  defaultValue={
                    this.state.fields.name !== null
                      ? this.state.fields.name
                      : this.state.name
                  }
                  onChange={this.onNameChange}
                  title={nameProblem(this.state.fields.name)}
                  isValid={this.state.validStates.name == "success"}
                  isInvalid={this.state.validStates.name != "success"}
                />
                <FormControl.Feedback />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <div className="profile-edit-modal-message">
                {this.state.displayMessage}
              </div>
              <Button type="reset" onClick={this.reset}>
                Reset
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!this.canSubmit}
              >
                Submit
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      )
    )
  }
}

export default ProfileEditModal
