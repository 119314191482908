import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {
  Button,
  Col,
  Form,
  FormControl,
  FormLabel,
  Modal,
} from 'react-bootstrap'

import {DOWS_2, seconds_tohhmmss, TimeInput} from 'time'
import {UserStore} from 'stores/userStore'
import {PlaylistStore} from 'stores/playlistStore'
import Showtime from 'stores/Showtime'
import DatePicker from 'react-datepicker';

interface Props {
  show: boolean
  closeModal: () => void
  editingShowtime: Showtime
  deleteShowtime: () => void
  userStore?: UserStore
  playlistStore? : PlaylistStore
}

interface State {
  selectedDate: Date
}

@observer
class ShowtimeEditModal extends React.Component<Props, State> {
  constructor(props)
  {
    super(props);
    this.state = {selectedDate: new Date()};
  }

  get dateSelected(): Date{
    // If selectedDate has not been set before, then set it to the current showtime's scheduled dates/times
    if(this.props.editingShowtime)
    {
      var currDate = new Date()
      // Set the Hour, Minutes, and Day based off of the editingShowtime info (the saved schedule)
      this.state.selectedDate.setHours(parseInt(this.props.editingShowtime.time_start.substring(0,2)));
      this.state.selectedDate.setMinutes(parseInt(this.props.editingShowtime.time_start.substring(3,5)));
      // modify day based on difference between saved day of the week and current day of the week (no way to just set it to a day of the week, must use date)
      var dateShift = this.props.editingShowtime.day_start - this.state.selectedDate.getDay()
      this.state.selectedDate.setDate(this.state.selectedDate.getDate() + dateShift)

      // All this (seconds, year,  and month) should stay constant with the current time (try to remove in future unless we change to allowing future scheduling instead of weekly)
      this.state.selectedDate.setSeconds(0);
      this.state.selectedDate.setFullYear(currDate.getFullYear())
      this.state.selectedDate.setMonth(currDate.getMonth())

    }
    return this.state.selectedDate
  }

  onSubmit = (event) => {
    event.preventDefault()

    this.props.closeModal()
  }

  onDurationChange = (event) => {
    let value = event.target.value

    if (!value) {
      return null
    }

    value = Math.floor(Number(value))

    if (0 < value && value <= 168) {
      // TODO: make this be a store function call
      this.props.editingShowtime.duration = value
    }
  }

  // Function to handle a selection change in the date/time picker
  onDatePickerChange = date => {
    this.setState({selectedDate: date});
    this.props.editingShowtime.day_start = date.getDay()
    // Slice function allows for insurance that there are 2 characters, since getHours and getMinutes return 1 char if < 10
    this.props.editingShowtime.time_start = `${("0"+date.getHours()).slice(-2)}:${("0"+date.getMinutes()).slice(-2)}:00`
  }

  render() {
    const datePickerStyle = {
      fontSize: '1rem'
    }
    return (
      <Modal
        className="showtime-edit-modal"
        show={this.props.show}
        onHide={this.props.closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Show Time</Modal.Title>
        </Modal.Header>
        {/* removed horizontal from Form */}
        <Form onSubmit={this.onSubmit}>
          <Modal.Body className="edit-showtime-body">
            {/* Date Picker and Time picker for selecting the starting day and time*/}
            <Form.Group className="datepicker-form">
              <Col as={FormLabel} sm={3}>
                Starting Day and Time
              </Col>
              <Col sm={8}>
                <div className="sem-datepicker">
                  <DatePicker
                    onChange={this.onDatePickerChange}
                    selected={this.dateSelected}
                    inline
                    showTimeSelect
                  />
                  

                </div>
              </Col>
            </Form.Group>

            <Form.Group className="duration-form">
              <Col id="duration-col" as={FormLabel} sm={3}>
                Duration (hours)
              </Col>
              <Col sm={7}>
                <FormControl
                  className="sem-duration"
                  type="number"
                  min={1}
                  max={168}
                  onChange={this.onDurationChange}
                  defaultValue={
                    this.props.editingShowtime &&
                    String(this.props.editingShowtime.duration)
                  }
                />
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.deleteShowtime} variant="danger">
              DELETE
            </Button>
            <Button type="submit" variant="primary">
              Done
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default ShowtimeEditModal
