import * as React from 'react'
import {inject, observer} from 'mobx-react'

import {RealUser} from 'api/realsources'
import {AlertStore} from 'stores/alertStore'
import {RealUserStore} from 'modules/admin/adminstores'
import UserForm from './form'

interface Props {
  alertStore?: AlertStore
}

const UsersCreateTab: React.StatelessComponent<Props> = inject('alertStore')(
  observer(props => {
    const onSubmit = (
      userSubmitted: RealUser,
      cb: (success: boolean) => void
    ) => {
      RealUserStore.create(userSubmitted)
        .then(user => {
          props.alertStore.addAlert(
            'Successfully created user.',
            'success',
            'User Admin Creation'
          )
          cb(true)
        })
        .catch(err => {
          props.alertStore.addAlert(err, 'danger', 'User Admin Creation, e1')
          cb(false)
        })
    }

    return (
      <div id="user-create" className="admin-inner-tab">
        <span className="description">
          User this form to create a new user.
        </span>
        <UserForm
          formType="create"
          onSubmit={(user, callback) => onSubmit(user, callback)}
          id="user-createform"
        />
      </div>
    )
  })
)

export default UsersCreateTab
