import * as React from 'react'
import {computed, IObservableArray, observable, ObservableMap} from 'mobx'
import {inject, observer} from 'mobx-react'
import {Button} from 'react-bootstrap'

import Screen from 'stores/Screen'
import Playlist from 'stores/Playlist'
import showtimeStore, {ShowtimeStore} from 'stores/showtimeStore'
import {UserStore} from 'stores/userStore'
import {PlaylistStore} from 'stores/playlistStore'
import Showtime from 'stores/Showtime'
import PlaylistSelect from './PlaylistSelect'
import PlaylistAddButton from './AddButton'
import DrawContainer from './DrawContainer'
import ShowtimeEditModal from './ShowtimeModal'

interface Props {
  screen: Screen
  disabled: boolean
  showtimeStore?: ShowtimeStore
  playlistStore?: PlaylistStore
  userStore?: UserStore
}

interface State {
  playlists: IObservableArray<Playlist>
  showtimes: PlaylistShowtimeMap
  showEditModal: boolean
  editingShowtime: Showtime | null
  isLoading: boolean
  statusMessage: string
}

interface PseudoPlaylist {
  id: string
  title: string
  promoted: boolean
}

const PLAYLIST_NONE: PseudoPlaylist = {id: 'none', title: '<none>', promoted: false}

type PlaylistShowtimeMap = ObservableMap<string, ObservableMap<string, Showtime>>

@inject('userStore', 'showtimeStore', 'playlistStore')
@observer
class ScheduleControl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      playlists: observable.array(props.screen.playlists.slice()),
      showtimes: props.screen.showtimes(),
      showEditModal: false,
      editingShowtime: null,
      isLoading: false,
      statusMessage: null,
    }
  }

  @computed
  get playlistOptions() {
    // filters out playlists that are already selected
    return [PLAYLIST_NONE].concat(
      this.props.playlistStore.myAndSharedPlaylists.filter((pl) => {
        return !this.state.showtimes.has(pl.id)
      })
    )
  }

  @computed
  get isAdmin() {
    return this.props.userStore.currentUser
      ? this.props.userStore.currentUser.isAdmin
      : null
  }

  isEditablePromoted = (st: Showtime) => {
    if(this.isAdmin)
    {
      return true
    }
    else
    {
      return (st && !st.playlist.promoted)
    }
  }

  deleteShowtime = () => {
    const st = this.state.editingShowtime
    const plid = st.playlistID
    if (this.state.showtimes.has(plid)) {
      const plmap = this.state.showtimes.get(plid)
      if (plmap.has(st.id)) {
        plmap.delete(st.id)
        this.closeEditModal()
      } else console.error(`Failed to delete show time ${st.id}; not in map`)
    } else
      console.error(`Failed to delete show time ${st.id}; playlist not in map`)
  }

  editShowtime = (st: Showtime) => {
    this.setState({
      showEditModal: this.isEditablePromoted(st),
      editingShowtime: st
    })
    // If the playlist is not reschedulable by this user, a dialog box appears
    //   to explain why.
    if(!this.isEditablePromoted(st))
    {
      const message = 'Editing the schedule of a promoted playlist is prohibited'
      if (!window.confirm(message)) {
        return
      }
    }
  }

  closeEditModal = () =>
    this.setState({
      showEditModal: false,
      editingShowtime: null,
    })

  apiUpdate = (st: Showtime) => {
    const stR = showtimeStore.diff(st)
    return showtimeStore.update(stR, st)
  }

  onSubmit = async (event) => {
    event.preventDefault()

    this.setState({
      isLoading: true,
      statusMessage: 'Submitting...',
    })

    const {create, update, remove} = this.props.screen.diffPlaylistShowtimeMap(
      this.state.showtimes
    )

    const statuses = []

    statuses.push.apply(statuses, remove.map(showtimeStore.delete))
    statuses.push.apply(statuses, create.map(showtimeStore.create))
    statuses.push.apply(statuses, update.map(this.apiUpdate))

    try {
      const resolved = await Promise.all(statuses)
      if (resolved) {
        this.props.screen.refresh()
        this.setState({
          isLoading: false,
          statusMessage: 'Success!',
        })
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        statusMessage: err.message,
      })
      console.error('Error submitting:', err)
    }
  }

  render() {
    return (
      <div className="screen-expanded-control schedule-control">
        <h5 className="sec-title">SCHEDULING</h5>
        <form onSubmit={this.onSubmit}>
          <div className="schedcon-playlist-container">
            <PlaylistSelect
              selectKey={0}
              playlistOptions={this.playlistOptions}
              playlists={this.state.playlists}
              showtimes={this.state.showtimes}
              screenID={this.props.screen.id}
              disabled={this.props.disabled || this.state.isLoading}
              currUser={this.props.userStore.currentUser}
            />
            <PlaylistSelect
              selectKey={1}
              playlistOptions={this.playlistOptions}
              playlists={this.state.playlists}
              showtimes={this.state.showtimes}
              screenID={this.props.screen.id}
              disabled={this.props.disabled || this.state.isLoading}
              currUser={this.props.userStore.currentUser}
            />
            <PlaylistSelect
              selectKey={2}
              playlistOptions={this.playlistOptions}
              playlists={this.state.playlists}
              showtimes={this.state.showtimes}
              screenID={this.props.screen.id}
              disabled={this.props.disabled || this.state.isLoading}
              currUser={this.props.userStore.currentUser}
            />
            <PlaylistAddButton
              playlists={this.state.playlists}
              disabled={this.props.disabled || this.state.isLoading}
            />
          </div>

          <div className="schedcon-schedule-container">
            <div className="schedcon-const">
              <div className="schedcon-const-frame">
                <div className="s-c-f-day-mt" />
                <div className="s-c-f-day-wt" />
                <div className="s-c-f-day-fs" />
                <div className="s-c-f-half-sm" />
                <div className="s-c-f-half-tw" />
                <div className="s-c-f-half-tf" />
                <div className="s-c-f-half-sat" />
              </div>
              <div className="schedcon-const-labels">
                <span className="s-c-l-0">SUNDAY</span>
                <span className="s-c-l-1">MONDAY</span>
                <span className="s-c-l-2">TUESDAY</span>
                <span className="s-c-l-3">WEDNESDAY</span>
                <span className="s-c-l-4">THURSDAY</span>
                <span className="s-c-l-5">FRIDAY</span>
                <span className="s-c-l-6">SATURDAY</span>
              </div>
            </div>
            <DrawContainer
              editShowtime={this.editShowtime}
              screen={this.props.screen}
              playlists={this.state.playlists}
              showtimes={this.state.showtimes}
              disabled={this.props.disabled || this.state.isLoading}
            />
          </div>

          {!this.props.disabled && (
            <div className="schedcon-submit-box">
              <Button
                type="submit"
                className="schedcon-submit"
                variant="primary"
                disabled={this.state.isLoading}
              >
                Submit
              </Button>
              {this.state.statusMessage && (
                <div className="schedcon-submit-status">
                  {this.state.statusMessage}
                </div>
              )}
            </div>
          )}
          <ShowtimeEditModal
            show={this.state.showEditModal}
            closeModal={this.closeEditModal}
            editingShowtime={this.state.editingShowtime}
            deleteShowtime={this.deleteShowtime}
          />
        </form>
      </div>
    )
  }
}

export default ScheduleControl

export {ScheduleControl, PLAYLIST_NONE, PseudoPlaylist}
