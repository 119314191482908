import * as React from 'react'
import {Col} from 'react-bootstrap'
import Dotdotdot from 'react-dotdotdot'

interface Props {
  screenId: string
  screenTitle: string
  selected: boolean
  marqueeToggle: (id: string) => void
}

const MarqueeElement: React.StatelessComponent<Props> = (props) => (
  <Col xs={6}>
    <div onClick={() => props.marqueeToggle(props.screenId)}>
      <div className={`screenSelector col-6${props.selected ? ' active' : ''}`}>
        <Dotdotdot clamp={2} className="vertical-child">
          <p>{props.screenTitle}</p>
        </Dotdotdot>
      </div>
    </div>
  </Col>
)

export default MarqueeElement
