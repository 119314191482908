import * as React from 'react'

import Footer from './Footer'

const Check = require('images/elements/checkmark.svg')
const Cross = require('images/elements/close-x.svg')
const BadExample = require('images/urls/bad-example.png')
const GoodExample = require('images/urls/good-example.png')

const Guides: React.StatelessComponent = () => (
  <div id="content-guides">
    <div id="billboard">
      <div className="center">
        <h1>
          drive your content <b>further.</b>
        </h1>
        <h1>
          <b>faster.</b>
        </h1>
      </div>
    </div>
    <div id="content-examples">
      <h1>content matters.</h1>
      <div className="row">
        <div className="col-sm-6">
          <p>
            <Check width="50px" height="50px" id="content-check" />
          </p>
          <p>1920 x 1080</p>
          <p>Consistent color palette</p>
          <p>Concise text</p>
          <p>Simple fonts</p>
          <p>Approved Logos</p>
          <img src={GoodExample} />
        </div>
        <div className="col-sm-6">
          <p>
            <Cross width="50px" height="50px" id="content-cross" />
          </p>
          <p>360 x 240</p>
          <p>Clashing colors</p>
          <p>Wordy text</p>
          <p>Hard to read fonts</p>
          <p>Unapproved Logos</p>
          <img src={BadExample} />
        </div>
      </div>
    </div>
    <div id="mb-requirements">
      <h1>
        <b>miner</b>bytes requirements.
      </h1>
      <div id="regulations">
        <p>
          1. Content should be fresh, relevant, kept up to date, and be of
          acceptable nature for general audiences.
        </p>
        <p>
          2. Videos and images should be 1920x1080 for horizontal displays and
          1080x1920 for vertical displays.
        </p>
        <p>
          3. While MinerBytes is capable of displaying multiple file formats,
          the recommended format is PNG for images and MP4 (using H.264) for
          video.
        </p>
        <p>
          4. Commercial advertisement is prohibited to off campus
          businesses/sponsors (e.g. “$2 Bob’s Burgers”), however recognition is
          acceptable (e.g. “We’d like to thank our sponsor, Bob’s Burgers”).
        </p>
        <p>
          5. Users are responsible for ensuring appropriate use of copyrighted
          material.
        </p>
      </div>
    </div>
    <div id="contact-us">
      <div className="row">
        <div className="col-sm-6">
          <h1>questions?</h1>
        </div>
        <div className="col-sm-6" id="contact-center">
          <p>
            Did we leave something unanswered or unsure if your assets are up to
            par?
          </p>
          <p>
            Contact us at{' '}
            <a href="mailto:minerbytes@mst.edu">minerbytes@mst.edu</a>
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
)

export default Guides
