import * as React from 'react'
import {computed} from 'mobx'
import {observer} from 'mobx-react'

import {RealAsset} from 'api/realsources'

const ImageIcon = require('images/elements/image-icon.svg')
const VideoIcon = require('images/elements/video-icon.svg')
const WebIcon = require('images/elements/web-icon.svg')
const VerticalIcon = require('images/elements/vertical-icon.svg')
const HorizontalIcon = require('images/elements/horizontal-icon.svg')

interface Props {
  asset: RealAsset
  onClick: (asst: RealAsset) => void
}

@observer
class ContentIndexRow extends React.Component<Props> {
  @computed
  get orientationIcon() {
    switch (this.props.asset.orientation) {
      case 'vertical':
        return <VerticalIcon className="orientation-icon vertical" />
      case 'horizontal':
        return <HorizontalIcon className="orientation-icon horizontal" />
      default:
        return null
    }
  }

  @computed
  get isPrivateTD() {
    return this.props.asset.is_private ? (
      <td title="Private">
        <span className="fas fa-eye-slash"/>
      </td>
    ) : (
      <td title="Public">
        <span className="fas fa-globe-americas"/>
      </td>
    )
  }

  @computed
  get fileTypeIcon() {
    switch (this.props.asset.file_type) {
      case 'WEB':
        return <WebIcon className="type-icon type-icon-web" />
      case 'IMAGE':
        return <ImageIcon className="type-icon type-icon-image" />
      case 'VIDEO':
        return <VideoIcon className="type-icon type-icon-video" style={{color: "black"}}/>
      default:
        return null
    }
  }

  render() {
    return (
      <tr
        data-assetid={this.props.asset.id}
        data-ownerid={this.props.asset.ownerID || 'none'}
        onClick={() => this.props.onClick(this.props.asset)}
      >
        <td>{this.props.asset.id}</td>
        <td title={this.props.asset.name}>{this.props.asset.name}</td>
        <td title={this.props.asset.orientation}>{this.orientationIcon}</td>
        {this.isPrivateTD}
        <td title={this.props.asset.file_type}>{this.fileTypeIcon}</td>
      </tr>
    )
  }
}

export default ContentIndexRow
