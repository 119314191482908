import * as React from 'react'
import {IObservableArray} from 'mobx'
import {observer} from 'mobx-react'
import {Button} from 'react-bootstrap'

import {PseudoPlaylist} from './index'

interface Props {
  playlists: IObservableArray<PseudoPlaylist>
  disabled: boolean
}

@observer
class ScheduleAddButton extends React.Component<Props> {
  onClickAddPlaylist = () => {
    if (this.props.playlists.length < 3) {
      this.props.playlists.push(null)
    } else {
      console.error("Can't add another playlist, already have 3")
    }
  }

  render() {
    return (
      <label
        className="sc-add-button"
        style={{
          display:
            !this.props.disabled && this.props.playlists.length < 3
              ? 'inline-block'
              : 'none',
        }}
      >
        <Button onClick={() => this.onClickAddPlaylist()}>
          <span className="fa fa-plus"/>
        </Button>
        Add Playlist
      </label>
    )
  }
}

export default ScheduleAddButton
