import * as React from 'react'
import AddNewPlaylist from '../../../playlists/SideNavNewPlaylist';

interface Props {
    stuff?: string
}
interface State {
    promoted: boolean
}

class CreatePlaylist extends React.Component<Props, State> {
    constructor(props) {
        super(props)

        this.state = {
            promoted: false
        }
    }

    handleCheck = event => {
        // event.preventDefault()
        this.setState({
            promoted: event.target.checked
        })
    }

    render() {
        return (
            <div className="PlaylistCreateTab">
                <AddNewPlaylist
                    promoted={this.state.promoted}
                />
                <div className="checkboxdiv">
                    <div className="checkbox">
                        <input
                            name="promoted"
                            type="checkbox"
                            checked={this.state.promoted}
                            onChange={this.handleCheck}
                        />
                        <span>Promote</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreatePlaylist