import { computed, IObservableArray, observable } from 'mobx'
import { AxiosResponse } from 'axios'

import { OrientationString, PermissionLevel, PlaylistInterface } from 'globals'
import API from 'api'
import { PlaylistResource } from 'api/resources'
import userStore from './userStore'
import contentStore from './contentStore'
import alertStore from './alertStore'
import assetShowtimeStore from './assetShowtimeStore'
import Asset from './Asset'
import User from './User'
import AssetShowtime from './AssetShowtime'
import UserPermissions from './UserPermissions'
import playlistStore from './playlistStore'

class Playlist implements PlaylistInterface {
  static fromResource(resource: PlaylistResource) {
    return new Playlist({
      id: resource.id.toString(),
      title: resource.name,
      ownerID: resource.ownerID && resource.ownerID.toString(),
      orientation: resource.orientation as OrientationString,
      inUse: resource.in_use,
      promoted: resource.promoted,
      assetIDs: resource.assetIDs ? resource.assetIDs.map(String) : [],
      sharedIDs: resource.sharedIDs ? resource.sharedIDs.map(String) : []
    })
  }

  @observable
  id: string
  @observable
  title: string
  @observable
  ownerID: string
  @observable
  orientation: OrientationString | null
  @observable
  inUse: boolean
  @observable
  promoted: boolean
  @observable
  assetIDs: IObservableArray<string> = observable.array()
  @observable
  sharedIDs: IObservableArray<string> = observable.array()
  @observable
  userPermissions: UserPermissions

  @computed
  get owner() {
    if (this.ownerID) {
      return userStore.findById(this.ownerID)
    } else {
      throw Error(`Playlist#${this.id} has invalid owner ${this.ownerID}`)
    }
  }

  @computed
  get sharedWith() {
    return this.userPermissions.users
  }

  @computed
  get sharedWithSortedForDisplay() {
    return this.userPermissions.usersSortedForDisplay
  }

  @computed
  get assets() {
    const fbid = contentStore.findById
    if (!this.assetIDs) {
      return []
    }

    return this.assetIDs.map(id => contentStore.findById(id)).filter(Boolean)
  }

  @computed
  get showtimes() {
    return assetShowtimeStore.getByPlaylistID(this.id)
  }

  @computed
  get showtimesByIndex() {
    return this.showtimes.slice().sort((showtimeA, showtimeB) => {
      if (showtimeA.index < showtimeB.index) {
        return -1
      } else if (showtimeA.index > showtimeB.index) {
        return 1
      }
      return 0
    })
  }

  @computed
  get previewAssets() {
    return this.assets.filter(asset => asset.tpath)
  }

  @computed
  get orientationConflict(): boolean {
    let orientation = null

    for (const asset of this.assets) {
      if (asset.orientation !== null) {
        if (orientation !== null && asset.orientation !== orientation) {
          return true
        }

        orientation = asset.orientation
      }
    }

    return false
  }

  constructor(attrs: PlaylistInterface) {
    Object.assign(this, attrs)

    this.userPermissions = new UserPermissions(this.ownerID, true)
  }

  asResource = (): PlaylistResource => ({
    id: Number(this.id),
    name: this.title,
    ownerID: this.ownerID ? Number(this.ownerID) : undefined,
    orientation: this.orientation,
    in_use: this.inUse,
    promoted: this.promoted,
    assetIDs: this.assetIDs ? this.assetIDs.map(Number) : undefined,
    sharedIDs: this.sharedIDs ? this.sharedIDs.map(Number) : undefined
  })

  hasAsset = (asset: Asset) => this.assetIDs.indexOf(asset.id) !== -1

  toggleAsset = (asset: Asset) =>
    this.hasAsset(asset) ? this.removeAsset(asset) : this.addAsset(asset)

  addAsset = (asset: Asset) =>
  {
    if(!this.hasAsset(asset))
    {
      API.playlists
        .addAsset(asset.asResource(), this.asResource())
        .then(response => {
          const assetShowtimes = []
          response.data.data.forEach(respAsset => {
            assetShowtimes.push(AssetShowtime.fromResource(respAsset.pivot))
          })

          assetShowtimeStore.add(this.id, assetShowtimes)
          this.assetIDs.push(asset.id)

          if (this.orientationConflict) {
            alertStore.addAlert(
              'Vertical assets will not display on horizontal displays, and horizontal assets will not display on vertical displays.',
              'warning',
              'Playlist Contains Mixed Orientation Assets',
              15
            )
          }
        })
    }
  }

  //Remove the asset from the store without an API call
  softRemoveAsset = (asset: Asset) => {
    this.assetIDs.remove(asset.id)
    this.removeShowtimeFor(asset)
  }

  removeAsset = (asset: Asset) => {
    this.assetIDs.remove(asset.id)
    this.removeShowtimeFor(asset)

    API.playlists
      .removeAsset(asset.asResource(), this.asResource())
      .catch(() => {
        this.assetIDs.push(asset.id)
      })
  }

  removeShowtimeFor = (asset: Asset) => {
    const i = this.showtimes.findIndex(s => s.assetID === asset.id)
    if (i !== -1) this.showtimes.splice(i, 1)
  }

  swapShowtimes = (showtimeA: AssetShowtime, showtimeB: AssetShowtime) => {
    const newAssetOrder = this.showtimesByIndex.map(showtime => {
      switch (showtime.index) {
        case showtimeA.index:
          return showtimeB.asset.id
        case showtimeB.index:
          return showtimeA.asset.id
        default:
          return showtime.asset.id
      }
    })

    API.playlists.reorderAssets(this.asResource(), newAssetOrder).then(() => {
      const newIndexForA = showtimeB.index
      const newIndexForB = showtimeA.index

      showtimeA.index = newIndexForA
      showtimeB.index = newIndexForB
    })
  }

  moveShowtimeUp = (showtime: AssetShowtime) => {
    const nextShowtime = this.showtimesByIndex[showtime.index - 1]
    this.swapShowtimes(showtime, nextShowtime)
  }

  moveShowtimeDown = (showtime: AssetShowtime) => {
    const nextShowtime = this.showtimesByIndex[showtime.index + 1]
    this.swapShowtimes(showtime, nextShowtime)
  }

  isFirstShowtime = (showtime: AssetShowtime) =>
    showtime === this.showtimesByIndex[0]

  isLastShowtime = (showtime: AssetShowtime) => {
    const last = this.showtimesByIndex.length - 1
    return showtime === this.showtimesByIndex[last]
  }

  moveArray = (array, oldIndex, newIndex) => {
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0])

    const newArray = []

    // Update showtime indexes
    for (let i = 0; i < array.length; i++) {
      // Update showtime indexes

      // If an asset as been deleted, the showtime will not exist and the page will need refreshed.
      // This needs fixed so that assets and showtimes update without a page refresh.
      if (!array[i]) {
        alertStore.addAlert('Reordering Assets Failed. Please Refresh the Page.', 'danger')
        return
      }

      array[i].index = i
      // Build new array
      newArray[i] = array[i].assetID
    }

    return newArray
  }

  moveShowTime = (
    showtimeIndex: number,
    index: number
  ): Promise<AxiosResponse | void> => {
    const oldIndex = showtimeIndex
    const newIndex = index > oldIndex ? index - 1 : index

    const curShowtimesByIndex = this.showtimesByIndex

    return API.playlists
      .reorderAssets(
        this.asResource(),
        this.moveArray(this.showtimesByIndex, oldIndex, newIndex)
      )
      .catch(reason => {
        this.moveArray(this.showtimesByIndex, newIndex, oldIndex)
        alertStore.addAlert(
          'Reordering Assets Failed. Please Refresh the Page.',
          'danger'
        )
      })
  }

  shareTo = (userID: string, permission: PermissionLevel) => {
    return API.playlists
      .shareTo(this.id, userID, permission)
      .then(resp => {
        const pivot = resp.data.data.pivot

        const respPlaylistID = pivot.playlist_id
        const respUserID = pivot.user_id
        const respPermission = pivot.permission
        const user = User.fromResource(resp.data.data)

        userStore.addIfNew(user)

        // 201 'Created' if new user, 200 'OK' if updated user
        if (resp.statusText !== 'Created' && resp.statusText !== 'OK') {
          console.error('Unexpected response:', resp)
          throw Error('Unexpected response from shareTo endpoint')
        }

        // If a new owner is being set, the old owner is moved down to editor
        if (permission === 'creator') {
          this.shareTo(this.ownerID, 'editor')
          this.userPermissions.changeOwner(userID)
          playlistStore.handoffOwner(this.id)
        } else {
          this.userPermissions.setPermission(userID, permission)
        }
      })
      .catch(err => {
        let what = err
        if (err.response.data && err.response.data.message)
          what = Error(err.response.data.message)
        console.error(err)
        throw what
      })
  }

  updateTitle = (title: string): Promise<AxiosResponse> => {
    const tmpPlaylist = this.asResource()
    tmpPlaylist.name = title
    return API.playlists.update(tmpPlaylist).then(res => {
      if (res.data && res.data.status === 'success') {
        this.title = res.data.data.name
        return res.data.data.name
      } else {
        throw res
      }
    })
  }

  unshareTo = (userid: string) =>
    API.playlists
      .unshareTo(this.id, userid)
      .then((resp: AxiosResponse) => {
        this.userPermissions.unshareTo(userid)
        this.sharedIDs.remove(userid)
      })
      .catch(err => {
        let what = err
        if (err.response.data && err.response.data.message)
          what = Error(err.response.data.message)
        console.error(err)
        throw what
      })
}

export default Playlist
