import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {Button, Form, FormControl, FormLabel} from 'react-bootstrap'

import Playlist from 'stores/Playlist'
import { UserStore } from 'stores/userStore'
import { PlaylistStore } from 'stores/playlistStore'
import { AlertStore } from 'stores/alertStore'
import { AppStateStore } from 'stores/appStateStore'

interface Props {
  userStore?: UserStore
  playlistStore?: PlaylistStore
  alertStore?: AlertStore
  appStateStore?: AppStateStore
  promoted?: boolean
}

interface State {
  value: string
  submitting: boolean
}

@inject('userStore', 'playlistStore', 'alertStore', 'appStateStore')
@observer
class AddNewPlaylist extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      value: '',
      submitting: false,
    }
  }

  canSubmit = () => {
    return this.state.value.trim().length && !this.state.submitting
  }

  handleChange = (event) => {
    this.setState({
      value: event.target.value,
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const title = this.state.value.trim()

    if (!title) {
      return
    }

    const playlist = new Playlist({
      id: null,
      title: this.state.value.trim(),
      ownerID: this.props.userStore.currentUser.id,
      orientation: null,
      inUse: null,
      promoted: this.props.promoted || false,
      assetIDs: [],
      sharedIDs: [],
    })

    this.props.playlistStore
      .create(playlist)
      .then((newPlaylist: Playlist) => {
        this.props.alertStore.addAlert(
          `Successfully created playlist '${newPlaylist.title}'`,
          'success',
          'Success!'
        )
        this.setState({ value: '', submitting: false })
        this.props.appStateStore.closeSideNav()
      })
      .catch((err) => {
        this.props.alertStore.addAlert(
          err,
          'danger',
          'Failed to create playlist'
        )
        this.setState({ submitting: false })
      })
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <Form.Group controlId="create-playlist-title">
          <FormLabel>enter title of new playlist</FormLabel>
          <FormControl
            type="text"
            value={this.state.value}
            onChange={this.handleChange}
            disabled={this.state.submitting}
          />
          <hr />
          <Button className="btn-create-playlist" type="submit" disabled={!this.canSubmit}>
            Create Playlist
          </Button>
          <FormControl.Feedback />
        </Form.Group>
      </form>
    )
  }
}

export default AddNewPlaylist
