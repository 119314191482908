import * as React from 'react'
import {computed} from 'mobx'
import {inject, observer} from 'mobx-react'
import {Button} from 'react-bootstrap'
import Glyphicon from 'glyphicons'

import {AlertStore} from 'stores/alertStore'
import {RealStore} from 'modules/admin/adminstores'

interface Props {
  stores: Array<RealStore<any>>
  alertStore?: AlertStore
}

interface State {
  stage: 'ok' | 'waiting'
}

@inject('alertStore')
@observer
class Refresher extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      stage: 'ok',
    }
  }

  @computed
  get label() {
    return this.state.stage === 'waiting' ? 'Refreshing\u2026' : 'Refresh'
  }

  @computed
  get storeTypes() {
    return this.props.stores.map((store) => store.Tname)
  }

  onClick = () => {
    if (this.state.stage !== 'ok') {
      return
    }

    this.setState({stage: 'waiting'})

    const promises: Array<Promise<boolean>> = this.props.stores.map((store) => {
      return store.getAll((success, name, err) =>
        this.refreshCallback(success, name, err)
      )
    })

    Promise.all(promises).then(() => this.setState({stage: 'ok'}))
  }

  refreshCallback = (success: boolean, name: string, err?: any) => {
    if (!success) {
      let msg = 'Unknown error'

      if (typeof err === 'string') {
        msg = err
      } else if (err.response) {
        if (err.response.data && err.response.data.status) {
          msg = `${err.response.data.status}: ${err.response.data.message}`
        } else {
          msg = `${err.response.status}: ${err.response.statusText}`
        }
      } else {
        msg = String(err)
      }

      this.props.alertStore.addAlert(
        msg,
        'danger',
        `Failed to refresh ${name} real-store`
      )
    }
  }

  render() {
    return (
      <Button
        className="refresher"
        onClick={this.onClick}
        variant="info"
        data-stage={this.state.stage}
        title={`Refresh stores: ${this.storeTypes.join(', ')}`}
      >
        {this.label}
      </Button>
    )
  }
}

export default Refresher
