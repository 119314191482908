import * as React from 'react'
import {Button, Modal, Alert} from 'react-bootstrap'
import {inject, observer} from 'mobx-react'

import { AppStateStore } from 'stores/appStateStore'
import { ContentStore } from 'src/stores/contentStore'
import { AlertStore } from 'src/stores/alertStore'
import { PlaylistStore } from 'src/stores/playlistStore'
import { UserStore } from 'src/stores/userStore'

const Close = require('images/elements/close-x.svg')
const FavoriteIcon = require('images/elements/favorites-icon.svg')

interface Props {
  showModal: boolean
  hideContentDeleterModal: () => void
  appStateStore?: AppStateStore
  contentStore?: ContentStore
  alertStore?: AlertStore
  playlistStore?: PlaylistStore
  userStore?: UserStore
}

const ContentDeleterModel: React.StatelessComponent<Props> = inject(
  'appStateStore',
  'contentStore',
  'alertStore',
  'playlistStore',
  'userStore'
)(
  observer(props => {

    const deleteMultiSelected = () => {
      props.appStateStore.multiSelectedAssets.forEach(asset => {

        if (asset.ownerID !== props.userStore.currentUserId)
          return

        asset.delete()
        props.contentStore.remove(asset)

        props.playlistStore.playlists.forEach(p => {
          const foundAsset = p.assetIDs.find(aId => aId === asset.id)
          if(foundAsset) {
            p.removeAsset(asset)
          }
        })

        props.appStateStore.deselectAll();

      })

      props.alertStore.addAlert('All owned assets have been deleted.', 'success')

      props.appStateStore.toggleMultiSelect()

      props.hideContentDeleterModal()
    }

    return (
      <Modal
        show={props.showModal}
        onHide={props.hideContentDeleterModal}
      >
        <Modal.Header closeButton>
          <h4 className="modal-title content-multi-select">
            Delete selected assets
          </h4>
        </Modal.Header>
        <Modal.Body className="content-multi-select">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>preview</th>
                  <th>title</th>
                  <th>owner</th>
                  <th>type</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {props.appStateStore.multiSelectedAssets.map((asset, i) => {
                  const previewCSS = {
                    backgroundImage: 'url(' + asset.tpath + ')'
                  }

                  let favorite = null

                  if (asset.isFavorite) {
                    favorite = <FavoriteIcon className="favorite-icon" />
                  }

                  return (
                    <tr className={'asset-row'} key={asset.id}>
                      <td>
                        <div
                          className="multi-select-preview"
                          style={previewCSS}
                        >
                          {favorite}
                        </div>
                      </td>
                      <td>{asset.title}</td>
                      <td>{asset.owner.name}</td>
                      <td>{asset.assetType}</td>
                      <td>
                        <Close
                          className="remove-multi-asset"
                          onClick={() =>
                            props.appStateStore.multiSelectAsset(asset)
                          }
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.hideContentDeleterModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className={
              ''
            }
            onClick={() => deleteMultiSelected()}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    )
  })
)

export default ContentDeleterModel