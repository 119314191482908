import * as React from 'react'

import AssetForm from './AssetForm'

interface Props {
  closeSideNav: () => void
}

const NewAssetSideNav: React.StatelessComponent<Props> = props => (
  <div className="new-asset-side-nav">
    <AssetForm closeSideNav={props.closeSideNav} />
  </div>
)

export default NewAssetSideNav
