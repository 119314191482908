import {NOBACKEND} from 'globals'
import API from 'api'
import userStore from './userStore'
import screenStore from './screenStore'
import contentStore from './contentStore'
import playlistStore from './playlistStore'
import notificationStore from './notificationStore'
import broadcastStore from './broadcastStore'
import User from './User'
import dummydata from './dummydata'

/* Getter for /users/current/info */
function fetchMe(): Promise<User> {
  return API.current
    .user()
    .then(meR => {
      const me = User.fromResource(meR)
      userStore.add(me)
      userStore.currentUserId = me.id
      // load institution via getter
      me.institution // tslint:disable-line
      return me
    })
    .catch(err => {
      let msg = 'Unknown error retrieving info'
      if (err.response && err.response.status > 200) {
        if (err.response.status === 401) {
          console.info('Status 401 from /users/current/info')
          delete localStorage.token
          msg = 'Not Authenticated'
        } else {
          msg = `${err.response.status}: ${err.response.statusText}`
        }
      } else if (err.message) {
        msg = err.message
      }
      msg = encodeURIComponent(msg)
      window.location.replace(`/401#error=${msg}`)
      return null
    })
}

export default function initializeStores() {
  if (NOBACKEND) {
    dummydata.addAll()
  } else {
    fetchMe().then(me => {
      screenStore.fetchShared()
      playlistStore.fetchShared()
      contentStore.fetchShared()
      notificationStore.fetchAll()
      if (me && me.broadcast_priv) {
        broadcastStore.fetchAll()
      }
    })
  }
}
