import {computed, observable, runInAction} from 'mobx'
import {AxiosResponse} from 'axios'

import {AssetInterface, AssetTypeString, OrientationString} from 'globals'
import API from 'api'
import {AssetResource} from 'api/resources'
import {assetConverter} from './converters'
import userStore from './userStore'
import AssetShowtime from './AssetShowtime'
import assetShowtimeStore from './assetShowtimeStore'
import User from './User'

const UPDATABLE_ATTRS = [
  'verticalURL',
  'verticalFileID',
  'verticalFile',
  'horizontalURL',
  'horizontalFileID',
  'horizontalFile',
  'webURL',
  'webFileID',
  'webFileURL',
  'webURLData',
  'youtubeID',
  'youtubeFileID',
  'tpath',
  'lifeSpanBegin',
  'lifeSpanEnd',
  'title',
  'orientation',
  'ownerID',
  'description',
  'expires',
  'assetType',
  'isFavorite',
  'isPrivate'
]

export default class Asset implements AssetInterface {
  static readonly OWNVAL = 'creator'

  static fromResource(resource: AssetResource): Asset {
    return new Asset(
      assetConverter.toModel<AssetResource, AssetInterface>(resource)
    )
  }

  @observable id: string
  @observable verticalURL: string = null
  @observable verticalFileID: string = null
  @observable verticalFile: object = null
  @observable horizontalURL: string = null
  @observable horizontalFileID: string = null
  @observable horizontalFile: object = null
  @observable webURL: string = null
  @observable webFileID: string = null
  @observable webFileURL: string = null
  @observable webURLData: string = null
  @observable youtubeID: string = null
  @observable youtubeFileID: string = null
  @observable tpath: string
  @observable lifeSpanBegin: any
  @observable lifeSpanEnd: any
  @observable title: string
  @observable orientation: OrientationString
  @observable ownerID: string
  @observable description: string
  @observable expires: string
  @observable assetType: AssetTypeString
  @observable isFavorite: boolean
  @observable isPrivate: boolean

  constructor(attrs: AssetInterface) {
    if (attrs.id && !attrs.ownerID)
      console.warn(`Ownerless asset, id = ${attrs.id}.`)

    Object.assign(this, attrs)

    this.loadFiles()
  }

  showtimeForPlaylist = (playlistID: string): AssetShowtime => {
    return assetShowtimeStore.get(this.id, playlistID)
  }

  @computed
  get owner(): User {
    if (this.ownerID) {
      return userStore.findById(this.ownerID)
    } else {
      throw new Error(`Asset#${this.id} has invalid owner ${this.ownerID}`)
    }
  }

  @computed
  get ownerString(): string {
    return this.owner.name
  }

  @computed
  get ownerUsername(): string {
    return this.owner.username
  }

  @computed
  get ownerEmail(): string {
    return this.owner.email
  }

  asResource = (): AssetResource => {
    return assetConverter.toResource<Asset, AssetResource>(this)
  }

  // TODO: correctly type this
  toggleFavorite = (): Promise<AxiosResponse | any> => {
    const resource = this.asResource()
    this.isFavorite = !this.isFavorite
    return API.assets
      .toggleFavorite(resource)
      .catch(err => (this.isFavorite = !this.isFavorite))
  }

  loadFiles = () => {
    if (this.webFileID && !this.webFileURL) {
      if (this.verticalURL) this.webFileURL = this.webURLData = this.verticalURL
      else
        API.files.get(Number(this.webFileID)).then(data => {
          runInAction(() => (this.webFileURL = this.webURLData = data.url))
        })
    }
  }

  getImageURL = () => {
    return this.horizontalURL || this.verticalURL
  }

  create = files => {
    // TODO: move to AssetStore where it should be
    if (files.verticalFile || files.horizontalFile) {
      return API.files
        .uploadAll({
          vert_file: files.verticalFile,
          hori_file: files.horizontalFile
        })
        .then(uploadedFiles => {
          uploadedFiles.forEach(file => {
            if (file.orientation === 'horizontal')
              this.horizontalFileID = file.id

            if (file.orientation === 'vertical') this.verticalFileID = file.id
          })

          return API.assets.create(this.asResource()).then(Asset.fromResource)
        })
    } else if (this.webURL) {
      return API.files.createWebURL(this.webURL).then(file => {
        this.webFileURL = file.url
        this.webFileID = file.id

        return API.assets.create(this.asResource()).then(Asset.fromResource)
      })
    } else {
      return API.assets.create(this.asResource()).then(Asset.fromResource)
    }
  }

  edit = (edits, files) => {
    edits.id = this.id

    if (files.verticalFile || files.horizontalFile) {
      return API.files
        .uploadAll({
          vert_file: files.verticalFile,
          hori_file: files.horizontalFile
        })
        .then(uploadedFiles => {
          uploadedFiles.forEach(file => {
            if (file.orientation === 'horizontal')
              edits.horizontalFileID = file.id // need to delete file

            if (file.orientation === 'vertical') edits.verticalFileID = file.id // need to delete file
          })

          return API.assets
            .edit(assetConverter.toResource<Asset, AssetResource>(edits))
            .then(() => this.mergeEdits(edits))
            .then(this.loadFiles)
        })
    } else {
      return API.assets
        .edit(assetConverter.toResource<Asset, AssetResource>(edits))
        .then(() => this.mergeEdits(edits))
    }
  }

  mergeEdits = edits => {
    if (edits.horizontalFileID) this.horizontalURL = null

    if (edits.horizontalURL) this.horizontalFileID = this.horizontalFile = null

    if (edits.verticalFileID) this.verticalURL = null

    if (edits.verticalURL) this.verticalFileID = this.verticalFile = null

    for (const key in edits) {
      if (this.hasOwnProperty(key)) {
        this[key] = edits[key]
      }
    }
  }

  update = (newVersion: Asset): boolean => {
    const merger: any = {}
    let conf = false
    for (const attr of UPDATABLE_ATTRS) {
      if (this[attr] !== newVersion[attr] && newVersion[attr] !== undefined) {
        conf = true
        merger[attr] = newVersion[attr]
      }
    }
    if (conf) Object.assign(this, merger)
    return conf
  }

  delete = () => {
    return API.assets.delete(
      assetConverter.toResource<Asset, AssetResource>(this)
    )
  }
}
