import * as React from 'react'
import {Route, Switch} from 'react-router-dom'
import Headroom from 'react-headroom'
import {Motion, spring} from 'react-motion'

import Home from "../../modules/home/Home"
import Guides from "../../modules/home/Guides"
import Get from "../../modules/home/Get"
import PreviewNav from "../../modules/preview/HelpNav"
import PreviewHelp from "../../modules/preview/Help"
import PreviewAssets from "../../modules/preview/HelpAssets"
import PreviewScreens from "../../modules/preview/HelpScreens"
import PreviewPlaylists from "../../modules/preview/HelpPlaylists"
import PreviewWifi from "../../modules/preview/HelpWifi"
import NotFound from "../../modules/NotFound"

import {GOOGLE_OAUTH2_URL} from 'globals'
import HashAlert from './HashAlert'

interface State {
  navIndex: number
  startNavIndex: number
  width: number
}

class HomeNav extends React.Component<any, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      navIndex: this.switchNavIndex(props.location.pathname),
      startNavIndex: this.switchNavIndex(props.location.pathname),
      width: 0,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.updateWindowWidth())
    this.updateWindowWidth()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateWindowWidth())
  }

  // UPDATE-TODO:
  // Move code with side effects to componentDidMount, and set initial state in the constructor.
  // UNSAFE_componentWillReceiveProps(nextProps) {
  componentDidUpdate(nextProps, nextState: State) {
    if(this.state.navIndex != nextState.navIndex)
    {
      this.setState({
        navIndex: this.switchNavIndex(nextProps.location.pathname)
      })
    }
    if(this.state.width != window.innerWidth)
    {
      this.updateWindowWidth()
    }
  }

  switchNavIndex = (pathname: string) => {
    switch (pathname.substring(1)) {
      case 'guides':
        return 1
      case 'get':
        return 2
      default:
        return 0
    }
  }

  updateWindowWidth = () => {
    this.setState({width: window.innerWidth})
  }

  render() {

    let startElement = document.getElementById(`home-nav-li-${this.state.startNavIndex}`)
    let curElement = document.getElementById(`home-nav-li-${this.state.navIndex}`)
    let ulElement = document.getElementById("home-nav-ul")

    return (
      <div>
        <Headroom
          disableInlineStyles={true}
          id={this.props.location.pathname.substring(1)}
        >
          <nav className="home-navigation">
            <ul className="list-unstyled" id="home-nav-ul">
              <li id="home-nav-li-0"> 
                <a href="/">about</a>
              </li>
              <li id="home-nav-li-1">
                <a href="/guides">content guides</a>
              </li>
              <li id="home-nav-li-2">
                <a href="/get"> get <b>miner</b>bytes </a>
              </li>
              <li id="home-nav-li-3">
                <a href={GOOGLE_OAUTH2_URL}>log in</a>
              </li>
              { (startElement && curElement) ?
                <Motion
                  defaultStyle={{
                    left: startElement.getBoundingClientRect().left - ulElement.getBoundingClientRect().left,
                    width: startElement.offsetWidth
                  }}
                  style={{
                    left: spring(
                      curElement.getBoundingClientRect().left - ulElement.getBoundingClientRect().left
                    ),
                    width: spring(curElement.offsetWidth)
                  }}
                >
                  {move => <div className="active-indicator" style={move} />}
                </Motion>
                : null
              }
            </ul>
          </nav>
          <HashAlert
            paramName="signinerror"
            title="Sign In Error"
            location={this.props.location}
            type="danger"
          />
        </Headroom>
        {this.props.children}
        {/* Routing Information
        This code here is responsible for being able to navigate the website.
        It is placed here because these are all children of the HomeNav object, so they have to
        be included in its render function. */}
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route exact path='/guides' component={Guides}/>
          <Route exact path='/get' component={Get}/>
          <Route path='/preview'>
            <PreviewNav children={window.location.pathname}/>
            <Route exact path="/preview/" component={PreviewHelp}/>
            <Route exact path="/preview/assets" component={PreviewAssets} />
            <Route exact path="/preview/screens" component={PreviewScreens} />
            <Route exact path="/preview/playlists" component={PreviewPlaylists} />
            <Route exact path="/preview/wifi" component={PreviewWifi} />
          </Route>
          <Route component={NotFound}/>
        </Switch>
      </div>
    )
  }
}

export default HomeNav
