import {computed, observable} from 'mobx'

import API from 'api'
import {AssetShowtimeResource} from 'api/resources'
import {assetShowtimeConverter} from './converters'
import Asset from './Asset'
import contentStore from './contentStore'

export default class AssetShowtime {
  static fromResource(resource: AssetShowtimeResource): AssetShowtime {
    return new AssetShowtime(assetShowtimeConverter.toModel(resource))
  }

  playlistID: string
  assetID: string
  @observable index: number
  @observable timeStart: string
  @observable timeStop: string
  @observable dateStart: string
  @observable dateStop: string
  @observable duration: number
  @observable daysOfWeek: any
  @observable enabled: boolean

  @computed
  get asset(): Asset {
    return contentStore.get(this.assetID)
  }

  constructor(attrs) {
    Object.assign(this, attrs)
  }

  get durationMinutes() {
    return Math.floor(this.duration / 60)
  }

  set durationMinutes(minutes) {
    this.duration = minutes * 60 + this.durationSeconds
  }

  get durationSeconds() {
    return this.duration - 60 * this.durationMinutes
  }

  set durationSeconds(seconds) {
    this.duration = this.durationMinutes * 60 + seconds
  }

  asResource = (): AssetShowtimeResource => {
    return assetShowtimeConverter.toResource(this)
  }

  toggleDay = (day: string) => {
    const foundDay = this.daysOfWeek.find(d => d.day === day)
    foundDay.active = !foundDay.active
  }

  update = () => {
    API.assetShowtimes.update(assetShowtimeConverter.toResource(this))
  }
}
