import {ValidationState} from 'globals'

export function netValidity(args: ValidationState[]): ValidationState {
  let foundWarning = false
  let foundSuccess = false

  for (const arg of args) {
    switch (arg) {
      case 'error':
        return 'error'
      case 'warning':
        foundWarning = true
        break
      case 'success':
        foundSuccess = true
        break
    }
  }

  if (foundWarning) {
    return 'warning'
  } else if (foundSuccess) {
    return 'success'
  }

  return null
}
