import {observable} from 'mobx'

import {InstitutionInterface} from 'globals'
import {InstitutionResource} from 'api/resources'

export default class Institution implements InstitutionInterface {
  static fromResource(resource: InstitutionResource): Institution {
    return new Institution({
      id: resource.id.toString(),
      name: resource.name,
      imgURL: resource.img_url,
      webURL: resource.web_url
    })
  }

  @observable id: string
  @observable name: string
  @observable imgURL: string
  @observable webURL: string | null = null

  constructor(attrs: InstitutionInterface) {
    Object.assign(this, attrs)
  }

  asResource = (): InstitutionResource => {
    return {
      id: Number(this.id),
      name: this.name,
      img_url: this.imgURL,
      web_url: this.webURL
    }
  }
}
