import * as React from 'react'
import {Col, Container, Row} from 'react-bootstrap'

const createAssetImage = require('images/urls/new-asset.png')
const currentPlaylistImage = require('images/urls/select-current-playlist.png')

const Help: React.StatelessComponent = () => (
  <Container className="help-grid ml-3">
  <Row>
    <Col>
      <h1>Getting Started</h1>
      <p>
        This getting started guide will walk you through the process of
        creating new assets, building playlists, and setting up screens.
        For more help with specific functions, use the navigation links
        above to explore each section more in depth.
      </p>
    </Col>
  </Row>
  <Row>
    <Col sm={12} md={8}>
      <h3>Adding Content</h3>
      <p>
        To begin setting up playlists for your screens, you must upload
        assets. Assets (or Content) are individual pieces of content that
        will be displayed on a screen. They can be images, videos, or even
        webpages.
      </p>
      <ol>
        <li>
          Click the "New Asset" button on the left side bar of the content
          page.
        </li>
        <li>
          Enter an asset title. This is how your asset will be identified
          for yourself and in the content store.
        </li>
        <li>
          If your asset is a webpage, put the URL in the "custom web URL"
          field.
        </li>
        <li>
          To use images, you may either upload them by clicking the white
          boxes, or enter the URL of a web image in the corresponding URL
          box. You may upload two images (one for vertical screens,
          another for horizontal) if your asset will be displayed on both
          screen orientations.
        </li>
        <li>
          Enter an asset life span. The asset will not appear on screens
          or the content store until the start date, and will continue to
          appear until it is deleted or the end date is reached. The
          default lifespan of an asset is 3 months.
        </li>
        <li>
          To prevent an asset from appearing on the asset store, turn on
          the "private" button.
        </li>
        <li>Click "Create Asset" to confirm creation of the asset.</li>
      </ol>
    </Col>
    {/* removed smHidden */}
    <Col md={4}>
      <img src={createAssetImage} title="New Asset" />
    </Col>
  </Row>
  <Row>
    <Col sm={12} md={8}>
      <h3>Creating a Playlist</h3>
    </Col>
  </Row>
  <Row>
    <Col sm={12} md={8}>
      <p>
        Playlists group a selection of assets into a slideshow to be
        displayed on a screen. Playlists can contain a mixture of images,
        videos, and webpages.
      </p>
      <ol>
        <li>
          Click the "New Playlist" button on the left side bar of the
          playlists page.
        </li>
        <li>Switch back to the content page.</li>
        <li>
          Select the "Add To" button under your asset's card and click on
          your newly created playlist.
        </li>
        <li>
          To play a playlist on a screen, visit the screens page. Select
          your playlist from the drop-down menu under "Current Playlist"
          on your screen's card.
        </li>
      </ol>
    </Col>
    {/* removed smHidden  */}
    <Col md={4}>
      <img src={currentPlaylistImage} title="Select Playlist" />
    </Col>
  </Row>
  <Row>
    <p className="gettingStarted-conclusion">
      This guide should help you create assets, create basic playlists,
      and display them on your first screen. However, this guide does not
      explain advanced playlist scheduling features, or changing screen
      settings. For more information on these functions, visit the
      corresponding help pages using the navigation links above this
      guide.
    </p>
  </Row>
</Container>
)

export default Help
