import * as React from 'react'
import {computed} from 'mobx'
import {inject, observer} from 'mobx-react'
import Select from 'react-select'
import {Props as SelectProps} from 'react-select'

import {PlaylistStore} from 'stores/playlistStore'
import {ShowtimeStore} from 'stores/showtimeStore'
import Screen from 'stores/Screen'
import Playlist from 'stores/Playlist'
import Showtime from 'stores/Showtime'

// This class is now a placeholder since we converted from a dropdown to a display box for current playlist
//   and will need to be removed and replacaed with something more fitting in the future

interface Props {
  activePlaylistID: string
  options: Playlist[]
  disabled: boolean
  screen: Screen
  playlistStore?: PlaylistStore
  showtimeStore?: ShowtimeStore
}

interface State {
  updating: boolean
}

interface PseudoPlaylist {
  id: string
  title: string
  disabled?: boolean
}

// define new Select
const PseudoPlaylistSelect = Select as new (
  props: SelectProps<PseudoPlaylist>
) => Select<PseudoPlaylist>

const PLAYLIST_NONE: PseudoPlaylist = {
  id: 'none',
  title: '<none>',
  disabled: true,
}

const PLAYLIST_MULT: PseudoPlaylist = {
  id: 'multi',
  title: '<multiple>',
  disabled: true,
}

const PLAYLIST_LOAD: PseudoPlaylist = {
  id: 'loading',
  title: 'Updating...',
  disabled: true,
}

@inject('playlistStore', 'showtimeStore')
@observer
class CurrentPlaylistSelect extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      updating: null,
    }
  }

  apiRemoveShowtime = (st: Showtime) => {
    return this.props.showtimeStore
      .delete(st)
      .then(() => true)
      .catch((err) => false)
  }

  createDefaultPlaylistShowtime = (playlistID: string) => {
    const newStTmp = Showtime.createDefaultFull(
      this.props.screen.id,
      playlistID
    )
    this.props.showtimeStore.create(newStTmp).then(() =>
      this.props.screen.refresh().then(() =>
        this.setState({
          updating: false,
        })
      )
    )
  }

  onChange = (newValue: PseudoPlaylist) => {
    if (
      newValue === null ||
      newValue.id === 'none' ||
      newValue.id === 'multiple' ||
      newValue.id === this.props.activePlaylistID
    ) {
      return
    }

    if (this.props.screen.playlistIDs.length) {
      // Screen has some showtimes; confirm with user before overwriting

      const message =
        'Selecting a playlist from the Current Playlist dropdown will' +
        ' override and remove all scheduled playlists. Are you sure' +
        ` you want the playlist ${newValue.title} to override this` +
        ' screen?'

      if (!window.confirm(message)) {
        return
      }
    }

    const currShowtimes = this.props.showtimeStore.getScreenShowtimes(
      this.props.screen.id
    )
    const statuses = currShowtimes.map(this.apiRemoveShowtime)

    this.setState({
      updating: true,
    })

    Promise.all(statuses)
      .then((resolved) => {
        if (resolved.every(Boolean)) {
          this.createDefaultPlaylistShowtime(newValue.id)
        } else {
          console.error('Partial/whole failure deleting Showtimes')
          this.setState({
            updating: false,
          })
        }
      })
      .catch((err) => {
        console.error('Failure deleting Showtimes:', err)
        this.setState({
          updating: false,
        })
      })
  }

  @computed
  get selectPlaylist() {
    return this.state.updating
      ? PLAYLIST_LOAD
      : this.props.activePlaylistID === 'none'
      ? PLAYLIST_NONE
      : this.props.activePlaylistID === 'multiple'
      ? PLAYLIST_MULT
      : this.props.activePlaylistID
      ? this.props.playlistStore.findById(this.props.activePlaylistID)
      : null
  }

  render() {
    return <p className="playlist-box">{this.selectPlaylist.title}</p>
  }
}

export default CurrentPlaylistSelect
