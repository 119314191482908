import * as React from 'react'
import {Modal, Carousel} from 'react-bootstrap'

import AssetPreview from '../AssetPreview'

import moment = require('moment');

const PrevArrow = require('images/elements/left-indicator.svg')
const NextArrow = require('images/elements/right-indicator.svg')

// Consts for icons/glyphs in details modal
const ImageIcon = require('images/elements/image-icon.svg')
const VideoIcon = require('images/elements/video-icon.svg')
const WebIcon = require('images/elements/web-icon.svg')

interface Props {
  show: boolean
  close: () => void
  verticalURL: string
  horizontalURL: string
  youtubeID: string
  title: string
  assetType: string
  previewIndex: number
  handlePreviewSelect: (index: number) => void

  ownerName: string
  expires: string
  privacy: boolean
}

const reformatTime = (timeString: string): string => {
  return moment(timeString).format('LLLL')
}

const assetIndicator = (<p>didn't display</p>)
const typeIcon = (assetType : String) => {
  switch(assetType)
  {
    case 'VIDEO':
      return (
        <div className="asset-preview-icon-display">
          <VideoIcon className="type-icon type-icon-video" />
          {/* <h3>(Video)</h3> */}
        </div>
      )
    case 'IMAGE':
    return (<div className="asset-preview-icon-display">
          <ImageIcon className="type-icon type-icon-image" />
          {/* <h3>(Image)</h3> */}
        </div>
      )
    case 'WEB':
      return (
        <div className="asset-preview-icon-display">
          <WebIcon className="type-icon type-icon-web" />
          {/* <h3>(Web)</h3> */}
        </div>
      )
      default:
        return (<p>failed to hit a case</p>)
  }
}

function privacyIcon(isPrivate : boolean) {
  return isPrivate ? (
    <div className="asset-preview-icon-privacy">
      <span className="fa fa-eye-close"/>
      {/* <h3>(Private)</h3> */}
    </div>
  ) : (
    <div className="asset-preview-icon-privacy">
      <span className="fa fa-globe"/>
      {/* <h3>(Public)</h3> */}
    </div>
  )
}

const AssetPreviewModal: React.StatelessComponent<Props> = props => {
  const showPreviewControls =
    (props.verticalURL !== null && props.horizontalURL !== null) ||
    props.youtubeID !== null

  return (
    <Modal 
      className="asset-preview-modal"
      show={props.show} 
      onHide={() => props.close()}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="content-preview">
        <Carousel
          defaultActiveIndex={props.previewIndex}
          indicators={showPreviewControls}
          controls={showPreviewControls}
          interval={null}
          wrap={false}
          prevIcon={<PrevArrow className="prev-arrow" />}
          nextIcon={<NextArrow className="next-arrow" />}
        >
          <Carousel.Item>
            <AssetPreview
              url={props.verticalURL}
              youtubeID={props.youtubeID}
              orientation="vertical"
              type={props.assetType}
            />
            <Carousel.Caption>
              <p className="caption">Vertical</p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <AssetPreview
              url={props.horizontalURL}
              youtubeID={props.youtubeID}
              orientation="horizontal"
              type={props.assetType}
            />
            <Carousel.Caption>
              <p className="caption">Horizontal</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <div className="asset-preview-info">
          {/* Places All layers in a flex container to layer them properly */}
          <div className="preview-flex-container">
            {/* Owner Layer */}
            <div className="asset-preview-owner-wrapper">
              <p className="asset-preview-owner-header">Owner:</p>
              <p className="asset-preview-owner">{props.ownerName}</p>
              {/* Icon Info Layer */}
              <div className='asset-preview-icons'>
                {typeIcon(props.assetType)}
                {privacyIcon(props.privacy)}
              </div>
            </div>
            {/* Expiration Date Layer */}
            <div className="asset-preview-expiration-wrapper">
              <p className="asset-preview-expiration-date-header">Expires:</p>
              <p className="asset-preview-expiration-date">{reformatTime(props.expires)}</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-default" onClick={props.close}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default AssetPreviewModal
