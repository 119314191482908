import * as React from 'react'
import {computed} from 'mobx'
import {inject, observer} from 'mobx-react'

import {UserStore} from 'stores/userStore'

const ProfileHolder = require('images/urls/blank-profile-picture.svg')

interface Props {
  openProfileEdit: () => void
  signOut: (event: any) => void
  userStore?: UserStore
}

interface State {
  panelVisible: boolean
}

@inject('userStore')
@observer
class ProfileIcon extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      panelVisible: false
    }
  }

  @computed
  get user() {
    return this.props.userStore.currentUser || this.props.userStore.fakeUser
  }

  @computed
  get profileImageLarge() {
    return (
      <img
        className="profile-img-large"
        src={this.user.imageURL || ProfileHolder}
      />
    )
  }

  @computed
  get profileImageSmall() {
    return (
      <img
        className="profile-img-small"
        src={this.user.imageURL || ProfileHolder}
        onClick={() => this.iconClicked()}
      />
    )
  }

  iconClicked = () => {
    this.setState({
      panelVisible: !this.state.panelVisible
    })
  }

  render() {
    return (
      <div id="profile-wrapper">
        <div
          id="profile-icon"
          className={this.state.panelVisible ? 'active ' : ''}
        >
          {this.profileImageSmall}
          <div className="t2b-curve-join">
            <div className="stroke" />
          </div>
        </div>
        {this.state.panelVisible && (
          <div>
            <div className="close-profile-panel" onClick={this.iconClicked} />
            <div className="user-panel">
              {this.profileImageLarge}

              <div className="user-info-container">
                <h3 className="name">{this.user.name}</h3>

                {this.user.institution && <p>{this.user.institution.name}</p>}
                <p>{this.user.department}</p>

                <button className="edit" onClick={this.props.openProfileEdit}>
                  Edit
                </button>
                <button className="sign-out" onClick={this.props.signOut}>
                  Sign Out
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ProfileIcon
