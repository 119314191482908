import * as React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {inject, observer} from 'mobx-react'

import {ContentStore} from 'stores/contentStore'
import Asset from 'stores/Asset'
import Playlist from 'stores/Playlist'

const Close = require('images/elements/close-x.svg')
const CheckMark = require('images/elements/checkmark.svg')

interface Props {
  show: boolean
  onHide: () => void
  playlist: Playlist
  contentStore?: ContentStore
}

interface State {
  selectedAssets: Asset[]
  assetFilter: 'FAVORITES' | 'MINE'
}

@inject('contentStore')
@observer
class AddAssetModal extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      selectedAssets: null,
      assetFilter: 'FAVORITES',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.show && !this.props.show) {
      this.setState({selectedAssets: null})
    }
  }

  selectAsset = (asset: Asset) => {
    let selectedAssets = this.state.selectedAssets

    if (selectedAssets === null) {
      selectedAssets = [asset]
    } else {
      const assetIndex = selectedAssets.indexOf(asset)
      if (assetIndex === -1) {
        selectedAssets.push(asset)
      } else {
        selectedAssets.splice(assetIndex, 1)
      }
    }

    this.setState({
      selectedAssets,
    })
  }

  addAssetsToPlaylist = () => {
    // TODO: turn this into an api call
    this.state.selectedAssets.forEach((asset) => {
      this.props.playlist.addAsset(asset)
    })
    this.props.onHide()
  }

  render() {
    let selectedRowClass = ''
    if (this.state.selectedAssets !== null) {
      selectedRowClass =
        this.state.selectedAssets.length > 0 ? ' filled' : ' empty'
    }

    return (
      <Modal
        className="add-asset-modal"
        onHide={this.props.onHide}
        show={this.props.show}
      >
        <Modal.Header>
          <h4 className="modal-title">
            Add assets to {this.props.playlist.title}
          </h4>
          <Close
            type="button"
            className="close close-x"
            onClick={this.props.onHide}
          />
        </Modal.Header>
        <Modal.Body className={this.state.selectedAssets ? 'expanded' : ''}>
          <div className="content-switch">
            <button
              className={
                'favorites' +
                (this.state.assetFilter === 'FAVORITES' ? ' active' : '')
              }
              onClick={() => this.setState({assetFilter: 'FAVORITES'})}
            >
              Favorites
            </button>
            <button
              className={
                'mine' + (this.state.assetFilter === 'MINE' ? ' active' : '')
              }
              onClick={() => this.setState({assetFilter: 'MINE'})}
            >
              Mine
            </button>
          </div>
          <div className="add-asset-body">
            {this.state.assetFilter === 'FAVORITES'
              ? this.props.contentStore.favorites.map((asset, i) => {
                  const selected =
                    this.state.selectedAssets &&
                    this.state.selectedAssets.indexOf(asset) !== -1

                  if (this.props.playlist.hasAsset(asset)) {
                    return null
                  }

                  return (
                    <div
                      key={asset.id}
                      className="user-asset"
                      style={{backgroundImage: `url(${asset.tpath})`}}
                    >
                      <div
                        className={
                          'multi-selector' + (selected ? ' selected' : '')
                        }
                        onClick={() => this.selectAsset(asset)}
                      >
                        <CheckMark className="multi-selector-checkmark" />
                      </div>
                    </div>
                  )
                })
              : this.props.contentStore.myAssets.map((asset, i) => {
                  const selected =
                    this.state.selectedAssets &&
                    this.state.selectedAssets.indexOf(asset) !== -1

                  if (this.props.playlist.hasAsset(asset)) {
                    return null
                  }

                  return (
                    <div
                      key={asset.id}
                      className="user-asset"
                      style={{backgroundImage: `url(${asset.tpath})`}}
                    >
                      <div
                        className={
                          'multi-selector' + (selected ? ' selected' : '')
                        }
                        onClick={() => this.selectAsset(asset)}
                      >
                        <CheckMark className="multi-selector-checkmark" />
                      </div>
                    </div>
                  )
                })}
            {/* Keeps the bottom row shifted to the left */}
            <div className="user-asset empty" />
            <div className="user-asset empty" />
            <div className="user-asset empty" />
            <div className="user-asset empty" />
            <div className="user-asset empty" />
            <div className="user-asset empty" />
            <div className="user-asset empty" />
            <div className="user-asset empty" />
            <div className="user-asset empty" />
            <div className="user-asset empty" />
          </div>
          <div className={`selected-assets-row${selectedRowClass}`}>
            {this.state.selectedAssets &&
              this.state.selectedAssets.map((asset, i) => (
                <div
                  key={asset.id}
                  className="user-asset"
                  onClick={() => this.selectAsset(asset)}
                  style={{backgroundImage: `url(${asset.tpath})`}}
                />
              ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* bsStyle="default" */}
          <Button onClick={this.props.onHide}>Cancel</Button>
          <Button
            variant="primary"
            disabled={
              this.state.selectedAssets === null ||
              this.state.selectedAssets.length === 0
            }
            className={
              this.state.selectedAssets === null ||
              this.state.selectedAssets.length === 0
                ? 'disabled'
                : ''
            }
            onClick={this.addAssetsToPlaylist}
          >
            Add Assets
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default AddAssetModal
