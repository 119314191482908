import * as React from 'react'
const Logo = require('../../images/elements/mb-logo.svg')

const LazyPlaceholder: React.SFC = () => {
  return (
    <div className="loader-wrapper">
      {/* <Logo id="lazy-animate" /> */}
    </div>
  )
}

export default LazyPlaceholder
