import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {Button} from 'react-bootstrap'

import SideNavElement from 'modules/navs/SideNavElement'
import NewAssetSideNav from './NewAssetSideNav'
import ContentCardSection from './ContentCardSection'
import SideNavFavorites from './FavoritesSideNav'
import {ContentStore} from 'stores/contentStore'
import {PlaylistStore} from 'stores/playlistStore'
import {AppStateStore} from 'stores/appStateStore'
import PlaylistPlacerModal from './modals/PlaylistPlacer'
import ContentDeleterModal from './modals/ContentDeleter'
import SideNav from '../navs/SideNav'
import SearchBar from '../common/SearchBar'

const NewAsset = require('images/elements/plus-icon.svg')
const Favorites = require('images/elements/favorites-icon.svg')

interface Props {
  contentStore?: ContentStore
  playlistStore?: PlaylistStore
  appStateStore?: AppStateStore
}

interface State {
  contentMode: boolean
  showPlaylistModal: boolean
  showDeleteModal: boolean
}

@inject('contentStore', 'playlistStore', 'appStateStore')
@observer
class Content extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)

    this.props.appStateStore.closeSections()
    this.props.appStateStore.deselectAll()
    this.props.appStateStore.multiSelecting = false

    this.state = {
      contentMode: true,
      showPlaylistModal: false,
      showDeleteModal: false
    }
  }

  componentWillUnmount() {
    this.props.contentStore.searching = false
  }

  contentChange = () => {
    this.setState({
      contentMode: !this.state.contentMode,
    })
  }

  hidePlaylistPlacerModal = () =>
    this.setState({
      showPlaylistModal: false
    })

  hideContentDeleterModal = () =>
    this.setState({
      showDeleteModal: false
    })

  togglePlaylistModal = () =>
    this.setState({
      showPlaylistModal: !this.state.showPlaylistModal
    })

  toggleDeleteModal = () =>
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    })

  render() {
    const allActive = this.state.contentMode ? ' active' : ''
    const meActive = this.state.contentMode ? '' : ' active'

    const multiSelectActive = this.props.appStateStore.multiSelecting
      ? ' active'
      : ''

    return (
      <div id="content-page">
        <SideNav>
          <li id="new-asset">
            <SideNavElement
              customClass="new-asset"
              svgIcon={<NewAsset id="new-asset-svg-icon" />}
              title="New Asset"
            >
              <NewAssetSideNav
                closeSideNav={() => {
                  this.props.appStateStore.closeSideNav()
                }}
              />
            </SideNavElement>
          </li>
          <li id="favorites">
            <SideNavElement
              customClass="favorites"
              svgIcon={<Favorites id="favorites-svg-icon" />}
              title="Favorites"
            >
              <SideNavFavorites addAssetsToPlaylist={this.togglePlaylistModal} />
            </SideNavElement>
          </li>
        </SideNav>
        <div className="content-with-sidenav">
          <div className="content-switch-row">
            <div className="content-switch">
              <button
                className={'all-mode' + allActive}
                onClick={this.contentChange}
              >
                All
              </button>
              <button
                className={'me-mode' + meActive}
                onClick={this.contentChange}
              >
                Me
              </button>
            </div>

            <div className="select-multiple-grp">
              {this.props.appStateStore.multiSelectedAssets.length > 0 &&
                this.props.appStateStore.multiSelecting && (
                  <div className="select-multiple-count">
                    {this.props.appStateStore.multiSelectedAssets.length}
                    {this.props.appStateStore.multiSelectedAssets.length === 1
                      ? ' item '
                      : ' items '}
                    selected
                  </div>
                )}

              {this.props.appStateStore.multiSelectedAssets.length > 0 &&
                this.props.appStateStore.multiSelecting && (
                  <Button
                    className="deselect-btn"
                    onClick={() => this.props.appStateStore.deselectAll()}
                  >
                    Deselect
                  </Button>
                )}

              {this.props.appStateStore.multiSelectedAssets.length > 0 &&
                this.props.appStateStore.multiSelecting && (
                  <Button onClick={this.togglePlaylistModal}>Add to</Button>
                )}

              {this.props.appStateStore.multiSelectedAssets.length > 0 &&
                this.props.appStateStore.multiSelecting && (
                  <Button onClick={this.toggleDeleteModal}>Delete</Button>
                )}

              <Button
                className={'select-multiple-btn' + multiSelectActive}
                onClick={() => this.props.appStateStore.toggleMultiSelect()}
              >
                Multi-Select
              </Button>
            </div>
          </div>

          <PlaylistPlacerModal
            showModal={this.state.showPlaylistModal}
            hidePlaylistPlacerModal={this.hidePlaylistPlacerModal}
          />

          <ContentDeleterModal
            showModal={this.state.showDeleteModal}
            hideContentDeleterModal={this.hideContentDeleterModal}
          />

          <SearchBar store={this.props.contentStore} />

          {this.state.contentMode &&
            !this.props.contentStore.searching &&
            this.props.contentStore.recentAssets.length > 0 && (
              <ContentCardSection
                title="Recent Additions"
                customClass="recent-additions"
                playlists={this.props.playlistStore.playlists}
                assets={this.props.contentStore.recentAssets}
              />
            )}

          {this.state.contentMode &&
            !this.props.contentStore.searching &&
            this.props.contentStore.departmentAssets.length > 0 && (
              <ContentCardSection
                title="In Your Department"
                customClass="in-department"
                playlists={this.props.playlistStore.playlists}
                assets={this.props.contentStore.departmentAssets}
              />
            )}

          {this.state.contentMode &&
            !this.props.contentStore.searching &&
            this.props.contentStore.institutionAssets.length > 0 && (
              <ContentCardSection
                title="At Your Institution"
                customClass="at-institution"
                playlists={this.props.playlistStore.playlists}
                assets={this.props.contentStore.institutionAssets}
              />
            )}

          {this.state.contentMode && this.props.contentStore.searching && (
            <ContentCardSection
              title="All Search Results"
              customClass="search-results"
              alwaysExpand={true}
              playlists={this.props.playlistStore.playlists}
              assets={this.props.contentStore.searchResults}
            />
          )}

          {!this.state.contentMode && !this.props.contentStore.searching && (
            <ContentCardSection
              title="My Assets"
              customClass="my-assets"
              alwaysExpand={true}
              playlists={this.props.playlistStore.playlists}
              assets={this.props.contentStore.myAssets}
            />
          )}

          {!this.state.contentMode && this.props.contentStore.searching && (
            <ContentCardSection
              title="My Search Results"
              customClass="my-search-assets"
              alwaysExpand={true}
              playlists={this.props.playlistStore.playlists}
              assets={this.props.contentStore.mySearchResults}
            />
          )}
        </div>
      </div>
    )
  }
}

export default Content
