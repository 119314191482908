import {
  BACKEND_DOMAIN,
  DOMAIN_PROTO,
  NOBACKEND,
  TOKEN_TIMEOUT_GRACE
} from 'globals'
import API from 'api'
import userStore from 'stores/userStore'

// heavily based on https://github.com/ReactTraining/react-router/tree/v3/examples/auth-flow/auth.js

interface UnpredicableStringObject {
  [key: string]: string
}

function loggedIn() {
  if (NOBACKEND) {
    return true
  } else if (!window.localStorage.token) {
    return false
  } else if (getTokenTTL(localStorage.token) <= 0) {
    delete window.localStorage.token
    onChange(false)
    return false
  }

  return true
}

function login(token: string, cb: (success: boolean, err?: string) => void) {
  callBackend(token, res => {
    if (res.authenticated) {
      window.localStorage.token = res.token
      fetch('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token
        }
      })
        .then(response => response.json())
        .then(json => {
          window.localStorage.profileImage = json.picture
          if (userStore.currentUser) {
            userStore.currentUser.imageURL = json.picture
          }
        })
      API.setToken(res.token)

      if (cb) {
        cb(true)
      }

      this.onChange(true)
    } else {
      if (cb) {
        cb(false, res.err)
      }

      this.onChange(false)
    }
  })
}

function logout(cb?: () => void) {
  delete window.localStorage.token

  if (cb) {
    cb()
  }

  return true
}

function onChange(loggedin) {
  // TODO: write the body of this function
}

/*
  callBackend(): attempt to authorize the user through the backend's
    oauth2callback endpoint using the argument *token*, after which
    the *callback* argument is called.
*/
function callBackend(
  token: string,
  callback: (
    arg: {authenticated: boolean; token?: string; err?: string}
  ) => void
) {
  fetch(DOMAIN_PROTO + '://' + BACKEND_DOMAIN + '/oauth2callback', {
    method: 'POST',
    mode: 'cors',
    headers: {Accept: 'application/json'},
    body: JSON.stringify({token})
  })
    .then((response: any) => {
      if (response.ok) {
        response
          .json()
          .then(json => {
            if (json.token) {
              callback({
                authenticated: true,
                token: json.token
              })
            } else {
              callback({authenticated: false, err: 'No token from backend'})
            }
          })
          .catch(error => {
            console.error(
              'An error occurred while parsing an OK-response from the backend:',
              error
            )
            callback({
              authenticated: false,
              err: 'A response handling error occurred'
            })
          })
      } else {
        response
          .json()
          .then(json => {
            console.error('An error occurred serverside:', json)
            callback({authenticated: false, err: json.error})
          })
          .catch(error => {
            console.error(
              `Error handling ${response.status} ${response.statusText}:`,
              error
            )
            callback({
              authenticated: false,
              err: 'An error occurred while handling an error'
            })
          })
      }
    })
    .catch(error => {
      console.error('A fetch-level error occurred:', error)
      callback({authenticated: false, err: 'A fetch handling error occurred'})
    })
}

/*
  parseHash(): parse the values from a url hash, e.g. location.hash.substring(1).
  based on https://developers.google.com/identity/protocols/OAuth2UserAgent#validate-access-token
*/
function parseHash(locationHash: string) {
  const params: UnpredicableStringObject = {}
  const regex = /([^&=]+)=([^&]*)/g
  let match
  while ((match = regex.exec(locationHash))) {
    params[decodeURIComponent(match[1])] = decodeURIComponent(match[2])
  }

  return params
}

/*
  getTokenTTL(): determine a JWToken's time until expiration.
  Returns:
    (number): milliseconds until token expires
    undefined: token unset
*/
function getTokenTTL(token: any) {
  if (!token) {
    return undefined
  }
  token = parseToken(token)
  if (!token) {
    return -1
  }
  return token.expiration.getTime() - Date.now()
}

/*
  makeTokenTimer(): create a timer object from the JWToken's expiration that
    calls a callback a set time before expiry (based on TOKEN_TIMEOUT_GRACE).
    Will call logout() if the token's expired.
  Returns:
    timer: timer to call 'callback' before
    null: token has expired
    undefined: token unset
*/
function makeTokenTimer(callback: any) {
  const ttl = getTokenTTL(window.localStorage.token)
  if (!ttl) {
    return ttl
  } else if (ttl < 0) {
    logout(null)
    return null
  }
  return setTimeout(callback, ttl - TOKEN_TIMEOUT_GRACE)
}

/*
  parseToken(): parse a JWToken into an object.
  Returns:
    object: the JWT as an object
    null: token has expired
    undefined: token unset
*/
function parseToken(token: string) {
  const parts = token.split('.')
  try {
    const header = JSON.parse(atob(parts[0]))
    const payload = JSON.parse(atob(parts[1]))

    return {
      type: header.typ,
      alg: header.alg,
      payload,
      issuer: payload.iss,
      jwtID: payload.jti,
      expiration: new Date(payload.exp * 1000),
      issuedAt: new Date(payload.iat * 1000),
      notBefore: new Date(payload.nbf * 1000),
      subject: payload.sub,
      signature: parts[2]
    }
  } catch (e) {
    console.log('Error while parsing token:', e)
    return null
  }
}

/*
  refreshToken(): contact the server and refresh the JWToken. Does NOT call
    logout() on failure.
*/
function refreshToken(cb: (success: boolean) => void) {
  const token = window.localStorage.token

  if (!token) {
    console.log("Error: can't refresh unset token")
    cb(false)
    return
  }

  fetch(DOMAIN_PROTO + '://' + BACKEND_DOMAIN + '/auth/refresh', {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    }
  })
    .then((response: any) => {
      if (response.ok) {
        response.json().then(json => {
          if (json.token) {
            console.log('BACKEND: Token successfully refreshed')
            window.localStorage.token = json.token
            API.setToken(json.token)
            cb(true)
          } else {
            console.log('BACKEND: Token refresh error: no token in response')
            cb(false)
          }
        })
      } else {
        console.log(
          'BACKEND: Error from backend/auth/refresh:',
          response.status,
          ' ',
          response.statusText
        )
        cb(false)
      }
    })
    .catch(error => {
      console.log(
        'BACKEND: Error from, or while parsing, backend/auth/refresh:',
        error
      )
      cb(false)
    })
}

export default {
  loggedIn,
  login,
  logout,
  parseHash,
  onChange,
  refreshToken,
  getTokenTTL,
  makeTokenTimer
}
