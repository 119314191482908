import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {FormControl, FormGroup} from 'react-bootstrap'

import AssetShowtime from 'stores/AssetShowtime'
import { AlertStore } from 'src/stores/alertStore'

interface Props {
  showtime: AssetShowtime
  alertStore?: AlertStore
}

const DATE_RE = /^[0-9]{4}(-|\/)(0[1-9]|1[0-2])(-|\/)(0[1-9]|[1-2][0-9]|3[0-1])$/
const TIME_RE = /^([0-1][0-9]|2[0-4]):([0-5][0-9]):([0-5][0-9])$/

@inject('alertStore')
@observer
class AssetScheduler extends React.Component<Props> {

  toggleDay = day => {
    this.props.showtime.toggleDay(day.day)
  }

  handleTimeStartChange = event => {
    this.props.showtime.timeStart = event.target.value
  }

  handleTimeStopChange = event => {
    this.props.showtime.timeStop = event.target.value
  }

  handleDateStartChange = event => {
    this.props.showtime.dateStart = event.target.value
  }

  handleDateStopChange = event => {
      this.props.showtime.dateStop = event.target.value
  }

  componentWillUnmount() {
    if (!DATE_RE.test(this.props.showtime.dateStop)) {
      this.props.alertStore.addAlert(
        `Use the format YYYY-MM-DD. Nothing was changed.`,
        `danger`,
        `Invalid End Date`,
        10
      )
      return
    }
    if (!DATE_RE.test(this.props.showtime.dateStart)) {
      this.props.alertStore.addAlert(
        `Use the format YYYY-MM-DD. Nothing was changed.`,
        `danger`,
        `Invalid Start Date`,
        10
      )
      return
    }
    if (!TIME_RE.test(this.props.showtime.timeStop)) {
      this.props.alertStore.addAlert(
        `Use the 24-Hour hh:mm:ss format. Nothing was changed.`,
        `danger`,
        `Invalid End Time`,
        10
      )
      return
    }
    if (!TIME_RE.test(this.props.showtime.timeStart)) {
      this.props.alertStore.addAlert(
        `Use the 24-Hour hh:mm:ss format. Nothing was changed.`,
        `danger`,
        `Invalid Start Time`,
        10
      )
      return
    }

    this.props.showtime.update()
  }

  render() {
    return (
      <div className="asset-scheduler">
        <h5>Asset Scheduler</h5>
        <h6>Display Time Range</h6>
        <FormGroup className="time-inputs">
          <FormControl
            placeholder="00:00:00"
            value={this.props.showtime.timeStart}
            onChange={this.handleTimeStartChange}
          />
          <span className="to">to</span>
          <FormControl
            placeholder="23:59:59"
            value={this.props.showtime.timeStop}
            onChange={this.handleTimeStopChange}
          />
        </FormGroup>

        <h6>Display Days</h6>

        <div className="weekdays">
          {this.props.showtime.daysOfWeek.map((day, i) => (
            <div
              key={i}
              className={'day-tile' + (day.active ? ' selected' : '')}
              onClick={() => this.toggleDay(day)}
            >
              {day.day}
            </div>
          ))}
        </div>

        <h6>Display Date Range</h6>

        <FormGroup className="date-inputs">
          <FormControl
            placeholder="2000-01-01"
            value={this.props.showtime.dateStart}
            onChange={this.handleDateStartChange}

          />
          <span className="to">to</span>
          <FormControl
            placeholder="2075-01-01"
            value={this.props.showtime.dateStop}
            onChange={this.handleDateStopChange}
          />
        </FormGroup>
      </div>
    )
  }
}

export default AssetScheduler
