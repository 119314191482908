import * as React from 'react'

import {observer} from 'mobx-react'

export const TIMEINPUTREGEX = /^([0-9]|0[0-9]|1[0-2]):([0-5][0-9])\s?(am|pm)?$/i

export const HHMMSS = /(\d{1,2}):(\d{2}):(\d{2})/
export const SECPERMINUTE = 60
export const SECPERHOUR = 60 * SECPERMINUTE
export const SECPERDAY = 24 * SECPERHOUR
export const SECPERWEEK = 7 * SECPERDAY

export const DOWS_2 = Object.freeze(['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'])

export const DOWS_3 = Object.freeze([
  'SUN',
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT'
])

export function hhmmss_toSeconds(hhmmss: string): number {
  const R = HHMMSS.exec(hhmmss)
  return R ? Number(R[3]) + 60 * (Number(R[2]) + 60 * Number(R[1])) : null
}

export function hhmmss_totimeinput(hhmmss: string): string {
  const R = HHMMSS.exec(hhmmss)
  if (!R) return null

  let h = Number(R[1])
  let p = 'am'

  if (h === 12) {
    p = 'pm'
  }
  if (h > 12) {
    h -= 12
    p = 'pm'
  }
  return `${h}:${R[2]} ${p}`
}

export function seconds_tohhmmss(seconds: number): string {
  seconds = seconds % SECPERDAY
  const hh = Math.floor(seconds / SECPERHOUR)
  seconds = seconds % SECPERHOUR
  // pad minutes
  const mm = `0${Math.floor(seconds / SECPERMINUTE)}`.slice(-2)
  return `${hh}:${mm}:00`
}

export function now_weektime(): number {
  const now = new Date()
  return (
    now.getDay() * SECPERDAY +
    now.getHours() * SECPERHOUR +
    now.getMinutes() * 60 +
    now.getSeconds()
  )
}

/* timeinput_toSeconds(): converts a time matching TIMEINPUTREGEX to seconds.
    Returns null if invalid.
*/
export function timeinput_toSeconds(tinput: string): number {
  const R = TIMEINPUTREGEX.exec(tinput)
  if (!R) return null

  const hh = Number(R[1])
  const mm = Number(R[2])
  const p = R[3] ? R[3].toLowerCase() : null

  let t = 60 * (Number(R[2]) + 60 * Number(R[1]))

  if (hh === 12 && p === 'am') t -= 12 * SECPERHOUR
  else if (p === 'pm' && hh !== 12) t += 12 * SECPERHOUR

  return t
}

interface TimeInputProps {
  onChange: (seconds: number | null) => void
  defaultValue?: string
  className?: string
  // pattern-props: all three must be provided if any
  pattern?: RegExp
  hhmmss_toPattern?: (hhmmss: string) => string
  pattern_toSeconds?: (patt: string) => number
  // passed-through props
  disabled?: boolean
  form?: string
  name?: string
  placeholder?: string
  required?: boolean
}

interface TimeInputState {
  value: number | null
}

@observer
export class TimeInput extends React.Component<any, any> {
  static defaultProps = {
    defaultValue: '',
    className: '',

    pattern: TIMEINPUTREGEX,
    hhmmss_toPattern: hhmmss_totimeinput,
    pattern_toSeconds: timeinput_toSeconds,

    disabled: false,
    form: null,
    name: 'TimeInput',
    placeholder: '12:00am',
    required: false
  }

  input: HTMLInputElement

  constructor(props: any) {
    super(props)
    this.state = {value: props.defaultValue || null}
  }

  get rawValue(): string {
    return this.input.value
  }

  get value(): number {
    return this.state.value
  }

  get valid(): boolean {
    return this.value !== null
  }

  onChangeInternal = e => {
    const v = this.props.pattern_toSeconds(e.target.value)
    this.setState({value: v})
    this.props.onChange(v)
  }

  render() {
    return (
      <input
        ref={input => (this.input = input)}
        type="text"
        onChange={this.onChangeInternal}
        defaultValue={this.props.hhmmss_toPattern(this.props.defaultValue)}
        className={'TimeInput ' + this.props.className}
        disabled={this.props.disabled}
        pattern={this.props.pattern.source}
        form={this.props.form}
        name={this.props.name}
        placeholder={this.props.placeholder}
        required={this.props.required}
      />
    )
  }
}
