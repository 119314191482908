import * as React from 'react'
import {computed} from 'mobx'
import {observer} from 'mobx-react'
import {Table} from 'react-bootstrap'

import {RealScreen} from 'api/realsources'
import {
  RealInstitutionStore,
  RealScreenStore,
  RealUserStore,
} from 'modules/admin/adminstores'
import IndexRow from './IndexRow'
import ScreenModal from './Modal'
import Refresher from '../Refresher'

interface State {
  selectedScreen: RealScreen
}

@observer
class ScreensIndexTab extends React.Component<{}, State> {
  constructor(props) {
    super(props)

    this.state = {
      selectedScreen: null,
    }
  }

  onClickScreen = (screen: RealScreen) => {
    this.setState({selectedScreen: screen})
  }

  onCloseModal = () => {
    this.setState({selectedScreen: null})
  }

  @computed
  get rows() {
    return Array.from(RealScreenStore.values).map((screen: RealScreen, idx: number) => (
      <IndexRow
        screen={screen}
        key={screen.id}
        onClick={(clickedScreen) => this.onClickScreen(clickedScreen)}
      />
    ))
  }

  render() {
    return (
      <div id="screen-indextab" className="admin-inner-tab">
        <span className="description">Update the admin store of screens.</span>
        <Refresher
          stores={[RealScreenStore, RealInstitutionStore, RealUserStore]}
        />
        <div className="tablescroller" data-rows={this.rows.length}>
          {/* Removed condensed from Table */}
          <Table bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Orient.</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>{this.rows}</tbody>
          </Table>
        </div>
        <ScreenModal
          screen={this.state.selectedScreen}
          onHide={this.onCloseModal}
        />
      </div>
    )
  }
}

export default ScreensIndexTab
