import * as React from 'react'
import {Popover, Tooltip, Overlay, OverlayTrigger} from 'react-bootstrap'

import Playlist from 'stores/Playlist'
import PlaylistElement from 'modules/playlists/PlaylistElement'
import userStore from 'stores/userStore'

const ShareIcon = require('images/elements/share-icon.svg')
const ArrowIcon = require('images/elements/arrow.svg')

interface Props {
  playlist: Playlist
  previewAssets: JSX.Element[]
  tooltip: JSX.Element
  tooltipContainer: PlaylistElement
  numAssetsText: string
  canEdit: boolean
  showingShare: boolean
  toggleShare: () => void
  isOwner: boolean
  showDelete: boolean
  toggleDelete: () => void
  deleteConfirmedClicked: () => void
}

const isPromoted = (props: Props) => {
  if (props.playlist.promoted) {
    return <ArrowIcon />
  }
}

const PlaylistCard: React.FunctionComponent<Props> = (props) => {
  let shareButtonRef: HTMLButtonElement
  const deleteButtonRef = React.useRef(null)

  const tooltip = (
    <Tooltip id={`${props.playlist.title}-${props.playlist.id}`}>
      {props.playlist.title}
    </Tooltip>
  )

  return (
    <div className="playlist-grid-container">
      <div className="playlist-grid">
        <div className="image-preview">{props.previewAssets}</div>
        {props.playlist.inUse && <p className="in-use">IN USE</p>}
        <h2 className="playlist-title">{props.playlist.title}</h2>
        <h3 className="playlist-author">
          <span className="full-name-split">
            <span className="first-name">{props.playlist.owner.fname}</span>
            <span className="last-name">{props.playlist.owner.lname}</span>
          </span>
        </h3>
        <p className="playlist-info">
          {props.numAssetsText}
          {props.playlist.orientation && ` - ${props.playlist.orientation}`}
        </p>
        {/* container={props.tooltipContainer} */}
        <OverlayTrigger
          placement="bottom"
          overlay={props.tooltip}
        >
          <div className="share-info">
            {isPromoted(props)}
            <ShareIcon className="share-icon" />
            {props.playlist.sharedWith.length !== 0 && (
              <p className="shared-number">
                {props.playlist.sharedWith.filter((user) => {
                  return user.id !== props.playlist.ownerID
                }).length}
              </p>
            )}
          </div>
        </OverlayTrigger>
        <div className="playlist-controls">

          {/* EDIT BUTTON */}
          <a href={`/playlists/${props.playlist.id}/edit`}>
            <button disabled={!props.canEdit} className="playlist-edit">
              Edit
            </button>
          </a>

          {/* SHARE BUTTON */}
          <button
            className={
              props.showingShare ? 'playlist-share active' : 'playlist-share'
            }
            ref={(ref) => (shareButtonRef = ref)}
            onClick={props.toggleShare}
          >
            Share
          </button>
          
          {/* DELETE BUTTON */}
          <button
            disabled={!props.isOwner || props.playlist.promoted && !userStore.currentUser.isAdmin}
            className={`playlist-delete${props.showDelete ? ' active' : ''}`}
            ref={deleteButtonRef}
            onClick={props.toggleDelete}
          >
            Delete
          </button>
          <Overlay
            target={deleteButtonRef}
            show={props.showDelete}
            placement="bottom"
          >
            <Popover
              id="popover-trigger-click-root-close"
              className="delete"
            >
              <Popover.Title className="popover-title">ARE YOU SURE?</Popover.Title>
              <div className="popover-container">
                <button className="cancel" onClick={props.toggleDelete}>
                  CANCEL
                </button>
                <button
                  className="deleteConfirm"
                  onClick={props.deleteConfirmedClicked}
                >
                  YES
                </button>
              </div>
            </Popover>
          </Overlay>
        </div>
      </div>
    </div>
  )
}

export default PlaylistCard
