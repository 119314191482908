import * as React from 'react'
import {Col} from 'react-bootstrap'
import Dotdotdot from 'react-dotdotdot'
import {inject, observer} from 'mobx-react'

import Asset from 'stores/Asset'
import {ContentStore} from 'stores/contentStore'
import {AppStateStore} from 'stores/appStateStore'

const CheckMark = require('images/elements/checkmark.svg')

interface Props {
  asset: Asset
  contentStore?: ContentStore
  appStateStore?: AppStateStore
}

const FavoritesContentCard: React.StatelessComponent<Props> = inject(
  'contentStore',
  'appStateStore'
)(
  observer(props => (
    <Col className="col-xs-4">
      <div className="favorites-content-card">
        <div
          className="favorites-content-card-image"
          style={
            props.asset.tpath
              ? {backgroundImage: `url(${props.asset.tpath})`}
              : {}
          }
        />
        <div
          className={`favorites-selector${
            props.appStateStore.isMultiSelected(props.asset) ? ' selected' : ''
          }`}
          onClick={() => props.appStateStore.multiSelectAsset(props.asset)}
        >
          <CheckMark className="favorites-selector-checkmark" />
        </div>
        <div className="favorites-card-description">
          <Dotdotdot clamp={2}>{props.asset.title.toUpperCase()}</Dotdotdot>
        </div>
      </div>
    </Col>
  ))
)

export default FavoritesContentCard
