import * as React from 'react'
import {inject, observer} from 'mobx-react'
import {Table} from 'react-bootstrap'

import {PlaylistStore} from 'stores/playlistStore'
import Playlist from 'stores/Playlist'
import PlaylistElement from './PlaylistElement'
import SideNavNewPlaylist from './SideNavNewPlaylist'
import SideNavElement from 'modules/navs/SideNavElement'
import SVGButton from 'modules/common/SVGButton'
import ShareModal from 'modules/common/ShareModal/index'
import SideNav from '../navs/SideNav'

// Imports needed for routing
import {Route, Switch} from 'react-router-dom'
import EditPlaylist from './EditPlaylist'
import NotFound from "../NotFound"
import Get from "../home/Get"
import HomeNav from '../navs/HomeNav'

const NewPlaylist = require('images/elements/plus-icon.svg')
const GridIcon = require('images/elements/grid-icon.svg')
const RowIcon = require('images/elements/rows-icon.svg')

interface Props {
  playlistStore?: PlaylistStore
}

interface State {
  gridMode: boolean
  activeSharePlaylist: Playlist
}

@inject('playlistStore')
@observer
class Playlists extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)

    if (!('gridMode' in window.localStorage)) {
      window.localStorage.gridMode = true
    }

    this.state = {
      gridMode: window.localStorage.gridMode,
      activeSharePlaylist: null
    }
  }

  gridModeSwitch = (gridMode: boolean) => {
    this.setState({
      gridMode
    })
    window.localStorage.gridMode = gridMode
  }

  getList = (data: Playlist[], className: string) => {
    const displayMode = this.state.gridMode ? 'grid' : 'row'
    if (this.state.gridMode) {
      const emptyElements = []
      for (let i = 0; i < 10; i++)
        emptyElements.push(<div className="playlist-grid-container" key={i} />)
      return (
        <div className={className + ' grid'}>
          {data.map(playlist => (
            <PlaylistElement
              playlist={playlist}
              displayType={displayMode}
              assetNumber={playlist.assetIDs.length}
              toggleShare={this.toggleShare}
              showingShare={this.state.activeSharePlaylist === playlist}
              key={playlist.id}
            />
          ))}
          {emptyElements}
        </div>
      )
    } else {
      return (
        <Table className={className} striped responsive>
          <thead>
            <tr>
              <th className="playlist-row-name">Playlist Name</th>
              <th className="playlist-row-author">Author</th>
              <th className="playlist-row-length">Length</th>
              <th className="playlist-row-orientation">Orientation</th>
              <th className="playlist-row-shared" />
              <th className="playlist-row-in-use" />
              <th className="playlist-row-buttons" />
            </tr>
          </thead>
          <tbody>
            {data.map((playlist, i) => (
              <PlaylistElement
                playlist={playlist}
                displayType={displayMode}
                assetNumber={playlist.assetIDs.length}
                toggleShare={this.toggleShare}
                showingShare={this.state.activeSharePlaylist !== null}
                key={playlist.id}
              />
            ))}
          </tbody>
        </Table>
      )
    }
  }

  toggleShare = (playlist: Playlist) => {
    this.setState({
      activeSharePlaylist:
        playlist === this.state.activeSharePlaylist ? null : playlist
    })
  }

  render() {
    return (
      <div id="playlists-page">
        {/* Routes to child component for editing playlists */}
        <Switch>
          <Route exact path='/playlists'>
            <SideNav>
              <li id="new-playlist">
                <SideNavElement
                  customClass="new-playlist"
                  svgIcon={<NewPlaylist id="new-playlist-svg-icon" />}
                  title="New Playlist"
                >
                  <SideNavNewPlaylist />
                </SideNavElement>
              </li>
            </SideNav>
            <div className="content-with-sidenav">
              <div className="display-switch-row">
                <div className="display-switch">
                  <SVGButton
                    svgIcon={<GridIcon />}
                    onClick={() => this.gridModeSwitch(!this.state.gridMode)}
                    active={this.state.gridMode}
                  />
                  <SVGButton
                    svgIcon={<RowIcon />}
                    onClick={() => this.gridModeSwitch(!this.state.gridMode)}
                    active={!this.state.gridMode}
                  />
                </div>
              </div>

              {this.props.playlistStore.myPlaylists.length > 0 && (
                <h2 className="my-playlists-title">my playlists</h2>
              )}

              {this.props.playlistStore.myPlaylists.length > 0 &&
                this.getList(
                  this.props.playlistStore.myPlaylists,
                  'playlist-display'
                )}

              {this.props.playlistStore.sharedPlaylists.length > 0 && (
                <h2 className="shared-with-me-title">Shared with me</h2>
              )}

              {this.props.playlistStore.sharedPlaylists.length > 0 &&
                this.getList(
                  this.props.playlistStore.sharedPlaylists,
                  'shared-with-me-display'
                )}

              {this.state.activeSharePlaylist && (
                <ShareModal
                  showModal={this.state.activeSharePlaylist !== null}
                  hideModal={this.toggleShare}
                  target={this.state.activeSharePlaylist}
                  sortedSharedWith={
                    this.state.activeSharePlaylist.sharedWithSortedForDisplay
                  }
                  canShareOwner
                  disabled={
                    this.state.activeSharePlaylist.userPermissions.myPermission ===
                    'viewer'
                  }
                />
              )}
            </div>
          </Route>
          <Route path='/playlists/:id/edit' component={EditPlaylist}/>
        </Switch>
      </div>
    )
  }
}

export default Playlists
