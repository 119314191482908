import * as React from 'react'

const MinerbytesOverlay = require('images/urls/minerbytes-overlay.png')

interface Props {
  url: string
  youtubeID: string
  orientation: 'horizontal' | 'vertical'
  type: string
}

const AssetPreview: React.StatelessComponent<Props> = props => {
  const Asset = props.youtubeID ? (
    <iframe
      className="embed-responsive-item"
      src={`https://www.youtube.com/embed/${props.youtubeID}`}
      allowFullScreen
    />
  ) : props.type === 'IMAGE' || props.type === 'WEB' ? (
    <img src={props.url} className="embed-responsive-item" />
  ) : (
    <video controls className="video-preview embed-responsive-item">
      <source src={props.url} />
    </video>
  )

  return props.orientation === 'horizontal' ? (
    <div className="content-container horizontal">
      <div className="embed-responsive embed-responsive-16by9">{Asset}</div>
      <img src={MinerbytesOverlay} className="preview-minerbytes-overlay-horiz"></img>
    </div>
  ) : (
    <div className="content-container vertical">
      <div className="embed-responsive embed-responsive-9by16">{Asset}</div>
      <img src={MinerbytesOverlay} className="preview-minerbytes-overlay-vert"></img>
    </div>
  )
}

export default AssetPreview
