import * as React from 'react'
import {observer} from 'mobx-react'
import {Form} from 'react-bootstrap'
import moment from 'moment-timezone'

import Asset from 'stores/Asset'
import AssetShowtime from 'stores/AssetShowtime'
import ScheduleButton from './ScheduleButton'

const UpArrow = require('images/elements/up-indicator.svg')
const DownArrow = require('images/elements/down-indicator.svg')
const ImageIcon = require('images/elements/image-icon.svg')
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

interface Props {
  selected: boolean
  onSelect: () => void
  asset: Asset
  showtime: AssetShowtime
  onSelectMinute: (event) => void
  onSelectSecond: (event) => void
  onToggleEnabled: () => void
  index: number
  onDrag: (assetIndex: number) => void
  onDragOver: (assetIndex: number) => void
  onDrop: (assetIndex: number) => void
  dragClass: boolean
}

interface State {
  imageLoaded: boolean
  draggable: boolean
}

@observer
class ShowtimeRow extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      imageLoaded: null,
      draggable: false,
    }
  }

  componentDidMount() {
    const img = new Image()
    img.src = this.props.asset.tpath

    img.onload = () => {
      this.setState({
        imageLoaded: true,
      })
    }

    img.onerror = () => {
      this.setState({
        imageLoaded: false,
      })
    }
  }

  minuteOptions = () => {
    const options = []
    for (let i = 0; i <= 59; i++) {
      options.push({value: i, label: i})
    }

    return options
  }

  toggleDrag = () => {
    this.setState({draggable: !this.state.draggable})
  }

  dragStart = (event) => {
    // Firefox requires 'drag data' for every drag event:
    event.dataTransfer.setData('text', '')

    // this.props.onDrag()
    this.props.onDrag(this.props.index)
  }

  dragOver = (event) => {
    event.preventDefault()
    this.props.onDragOver(this.props.index)
  }

  dragDrop = (event) => {
    event.preventDefault()
    this.props.onDrop(this.props.index)
  }

  render() {
    const {
      selected,
      onSelect,
      asset,
      showtime,
      onSelectMinute,
      onSelectSecond,
      onToggleEnabled,
      index,
      onDrag,
      onDragOver,
      onDrop,
    } = this.props

    let imgStyle = {}

    let isExpired = false

    let expiryTime = moment.tz(asset.lifeSpanEnd, timeZone)

    if (moment().isAfter(expiryTime)) {
      isExpired = true
    }

    if (this.state.imageLoaded === true) {
      // Image successfully loaded
      imgStyle = {backgroundImage: `url(${asset.tpath})`, padding: 0}
    } else if (this.state.imageLoaded === null) {
      // Image hasn't loaded yet
      imgStyle = {backgroundImage: `url(${ImageIcon})`}
    }

    return (
      <tr
        draggable={this.state.draggable}
        className={(this.props.dragClass ? 'drag-n-drop' : '') + (isExpired ? 'expired' : '')}
        onDragStart={this.dragStart}
        onDragOver={this.dragOver}
        onDrop={this.dragDrop}
      >
        <td className="checkbox-cell">
          <Form.Check checked={selected} onChange={onSelect} />
        </td>
        <td className={"preview-cell"}>
          <div className="img-preview" style={imgStyle} />
        </td>
        <td className="title-cell">{asset.title}</td>
        <td className="duration-cell">
          <input
            type="number"
            className="time-select"
            disabled={isExpired}
            value={
              showtime.durationMinutes > 0
                ? `${showtime.durationMinutes}`.replace(/^0+/, '')
                : 0
            }
            onChange={onSelectMinute}
          />
          <p className="time-label">minutes</p>
          <input
            type="number"
            className="time-select"
            disabled={isExpired}
            value={
              showtime.durationSeconds > 0
                ? `${showtime.durationSeconds}`.replace(/^0+/, '')
                : 0
            }
            onChange={onSelectSecond}
          />
          <p className="time-label">seconds</p>
        </td>
        <td className="enabled-cell">
          <div
            className={'enabled ' + (showtime.enabled ? 'true' : 'false')}
            onClick={onToggleEnabled}
          >
            {showtime.enabled && !isExpired ? 'ON' : 'OFF'}
          </div>
        </td>
        <td className={"expiry-cell"}>
          {expiryTime.format("MM/DD/YYYY")}
        </td>
        <td className="schedule-cell">
          <ScheduleButton id={`schedule-button-${index}`} showtime={showtime} />
        </td>
        <td className="reposition-cell">
          <span
            onMouseOver={this.toggleDrag}
            onMouseLeave={this.toggleDrag}
            className="grippy"
          />
        </td>
      </tr>
    )
  }
}

export default ShowtimeRow
